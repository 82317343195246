import { Constants } from "../constants";

const initialState = false;

const loadingReducers = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case Constants.SET_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default loadingReducers;
