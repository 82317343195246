import { Constants } from "../constants";
export default {
  setMeasureByYear(payload: any) {
    return {
      payload,
      type: Constants.SET_MEASURE_BY_YEAR,
    };
  },
  setMeasureByMonth(payload: any) {
    return {
      payload,
      type: Constants.SET_MEASURE_BY_MONTH,
    };
  },
  setMeasureByMonthAndLocationReference(locationReferenceId: string, data: any) {
    const payload = {locationReferenceId, data};
    return {
      payload ,
      type: Constants.SET_MEASURE_BY_MONTH_AND_LOCATION_REFERENCE,
    };
  },
  setMeasureByLocationReference(payload: any) {
    return {
      payload,
      type: Constants.SET_MEASURE_BY_LOCATION_REFERENCE,
    };
  },
  filterLocationReference(payload: any) {
    return {
      payload,
      type: Constants.SET_MEASURE_LOCATION_REFERENCE_FILTER,
    };
  },
  setMeasureDetailDayByStep(payload: string) {
    return {
      payload,
      type: Constants.SET_MEASURE_DETAIL_DAY_BY_STEP,
    };
  },
};
