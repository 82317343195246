import { Constants } from "../constants";
import { IUser, UserActionTypes } from "../types";

const userReducerDefaultState: IUser = {
  logged: false,
  id: "",
  firstName: "",
  lastName: "",
  roles: [],
  services: [],
};

const userReducer = (state = userReducerDefaultState, action: UserActionTypes): IUser => {
  switch (action.type) {
    case Constants.LOG_IN:
      return {
        ...state,
        logged: action.payload,
      };
    case Constants.LOG_OUT:
      return { ...state, logged: action.payload };
    case Constants.SET_USER_INFO:
      return { ...action.payload };
    default:
      return state;
  }
};

export default userReducer;
