import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { config } from "../../config";
import Dashboard from "../components/Dashboard";

class DashboardManager extends React.Component<any> {

  public render() {
    return (
      <>
        <Dashboard url={`${config.apiUrl}/referential-admin`} />
      </>
    );
  }
}

export default connect()(withTranslation()(DashboardManager));
