import {Constants} from "../constants";

export default {
    setMeteoForecast(forecasts: any) {
        return {
            payload: forecasts,
            type: Constants.SET_METEO_FORECAST,
        }
    },
    setMeteoYesterdayPrecipitation(forecasts: any) {
        return {
            payload: forecasts,
            type: Constants.SET_METEO_YESTERDAY_PRECIPITATION,
        };
    }

};
