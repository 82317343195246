import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SzIcon, SzList, SzTypographie } from "@suezenv/react-theme-components";
import Switch from "../../../main/components/Utils/switch";
import { formatServerDate } from "../../../main/utils";
import { CIRCUIT_MODE_CENTER, CIRCUIT_MODE_SEVRAGE, DAY_LIST, MONTH_LIST } from "../../store/constants";
import { HUNTER_SOURCE, STATE } from "../../../main/store/constants";
import ProgrammingService from "../../services/ProgrammingService";

const NextWeekModal: any = (props: any) => {
  const { t } = useTranslation();
  const { circuit, calendars, updateCalendar, forecast, initHandle } = props;

  const updateWateringHandle = () => {
    const value = !wateringState;
    ProgrammingService.updateWatering(circuit, value).then(() => {
      setWateringState(value);
      initHandle();
    });
  };
  const getWateringState = (): boolean => {
    let wateringStatus = false;
    if (circuit.modules) {
      circuit.modules.map((module: any) => {
        if (module.state && circuit.source === HUNTER_SOURCE && module.state === STATE.OFF && module.offDays === 0) {
          wateringStatus = false;

          return false;
        } else if (module.state && circuit.source === HUNTER_SOURCE) {
          wateringStatus = true;

          return true;
        }
      });
    }
    return wateringStatus;
  };

  const [wateringState, setWateringState] = useState(getWateringState());
  const forecastByDate = (rowDate: Date) => {
    const forecastDay: any = [];
    forecast.forEach((item: any) => {
      const dateObserved: Date = new Date(item.dateObserved);
      if (dateObserved.getDate() === rowDate.getDate() && dateObserved.getMonth() === rowDate.getMonth()) {
        forecastDay.push(item);
      }
    });

    return forecastDay;
  };
  const formatDate = (date: Date) => {
    const month = t(MONTH_LIST[date.getMonth()]);
    const nbrDay = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${nbrDay} ${month}`;
  };
  const hasCalendar = (dayIndex: Date) => {
    let isActive = true;
    dayIndex.setHours(0, 0, 0, 0);
    calendars.forEach((calendar: any) => {
      if (formatServerDate(dayIndex) === calendar.day && calendar.circuitId === circuit.id) {
        isActive = calendar.isActive;
      }
    });
    return isActive;
  };

  const getDayTemperature = (forecastDay: any) => {
    const nbrItem = forecastDay.length;
    if (nbrItem === 0) {
      return "0.0";
    }

    let result = 0;
    forecastDay.forEach((item: any) => {
      result += item.temperature;
    });
    return (result / nbrItem).toFixed(1);
  };

  const getDayPrecipitationProbability = (forecastDay: any): any => {
    const nbrItem = forecastDay.length;
    if (nbrItem === 0) {
      return "0.0";
    }

    let result = 0;
    forecastDay.forEach((item: any) => {
      result += item.precipitationProbability;
    });
    return ((result / nbrItem) * 100).toFixed(2);
  };
  const getDayPrecipitation = (forecastDay: any) => {
    let result = 0;
    forecastDay.forEach((item: any) => {
      result += item.precipitation;
    });
    return result.toFixed(1);
  };
  const renderColumn = (indexDay: number) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + indexDay);
    const day = currentDate.getDay();
    const forecastDay = forecastByDate(currentDate);
    return (
      <div className="p-2 pl-4 pr-4">
        <SzTypographie className="m-0" color="inactive" variant="caption">
          {t(`full${DAY_LIST[currentDate.getDay()]}Range`)}
        </SzTypographie>
        <SzTypographie className="mb-4">{formatDate(currentDate)}</SzTypographie>
        <SzTypographie className="m-0" color="inactive" variant="caption">
          {t("programming:watering_prevision")}
        </SzTypographie>
        <SzTypographie>{getDayPrecipitationProbability(forecastDay)}%</SzTypographie>
        <SzTypographie className="mb-4">{getDayPrecipitation(forecastDay).replace(".0", "")}mm</SzTypographie>
        <SzTypographie className="m-0" color="inactive" variant="caption">
          {t("programming:temperature")}
        </SzTypographie>
        <SzTypographie className="mb-4">{getDayTemperature(forecastDay).replace(".0", "")}°C</SzTypographie>
        <SzTypographie className="m-0" color="inactive" variant="caption">
          {hasCalendar(currentDate)
            ? t("programming:programming_watering_authorized")
            : t("programming:programming_watering_forbidden")}
        </SzTypographie>
        <div className="row mb-4 m-0 p-0">
          <Switch
            onChangeHandle={() => updateCalendar(circuit, currentDate, !hasCalendar(currentDate))}
            checked={hasCalendar(currentDate)}
          />
        </div>
      </div>
    );
  };
  return (
    <>
      <SzList>
        <SzList.Item>
          <div className="row">
            <div className="col-1">
              <SzIcon variant="line" icon="calendar-3" />
            </div>
            <div className="col-3">
              <SzTypographie className="mb-1">{t("programming:next_week_title")}</SzTypographie>
              <SzTypographie className="m-0" color="inactive" variant="caption">
                {t("programming:next_week_title_2")}
              </SzTypographie>
            </div>
          </div>
        </SzList.Item>
      </SzList>
      {circuit.source === HUNTER_SOURCE && (
        <div className="d-flex flex-row ml-3 mt-2 mb-4 ">
          <div className="pl-5">
            <SzTypographie className="m-0" color="inactive" variant="caption">
              {t("programming:circuit")}
            </SzTypographie>
            <SzTypographie className="mt-3">{circuit.sigId}</SzTypographie>
          </div>
          <div className="pl-5">
            <SzTypographie className="m-0" color="inactive" variant="caption">
              {t("programming:mode.title")}
            </SzTypographie>
            <SzTypographie className="mt-3">{t(`programming:mode.${circuit.mode}`)}</SzTypographie>
          </div>

          <div className="pl-5">
            <SzTypographie className="m-0 text-center " color="inactive">
              {t("programming:watering.title")}
            </SzTypographie>
            <div className="d-flex flex-row">
              <SzTypographie className="mt-2 ml-1">{t("programming:watering.suspended")}</SzTypographie>
              <Switch onChangeHandle={updateWateringHandle} checked={wateringState} />
              <SzTypographie className="mt-2 ml-1">{t("programming:watering.active")}</SzTypographie>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-row ml-3 mt-2 mb-4 ">
        {renderColumn(1)}
        {renderColumn(2)}
        {renderColumn(3)}
        {renderColumn(4)}
        {renderColumn(5)}
        {renderColumn(6)}
        {renderColumn(7)}
      </div>
      <SzTypographie className="m-0 ml-3 pl-4" color="inactive" variant="caption">
        {t("programming:calendar_legend")}
      </SzTypographie>
    </>
  );
};

export default NextWeekModal;
