import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzButton, SzIcon, SzInput, SzList, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import Switch from "../../../main/components/Utils/switch";
import ProgrammingService from "../../services/ProgrammingService";
import {HUMIDITY_TYPE} from "../../store/constants";

const ShowHumidityAdjustModal: any = (props: any) => {
    const {t} = useTranslation();
    const initValue = {value: 1, label: t('programming:day_off', {"nbr": 1})};
    const {circuit, module, closeHandle} = props;
    const [enable, setEnable] = useState(false);
    const [value, setValue] = useState(initValue);
    const [adjustId, setAdjustId] = useState('');
    const [thresholdMax, setThresholdMax] = useState('');
    const [listAdjustConfigs, setListAdjustConfigs] = useState([]);


    useEffect(() => {
        ProgrammingService.getAdjustConfigs(circuit.id, HUMIDITY_TYPE, module.id).then((response: any) => {

            setListAdjustConfigs(response);
            setThresholdMax(response[0] ? response[0].thresholdMax : "")

            setValue(response[0] ? {
                value: response[0].value, label: t('programming:day_off', {"nbr": response[0].value})
            } : initValue);

            setAdjustId(response[0] ? response[0].id:'');
            setEnable(response[0] ? response[0].enable : false);
        });
    }, []);

    const getValueOptions = () => {
        const options: any = [];
        let i = 1
        while (i <= 5) {
            options.push({value: i, label: t('programming:day_off', {"nbr": i})});
            i++;
        }
        return options;
    }

    const saveHandle = (event: any) => {
        const adjustConfig = {
            value: value.value,
            circuitId: circuit.id,
            moduleId: module.id,
            thresholdMin: null,
            thresholdMax,
            enable,
            type: HUMIDITY_TYPE,
        };
        if (listAdjustConfigs.length === 0) {
            ProgrammingService.newAdjustConfig(adjustConfig);
        } else {
            ProgrammingService.editAdjustConfig({...adjustConfig, id:adjustId});
        }
        closeHandle();
        event.preventDefault();
    }
    return (
        <>
            <SzList>
                <SzList.Item>
                    <div className="row">
                        <div className="col-1">
                            <SzIcon variant="line" icon="gardening-hand-sprinkler"/>
                        </div>
                        <div className="col-6">
                            <SzTypographie className="mb-1">{t("programming:adjust_humidity")}</SzTypographie>
                            <SzTypographie className="m-0" color="inactive" variant="caption">
                                {module.name}
                            </SzTypographie>
                        </div>
                    </div>
                </SzList.Item>
            </SzList>
            <form onSubmit={saveHandle}>
                <div className="ml-3">
                    <SzTypographie className="m-0 mt-4 ml-4" color="inactive" variant="caption">
                        {t("programming:alert_threshold_exceeded_title")}
                    </SzTypographie>
                    <div className="row ml-0 mb-4 ">
                        <div className="float-left  mt-2 ml-3"><Switch className="ml-0"
                                                                       onChangeHandle={() => setEnable(!enable)}
                                                                       checked={enable}/></div>
                        <div className="float-left mt-1 text-right ml-3"><SzTypographie
                            className="mb-1 mt-2 ">{t("programming:threshold_max_title")}</SzTypographie></div>
                        <div className="col-5">
                            <SzInput
                                value={thresholdMax}
                                type="number"
                                required
                                onChange={({target}) => {
                                    if (!isNaN(Number(target.value)) && Number(target.value) >= 0) {
                                        setThresholdMax(target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div className="ml-3 w-100">
                        <div className="ml-2">
                            <SzTypographie
                                className="mb-0">{t("programming:adjust_humidity_module_title_1")}</SzTypographie>
                            <SzTypographie className="mb-1" color="inactive"
                                           variant="caption">{t("programming:adjust_humidity_module_title_2")}</SzTypographie>
                        </div>
                        < div className="ml-3 mt-2">
                            {circuit.modules.map((module: any) => {
                                    if (module.deviceModel.type === "acc2")
                                        return <div className="ml-3"><SzTypographie
                                            className="mb-0 mt-1 "> - {module.name}</SzTypographie></div>
                                }
                            )
                            }
                        </div>


                        < div className="ml-3 mt-3">
                            <SzTypographie
                                className="mb-0 ml-3">{t("programming:adjust_humidity_action_title_1")}</SzTypographie>
                            <SzTypographie className="mb-1 ml-3" color="inactive"
                                           variant="caption">{t("programming:adjust_humidity_action_title_2")}</SzTypographie>

                        </div>
                        <div className="row w-100 pb-5">
                            <div className="col-6 mt-1 ml-3"><SzTypographie
                                className="mb-1 mt-2 ml-3">{t("programming:adjust_threshold_exceeded_action_title")}</SzTypographie>
                            </div>
                            <div className="col-5">
                                <SzSelect
                                    placeholder={'Sélectionner un PI'}
                                    options={getValueOptions()}
                                    value={value}
                                    onChange={(selectedOption: any) => {
                                        setValue(selectedOption)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <SzButton variant="secondary" className="mr-3">{t('programming:cancel')}</SzButton>
                        <SzButton className="mr-3" type="submit" variant="primary">{t('programming:save')}</SzButton>
                    </div>
                </div>
            </form>

        </>
    );
};

export default ShowHumidityAdjustModal;
