import React, { useState } from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {SzBox, SzButton, SzIcon, SzModal, SzTypographie} from "@suezenv/react-theme-components";
import { checkTimeInHours } from "../../../main/utils";
import {routesPath} from "../../../routes";
import ViewEquipmentManager from "../../manager/ViewEquipmentManager";
import ViewEventManager from "../../manager/ViewEventManager";
import { LIST_EVENTS } from "../../../main/store/constants";
import "./style.scss";

const EquipmentRow: any = (props: any) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const {equipment , latestEvent} = props;
    const history = useHistory();

    const getStatusChangedAt = () => {
      let numberDays = "-";
      if (equipment.stateChangedAt) {
        const stateDate = new Date(equipment.stateChangedAt).getTime();
        const nowDay = new Date().getTime();
        const diffDays = Math.round((nowDay - stateDate) / (1000 * 60 * 60 * 24));
        numberDays = `${diffDays} ${t("equipment:list.status_days")}`;
      }

      return numberDays;
    };

    const getRelayColumn = () => {
        if (equipment.relay) {
            return (<>
                <div className="row m-0 ">
                    <div className="col-2">
                        <SzIcon variant="line" icon="wifi-signal-2"/>
                    </div>
                    <div className="col-10">
                        <SzTypographie className="m-0" color="inactive"
                                       variant="caption">{t("equipment:list.relay_title")}</SzTypographie>
                        <SzTypographie>{equipment.relay.name}</SzTypographie>
                    </div>
                </div>
            </>);
        }
        return <></>;
    };

    const handleClickView = () => {
      setShow(true);
    };

    const getLastEvent = (equipmentId: any) => {
        let type: string | null = null;
        if (latestEvent && latestEvent.length) {

            const event = latestEvent.find((element: { _id: { equipmentId: any; }; latest: any; }) => (element._id.equipmentId === equipmentId && checkTimeInHours(element.latest) <= 24));
            type = event ? event.type: null;
        }

            return type && LIST_EVENTS.includes(type) ? <>{t(`event:types.list.${type}`)} </> : "-";
        };

    const toMapHandle = (equipment: any) => {
        const selectedLocationReference = {
            locationReference: equipment.locationReference || (equipment.circuit ? equipment.circuit.locationReference : ""),
            filterWateringEquipment: true,
        };
        props.filterMapLocationReference(selectedLocationReference);
        history.push(routesPath.map);
    };

    return (
      <>
        <SzModal title={""} show={show} handleClose={() => setShow(false)} size={"xl"}>
          <ViewEquipmentManager idEquipment={equipment.id} />
        </SzModal>
          <SzModal title={""} show={showEvents} handleClose={() => setShowEvents(false)} size={"xl"}>
              <ViewEventManager equipment={equipment} />
          </SzModal>
        <SzBox className="row equipment-info-box m-0 p-0 p-2 mb-2" tag="div">
          <div className="col-2 row p-0 m-0 border-right">
            <div className="col-1">
              <div className={`info-status status-${equipment.status.toLowerCase()}`}></div>
            </div>
            <div className="col-5">
              <SzTypographie className="m-0" color="inactive" variant="caption">
                {t("equipment:list.status_title")}
              </SzTypographie>
              <SzTypographie>{t(`equipment:status_code.${equipment.status}`)}</SzTypographie>
            </div>
            <div className="col-5">
              <SzTypographie className="m-0" color="inactive" variant="caption">
                {t("equipment:list.status_change_title")}
              </SzTypographie>
              <SzTypographie>{getStatusChangedAt()} </SzTypographie>
            </div>
          </div>
          <div className="col-8 p-0 m-0 row pl-4 border-right">
            <div className="col-4">
              <SzTypographie className="m-0">{equipment.name}</SzTypographie>
              <SzTypographie color="inactive" variant="caption">
                {equipment.serialNumber}
              </SzTypographie>
            </div>
            <div className="col-4">{getRelayColumn()}</div>
            <div className="col-1">
              <div title={t("equipment:list.btn_detail_map")}>
                  <SzButton onClick={() => toMapHandle(equipment)} variant="secondary" className="m-0"
                            icon="maps-pin-1" alignIcon="right"></SzButton>
              </div>
            </div>
            <div className="col-3">
                {equipment.locationReference ? <>
                    <SzTypographie color="inactive" variant="caption" className="m-0">{equipment.locationReference.name}</SzTypographie>
                    <SzTypographie className="m-0">{equipment.locationReference.sigId}</SzTypographie>
                </>
                : equipment.circuit && equipment.circuit.locationReference &&
                    <><SzTypographie color="inactive" variant="caption" className="m-0">{equipment.circuit.locationReference.name}</SzTypographie>
                        <SzTypographie className="m-0">{equipment.circuit.locationReference.sigId}</SzTypographie>
                    </>}
            </div>
          </div>
          <div className="col-2 row  m-0 p-0 pl-4">
            <div className="col-6">
              <SzTypographie color="inactive" variant="caption">
                {t("equipment:list.events_tile")}
              </SzTypographie>
              <SzTypographie className="m-0">{getLastEvent(equipment.id)}</SzTypographie>
            </div>
            <div className="col-3 m-0 p-0">
              <div title={t("equipment:list.btn_journal_title")}>
                <SzButton onClick={() => setShowEvents(true)} variant="secondary" className="m-0" icon="calendar-3" alignIcon="right"></SzButton>
              </div>
            </div>
            <div className="col-3 m-0 p-0">
              <div title={t("equipment:list.btn_detail_title")}>
                <SzButton
                  variant="secondary"
                  className="m-0"
                  icon="task-list-text-1"
                  alignIcon="right"
                  onClick={handleClickView}
                ></SzButton>
              </div>
            </div>
          </div>
        </SzBox>
      </>
    );
};

export default EquipmentRow;
