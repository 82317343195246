import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzButton, SzIcon, SzInput, SzList, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import Switch from "../../../main/components/Utils/switch";
import ProgrammingService from "../../services/ProgrammingService";
import {WATER_BUDGET_TYPE} from "../../store/constants";

const WaterBudgetModal: any = (props: any) => {
    const {t} = useTranslation();
    const {circuit, closeHandle} = props;

    const defaultValue = {
        value: "",
        circuitId: circuit.id,
        type: WATER_BUDGET_TYPE,
        thresholdMin: null,
        thresholdMax: null,
        enable: false,
    };

    const [listAdjustConfigs, setListAdjustConfigs] = useState([]);
    const [adjustConfig1, setAdjustConfig1] = useState(defaultValue);
    const [adjustConfig2, setAdjustConfig2] = useState(defaultValue);
    const [adjustConfig3, setAdjustConfig3] = useState(defaultValue);
    const [thresholdMin, setThresholdMin] = useState<number|string>("");
    const [thresholdMax, setThresholdMax] = useState<number|string>("");

    useEffect(() => {
        ProgrammingService.getAdjustConfigs(circuit.id, WATER_BUDGET_TYPE).then((listAdjustConfigs: any) => {
            setListAdjustConfigs(listAdjustConfigs);

            listAdjustConfigs.forEach((adjustConfig: any) => {
                if (adjustConfig.thresholdMax === null) {
                    setAdjustConfig1((adjustConfig ? adjustConfig : defaultValue));
                }
                if (adjustConfig.thresholdMin !== null && adjustConfig.thresholdMax !== null) {
                    setAdjustConfig2((adjustConfig ? adjustConfig : defaultValue));

                    setThresholdMin(adjustConfig ? adjustConfig.thresholdMin : "");
                    setThresholdMax(adjustConfig ? adjustConfig.thresholdMax : "");
                }
                if (adjustConfig.thresholdMin === null) {
                    setAdjustConfig3((adjustConfig ? adjustConfig : adjustConfig));
                }
            });
        });
    }, []);

    const saveHandle = (event: any) => {
        if (listAdjustConfigs.length === 0) {
            ProgrammingService.newAdjustConfig(adjustConfig1);
            ProgrammingService.newAdjustConfig(adjustConfig2);
            ProgrammingService.newAdjustConfig(adjustConfig3);
        } else {
            ProgrammingService.editAdjustConfig(adjustConfig1);
            ProgrammingService.editAdjustConfig(adjustConfig2);
            ProgrammingService.editAdjustConfig(adjustConfig3);
        }
        closeHandle();
        event.preventDefault();
    };

    const updateThresholdMin = (value: string) => {
        if (!isNaN(Number(value)) && Number(value) >= 0) {
            setThresholdMin(Number(value));
            setAdjustConfig1((previousState: any) => {
                return {...previousState, thresholdMin: value};
            });
            setAdjustConfig2((previousState: any) => {
                return {...previousState, thresholdMin: value};
            });

            if (Number(value) > thresholdMax) {
                updateThresholdMax(value);
            }
        }
    };

    const updateThresholdMax = (value: string) => {
        if (!isNaN(Number(value)) && Number(value) >= 0) {
            setThresholdMax(Number(value));
            setAdjustConfig2((previousState: any) => {
                return {...previousState, thresholdMax: value};
            });
            setAdjustConfig3((previousState: any) => {
                return {...previousState, thresholdMax: value};
            });

            if (Number(value) < thresholdMin) {
                updateThresholdMin(value);
            }
        }
    };

    return (
        <form onSubmit={saveHandle}>
            <SzList>
                <SzList.Item>
                    <div className="row">
                        <div className="col-1">
                            <SzIcon variant="line" icon="cog"/>
                        </div>
                        <div className="col">
                            <SzTypographie className="mb-1">{t("programming:adjust_water_budget_title")}</SzTypographie>
                            <SzTypographie className="m-0" color="inactive" variant="caption">
                                {circuit.locationReference ? circuit.locationReference.sigId : ""} {circuit.locationReference ? circuit.locationReference.name : ""}
                            </SzTypographie>
                        </div>
                    </div>
                </SzList.Item>
            </SzList>
            <SzList>
                <SzList.Item>
                    <div className="row">
                        <div className="col-1">
                            <Switch
                                onChangeHandle={() => {
                                    setAdjustConfig1((previousState: any) => {
                                        return { ...previousState, enable: !adjustConfig1.enable };
                                    });
                                }}
                                checked={adjustConfig1.enable}
                            />
                        </div>
                        <div className="col-4 mt-2">
                            <SzTypographie className="">{t("programming:adjust_water_budget_pluviometry_title")} <strong>{t("programming:adjust_water_budget_less_than")}</strong> </SzTypographie>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <div className="col-5">
                                    <SzInput
                                        name="input-demo"
                                        type={"number"}
                                        required={true}
                                        value={thresholdMin.toString()}
                                        onChange={({ target }) => {
                                            updateThresholdMin(target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-3 mt-2">
                            <SzTypographie className="" align="center">{t("programming:adjust_water_budget_pluviometry_title_2")} <strong>{t("programming:adjust_water_budget_name")}</strong> (%) </SzTypographie>
                        </div>
                        <div className="col-1 px-0">
                            <SzInput
                                name="input-demo"
                                value={adjustConfig1.value.toString()}
                                type={"number"}
                                required={true}
                                onChange={({ target }) => {
                                    if (!isNaN(Number(target.value)) && Number(target.value) >= 0 && Number(target.value) <= 100) {
                                        setAdjustConfig1((previousState: any) => {
                                            return { ...previousState, value: target.value };
                                        });
                                    }

                                }}
                            />
                        </div>
                    </div>
                </SzList.Item>

                <SzList.Item>
                    <div className="row">
                        <div className="col-1">
                            <Switch
                                onChangeHandle={() => {
                                    setAdjustConfig2((previousState: any) => {
                                        return { ...previousState, enable: !adjustConfig2.enable };
                                    });
                                }}
                                checked={adjustConfig2.enable}
                            />
                        </div>
                        <div className="col-4 mt-2">
                            <SzTypographie className="">{t("programming:adjust_water_budget_pluviometry_title")} <strong>entre</strong></SzTypographie>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <div className="col-5">
                                    <SzInput
                                        name="input-demo"
                                        value={thresholdMin.toString()}
                                        type={"number"}
                                        required={true}
                                        onChange={({ target }) => {
                                            updateThresholdMin(target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-2 mt-2">
                                    <SzTypographie weight="bold" align="center">Et</SzTypographie>
                                </div>
                                <div className="col-5">
                                    <SzInput
                                        name="input-demo"
                                        value={thresholdMax.toString()}
                                        type={"number"}
                                        required={true}
                                        onChange={({ target }) => {
                                            updateThresholdMax(target.value);
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className="col-3 mt-2">
                            <SzTypographie align="center">{t("programming:adjust_water_budget_pluviometry_title_2")} <strong>{t("programming:adjust_water_budget_name")}</strong> (%) </SzTypographie>
                        </div>
                        <div className="col-1 px-0">
                            <SzInput
                                name="input-demo"
                                value={adjustConfig2.value.toString()}
                                type={"number"}
                                required={true}
                                onChange={({ target }) => {
                                    if (!isNaN(Number(target.value)) && Number(target.value) >= 0 && Number(target.value) <= 100) {
                                        setAdjustConfig2((previousState: any) => {
                                            return {...previousState, value: target.value};
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </SzList.Item>

                <SzList.Item>
                    <div className="row">
                        <div className="col-1">
                            <Switch
                                onChangeHandle={() => {
                                    setAdjustConfig3((previousState: any) => {
                                        return { ...previousState, enable: !adjustConfig3.enable };
                                    });
                                }}
                                checked={adjustConfig3.enable}
                            />
                        </div>
                        <div className="col-4 mt-2">
                            <SzTypographie className="">{t("programming:adjust_water_budget_pluviometry_title")} <strong>{t("programming:adjust_water_budget_higher_than")}</strong> </SzTypographie>
                        </div>
                        <div className="col-3">
                            <div className="row">
                                <div className="col-5 offset-md-7">
                                    <SzInput
                                        name="input-demo"
                                        value={thresholdMax.toString()}
                                        type={"number"}
                                        required={true}
                                        onChange={({ target }) => {
                                            updateThresholdMax(target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-3 mt-2">
                            <SzTypographie align="center">{t("programming:adjust_water_budget_pluviometry_title_2")} <strong>{t("programming:adjust_water_budget_name")}</strong> (%) </SzTypographie>
                        </div>
                        <div className="col-1 px-0">
                            <SzInput
                                name="input-demo"
                                value={adjustConfig3.value.toString()}
                                type={"number"}
                                required={true}
                                onChange={({ target }) => {
                                    if (!isNaN(Number(target.value)) && Number(target.value) >= 0 && Number(target.value) <= 100) {
                                        setAdjustConfig3((previousState: any) => {
                                            return {...previousState, value: target.value};
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </SzList.Item>
            </SzList>
            <div className="text-center mt-5 mb-4">
                <SzButton variant="secondary" className="mr-3" onClick={closeHandle}>{t("programming:btn.close")}</SzButton>
                <SzButton className="mr-3 text-white" type={"submit"}>{t("programming:btn.validate")}</SzButton>
            </div>

        </form>
    );
};

export default WaterBudgetModal;
