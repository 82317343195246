import { ERROR } from "../constants";
import { ErrorsActionTypes, IErrorUser } from "../types";

const initialState: IErrorUser = {
  jwtCheck: false,
  message: "",
  status: null,
};

const errorUserReducers = (state = initialState, action: ErrorsActionTypes): IErrorUser => {
  switch (action.type) {
    case ERROR.SET:
      return { jwtCheck: action.payload.jwtCheck, message: action.payload.message, status: action.payload.status };
    case ERROR.RESET:
      return initialState;
    default:
      return state;
  }
};

export default errorUserReducers;
