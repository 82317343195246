import React, {ReactElement} from "react";
import "./index.scss";

interface ISwitch {
    onChangeHandle: () => void;
    checked: boolean;
    className?: string;
}

function Switch(props: ISwitch): ReactElement {
    return (
        <>
            <div className={`mt-2 pl-2 ${props.className}`}>
                <label className="switch">
                    <input type="checkbox" onChange={props.onChangeHandle} checked={props.checked}/>
                    <span className="slider"></span>
                </label>
            </div>

        </>
    );
}

export default Switch;
