// action strings
export enum Constants {
  SET_HYDRIQUE_LIST_EQUIPMENT = "SET_HYDRIQUE_LIST_EQUIPMENT",
  FILER_HYDRIQUE_STATUS = "FILER_HYDRIQUE_STATUS",
  SET_HYDRIQUE_EQUIPMENT_STATS = "SET_HYDRIQUE_EQUIPMENT_STATS",
  SET_HYDRIQUE_EQUIPMENT = "SET_HYDRIQUE_EQUIPMENT",
  SET_HYDRIQUE_LOCATION_REFERENCE_FILTER = "SET_HYDRIQUE_LOCATION_REFERENCE_FILTER",
  SET_HYDRIQUE_EQUIPMENT_EVENT_LIST = "SET_HYDRIQUE_EQUIPMENT_EVENT_LIST",
  SET_HYDRIQUE_LATEST_EVENT_EQUIPMENT = "SET_HYDRIQUE_LATEST_EVENT_EQUIPMENT",
  SET_HYDRIQUE_MONITORING_LOCATION_REFERENCE = "SET_HYDRIQUE_MONITORING_LOCATION_REFERENCE",
  SET_HYDRIQUE_MONITORING_FILTER = "SET_HYDRIQUE_MONITORING_FILTER",
  SET_HYDRIQUE_MONITORING_MEASURES = "SET_HYDRIQUE_MONITORING_MEASURES",
  SET_HYDRIQUE_MONITORING_PLANT_MEASURES= "SET_HYDRIQUE_MONITORING_PLANT_MEASURES",
  SET_HYDRIQUE_MONITORING_PLANTS= "SET_HYDRIQUE_MONITORING_PLANTS",
  INPN_BASE_URL ="https://inpn.mnhn.fr/espece/cd_nom/"
}

