import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SzBox, SzIcon, SzTypographie} from "@suezenv/react-theme-components";
import MapManager from "../../map/manager/MapManager";
import {routes} from "../../routes";
import AlarmService from "../../watering/services/AlarmService";
import {alarmAction} from "../../watering/store/actions";
import {RAISED_STATUS} from "../store/constants";
import MeteoService from "../services/MeteoService";
import meteo from "../store/actions/meteo";
import {formatIsoDateTime, formatMeteoDate} from "../utils";

class HomeManager extends React.Component<any> {

    public componentDidMount() {
        this.countAlarms();
        this.setMeteoForecast();
    }

    public countAlarms() {
        const filters: any = {locationReferences: [], status: RAISED_STATUS};
        AlarmService.countAlarms(filters).then((response: any) => {
            this.props.setAlarmsRaisedCount(response.count);
        });
    }

    public setMeteoForecast() {
        const date = new Date();
        date.setHours(date.getHours() + 1);

        const startAt = formatIsoDateTime(date);
        date.setHours(date.getHours() + 4);
        const endAt = formatIsoDateTime(date);

        MeteoService.getForecast(startAt, endAt).then((response: any) => {
            this.props.setMeteoForecast(response.data);
        });

        MeteoService.getPrecipitationYesterday().then((response: any) => {
            this.props.setMeteoYesterdayPrecipitation(response.data[0].precipitation || 0);
        });
    }

    public render() {
        const {t, forecast,yesterdayPrecipitation} = this.props;
        return (
            <div className="w-100 overflow-auto row m-0 p-0 h-100">
                <div className="col-10 m-0 p-0">
                    <MapManager isAffectedToCircuit={1}/>
                </div>
                <div className="col-2">
                    <SzBox className="w-100 alarm-box-info pt-2 m-0 p-0 mb-4 " tag="div">
                        <div className="row m-0 p-0 mb-2">
                            <div className="pl-2 col-2">
                                <SzIcon variant="line" icon="alarm-bell"/>
                            </div>
                            <div className="col-7">
                                <SzTypographie className="m-0">{this.props.t("home_alarm_text1")}</SzTypographie>
                                <SzTypographie color="inactive"  className="m-0">
                                    {this.props.t("home_alarm_text2")}
                                </SzTypographie>
                            </div>
                            <div className="col-3 cursor-pointer"  onClick={() => this.props.history.push(routes().alarm)}>
                                <SzIcon variant="line" icon="arrow-right"/>
                            </div>
                        </div>
                        <div className="text-center mt-3 w-100">
                            <SzTypographie  variant="huge">{this.props.listRaisedAlarms.nbr}</SzTypographie>
                            <SzTypographie color="inactive"  className="m-0">
                                {this.props.t("home_alarm_text_nbr_raised")}
                            </SzTypographie>
                        </div>
                    </SzBox>

                            {(forecast && forecast.length>0) &&
                                <SzBox className="weather-box p-0" tag="div">
                                    <div className="title row ml-3 mr-1 m-0 p-0">
                                        <div className="m-0 p-0 mt-2 col-2">
                                            <SzIcon variant="line" icon="beach-sun-birds"/>
                                        </div>
                                        < div className="mt-2 col-10">
                                            <SzTypographie
                                                className="m-0">{t("home_weather_text1")}</SzTypographie>
                                            <div>
                                                <SzTypographie
                                                    color="inactive"
                                                    className="m-0">{formatMeteoDate(forecast[0].dateObserved, this.props.t)}</SzTypographie>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="text-center w-100">
                                        <div className="mt-2 mb-1">
                                            <SzTypographie color="inactive" className="m-0">{t('temperature')}</SzTypographie>
                                            <SzTypographie  className="m-0">{`${forecast[0].temperature}°C`}</SzTypographie>
                                        </div>
                                    </div>
                                    <div className="text-center w-100">
                                        <div className=" mb-1">
                                        <SzTypographie color="inactive"  className="m-0">{t('precipitation')}</SzTypographie>
                                        <SzTypographie  className="m-0">{`${forecast[0].precipitation}mm | Prob : ${(forecast[0].precipitationProbability*100).toFixed(2)}%`}</SzTypographie>
                                        </div>
                                    </div>
                                    <div className="text-center w-100">
                                        <div className=" mb-1">
                                            <SzTypographie color="inactive" className="m-0">{t('wind')}</SzTypographie>
                                            <SzTypographie   className="m-0">{`${forecast[0].windSpeed}m/s | ${forecast[0].windDirection}°`}</SzTypographie>
                                        </div>
                                    </div>
                                    <div className="text-center w-100">
                                        <div className=" mb-1">
                                            <SzTypographie color="inactive"  className="m-0">{t('humidity')}</SzTypographie>
                                            <SzTypographie   className="m-0">{`${(forecast[0].relativeHumidity*100).toFixed(2)}%`}</SzTypographie>
                                        </div>
                                    </div>
                                    <div className="text-center w-100">
                                        <div className=" mb-1">
                                            <SzTypographie color="inactive"  className="m-0">{t('uv_index')}</SzTypographie>
                                            <SzTypographie   className="m-0">{`${forecast[0].uvIndex}`}</SzTypographie>
                                        </div>
                                    </div>
                                    <div className="text-center w-100">
                                        <div className=" mb-1">
                                            <SzTypographie color="inactive"  className="m-0">{t('yesterday_precipitation')}</SzTypographie>
                                            <SzTypographie   className="m-0">{`${yesterdayPrecipitation.toFixed(2)} mm`}</SzTypographie>
                                        </div>
                                    </div>
                                    <div className="text-right mt-2 w-100">
                                        <div className=" mb-2 mr-2">
                                            <SzTypographie color="inactive"  className="m-0">{t('home_weather_text2')}</SzTypographie>
                                        </div>
                                    </div>
                                </SzBox>
                            }

                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    setAlarmsRaisedCount: alarmAction.setAlarmsRaisedCount,
    setMeteoForecast: meteo.setMeteoForecast,
    setMeteoYesterdayPrecipitation:meteo.setMeteoYesterdayPrecipitation,
};
const mapStateToProps = (state: any) => {
    return {
        listRaisedAlarms: state.alarm.listRaisedAlarms,
        user: state.user,
        forecast: state.meteo.forecast,
        yesterdayPrecipitation: state.meteo.yesterdayPrecipitation

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(HomeManager));
