import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SzButton, SzIcon, SzList, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import {
    MAX_CHAR_ACK_ALARM,
    RAISED_ALARM_BLACKOUT,
    RAISED_ALARM_HARDWARE_FAILURE,
    RAISED_ALARM_LEAK,
    RAISED_ALARM_OTHER,
    RAISED_ALARM_UNIDENTIFIED_ORIGIN,
    RAISED_ALARM_VANDALISM,
    REASON_DUPLICATE,
    REASON_FALSE_ALERT,
    REASON_SOLVED
} from "../../store/constants";
import {TextArea} from "../../../main/components/Utils/textArea";

const AcknowledgeAlarm: any = (props: any) => {
    const {t} = useTranslation();
    const {acknowledgeAlarmHandle, alarm, user, closeHandle} = props;
    const [acknowledge, setAcknowledge] = useState({value: null, label: null});
    const [reason, setReason] = useState({value: null, label: null});
    const [comment, setComment] = useState("");
    const getOptions = (): any => {
        return [
            {value: REASON_SOLVED, label: t(`alarm:reason.${REASON_SOLVED}`)},
            {value: REASON_DUPLICATE, label: t(`alarm:reason.${REASON_DUPLICATE}`)},
            {value: REASON_FALSE_ALERT, label: t(`alarm:reason.${REASON_FALSE_ALERT}`)},
        ];
    };

    const getReasonOptions = (): any => {
        return [
            {value: RAISED_ALARM_BLACKOUT, label: t(`alarm:reason.${RAISED_ALARM_BLACKOUT}`)},
            {value: RAISED_ALARM_HARDWARE_FAILURE, label: t(`alarm:reason.${RAISED_ALARM_HARDWARE_FAILURE}`)},
            {value: RAISED_ALARM_VANDALISM, label: t(`alarm:reason.${RAISED_ALARM_VANDALISM}`)},
            {value: RAISED_ALARM_LEAK, label: t(`alarm:reason.${RAISED_ALARM_LEAK}`)},
            {value: RAISED_ALARM_UNIDENTIFIED_ORIGIN, label: t(`alarm:reason.${RAISED_ALARM_UNIDENTIFIED_ORIGIN}`)},
            {value: RAISED_ALARM_OTHER, label: t(`alarm:reason.${RAISED_ALARM_OTHER}`)},
        ];
    };
    const selectChangeHandle = (option: any) => {
        setAcknowledge( option);
    };
    const reasonChangeHandle = (option: any) => {
        setReason( option);
    };
    return (
        <>
            <SzList>
                <SzList.Item>
                    < div className="row">
                        <div className="col-1">
                            <SzIcon variant="line" icon="alarm-bell"/>
                        </div>
                        <div className="col-3">
                            <SzTypographie>{t("alarm:acknowledge_title")}</SzTypographie>
                        </div>
                    </div>
                </SzList.Item>
            </SzList>
            <div className="row m-0 p-0 mb-5">
                <div className="col-2 pt-2">
                    <SzTypographie>{t("alarm:acknowledge_label")}</SzTypographie>
                </div>
                <div className="col-10">
                    <SzSelect
                        className="mb-1"
                        onChange={selectChangeHandle}
                        name="acknowledge"
                        placeholder={t("alarm:acknowledge_placeholder")}
                        options={getOptions()}
                        value={acknowledge.value ? acknowledge : null}
                    />
                </div>

            </div>
            <div className="row m-0 p-0 mb-5">
                <div className="col-2 pt-2">
                    <SzTypographie>{t("alarm:reason_label")}</SzTypographie>
                </div>
                <div className="col-10">
                    <SzSelect
                        className="mb-1"
                        onChange={reasonChangeHandle}
                        name="reason"
                        placeholder={t("alarm:reason_placeholder")}
                        options={getReasonOptions()}
                        value={reason.value ? reason : null}
                    />
                </div>
            </div>

            <div className="row m-0 p-0 mb-5">
                <div className="col-2 pt-2">
                    <SzTypographie>{t("alarm:comment_label")}</SzTypographie>
                </div>
                <div className="col-10">
                    <TextArea
                        className="mb-1 ack-comment"
                        placeholder={t("alarm:max_char",{length:MAX_CHAR_ACK_ALARM})}
                        onChange={(e:any)=>{
                            if(e.target.value.length <= MAX_CHAR_ACK_ALARM) {
                                setComment(e.target.value)
                            }
                        }}
                        name="comment"
                        value={comment}
                    />
                </div>
            </div>
            <div className="text-center mt-5 mb-4">
                <SzButton onClick={() => {
                    acknowledgeAlarmHandle(acknowledge.value, alarm, user,reason.value, comment);
                    closeHandle();
                }} isDisabled={!acknowledge.value || !reason.value}
                          className="mr-3">{t("alarm:btn.validate")}</SzButton>
            </div>
        </>
    );
};

export default AcknowledgeAlarm;
