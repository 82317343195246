import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import Container from "./main/components/Container";
import Dashboard from "./main/pages/Admin/Dashboard";
import UserAdmin from "./main/pages/Admin/UserAdmin";
import Alarm from "./main/pages/Alarm";
import Consumption from "./main/pages/Consumption";
import Home from "./main/pages/Home";
import Map from "./main/pages/Map";
import Programming from "./main/pages/Programming";
import ProgrammingConfig from "./main/pages/Programming/ProgrammingConfig";
import Watering from "./main/pages/Watering";
import { store } from "./main/store";
import { routesPath } from "./routes";
import { defaultTheme, setActiveTheme, ThemeContext } from "./themes";
import { Authenticate as AuthenticateService } from "./user/services/Authenticate";
import {MAX_MOBILE_WIDTH} from "./main/store/constants";
import HydriqueCircuit from "./main/pages/HydriqueCircuit";
import HydriqueMap from "./main/pages/HydriqueMap";
import HydriqueEquipment from "./main/pages/HydriqueEquipment";
import UrbasensePlant from "./main/pages/HydriqueCircuit/UrbasensePlant";
import ReactGA from 'react-ga';
import {config} from "./config";
import ProgrammingHunterConfig from "./main/pages/Programming/ProgrammingHunterConfig";
import ProgrammingMobileHunterConfig from "./main/pages/Programming/ProgrammingMobileHunterConfig";

interface IAppProps {
  history: History;
}

const AppRouter = ({ history }: IAppProps) => {
  useEffect(() => {
    setActiveTheme();
    AuthenticateService.intialize();
  }, []);


  return (
      <>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ThemeContext.Provider value={defaultTheme}>
              <Container history={history}>
                <Switch>
                  <Route exact={true} path={routesPath.home}
                         component={(window.screen && window.screen.width >= MAX_MOBILE_WIDTH) ? Home : Programming}/>
                  <Route exact={true} path={routesPath.programming.index} component={Programming}/>
                  <Route exact={true} path={routesPath.programming.config} component={ProgrammingConfig}/>
                  <Route exact={true} path={routesPath.programmingHunter.config} component={ProgrammingHunterConfig}/>
                  <Route exact={true} path={routesPath.programmingMobileHunter.config} component={ProgrammingMobileHunterConfig}/>
                  {(window.screen && window.screen.width >= MAX_MOBILE_WIDTH) &&
                  <>
                    <Route exact={true} path={routesPath.watering} component={Watering}/>
                    <Route exact={true} path={routesPath.consumption} component={Consumption}/>
                    <Route exact={true} path={routesPath.map} component={Map}/>
                    <Route exact={true} path={routesPath.referentieladmin} component={Dashboard}/>
                    <Route exact={true} path={routesPath.useradmin} component={UserAdmin}/>
                    <Route exact={true} path={routesPath.alarm} component={Alarm}/>
                    <Route exact={true} path={routesPath.hydriqueMap} component={HydriqueMap}/>
                    <Route exact={true} path={routesPath.hydriqueEquipment} component={HydriqueEquipment}/>
                    <Route exact={true} path={routesPath.hydriqueCircuit} component={HydriqueCircuit}/>
                    <Route exact={true} path={routesPath.urbasenseModule} component={UrbasensePlant}/>
                  </>}
                </Switch>
              </Container>
            </ThemeContext.Provider>
          </ConnectedRouter>
        </Provider>
    </>
  );
};

export default AppRouter;
