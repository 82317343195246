import React, {useEffect} from "react";
import { SzTab} from "@suezenv/react-theme-components";
import ProgrammingManager from "../../../watering/manager/Programming/ProgrammingManager";
import Breadcrumb from "../../components/Utils/Breadcrumb";
import {useHistory} from "react-router";
import {routesPath} from "../../../routes";
import WateringTabNavigation from "../../components/Utils/WateringTabNavigation";
import {config} from "../../../config";
import ReactGA from "react-ga";

const Programming = () => {
    const history = useHistory();
    useEffect(() => {
        if (config.googleAnalyticsId && history.location) {
            ReactGA.initialize(config.googleAnalyticsId);
            ReactGA.set({ page: history.location.pathname }); // Update the user's current page
            ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
        }
    },[history.location.pathname]);

    const selectTabHandle = (tab: any) => {
        history.push(tab)
    }
    return (
        <div className="m-2 w-100 overflow-auto">
            <div className="row p-0 m-0">
                <div className="col-5 p-0 m-0 d-none d-xl-block">
                    <Breadcrumb elements={[{text: "programming"}]}/>
                </div>
                <div className="col-7 d-none d-xl-block">
                    <WateringTabNavigation route={routesPath.programming.index} selectTabHandle={selectTabHandle}/>
                </div>
            </div>
            <ProgrammingManager/>
        </div>
    );
};

export default Programming;
