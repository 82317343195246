import React from "react";
import {useTranslation} from "react-i18next";
import {SzChart} from "@suezenv/react-theme-components";
import "./style.scss";

const ConsumptionChart: any = (props: any) => {
    const {t} = useTranslation();
    const currentYear = new Date().getFullYear();

    const getChartOptions = () => {
        return {
            title: {
                align: "left",
                useHTML: true,
                text: "",
            },
            chart: {
                type: "column",
            },
            rangeSelector: {
                selected: 2,
            },
            xAxis: {
                title: {
                    useHTML: true,
                    text: "",
                },
                labels: {
                    useHTML: true,
                    format: "<h3 class='text-secondary'>{value}</h3>",
                    style: {
                        color: "",
                    },
                },
                categories: [
                    t("Jan"),
                    t("Feb"),
                    t("Mar"),
                    t("Apr"),
                    t("May"),
                    t("Jun"),
                    t("Jul"),
                    t("Aug"),
                    t("Sep"),
                    t("Oct"),
                    t("Nov"),
                    t("Dec"),
                ],
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: {
                    text: "",
                    useHTML: true,
                },
            },
            exporting: false,
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0,
                    colors: ["#84DFFF"],
                },
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    colorByPoint: true,
                    name: `${currentYear}`,
                    data: props.getDataYear(`${currentYear}`),
                    pointWidth: 15,
                    pointPadding: 0,
                },
                {
                    name: props.year.value,
                    data: props.getDataYear(props.year.value),
                    pointWidth: 15,
                    color: "#cccccc",
                    pointPadding: 0,
                },
            ],
        };
    };

    return (
        <SzChart options={getChartOptions()}/>);
};

export default ConsumptionChart;
