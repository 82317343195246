import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {
    SzBox,
    SzButton,
    SzIcon,
    SzInput,
    SzPagination,
    SzTypographie,
    SzSelect,
    SzTable, SzModal
} from "@suezenv/react-theme-components";
import EmptyRow from "../components/utils/EmptyRow";
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT,
    PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT,
} from "../../main/store/constants";
import {withRouter} from "react-router";
import {MAX_MOBILE_WIDTH} from "../../../main/store/constants";
import {HYDRIQUE_FUNCTIONAL_TYPE} from "../../main/store/constants";
import hydriqueMonitoringAction from "../store/actions/hydriqueMonitoringAction";
import LocationReference from "../components/HydriqueMonitoring/LocationReference";
import mapAction from "../../map/store/actions/mapAction";
import MeasureService from "../services/MeasureService";
import {formatServerDate} from "../../main/utils";
import PlantService from "../services/PlantService";
import {routes, routesPath} from "../../routes";
import PlantMeasure from "../components/HydriqueMonitoring/PlantMeasure";
import {getHumidityValueClass} from "../components/utils/utile";
import PlantDetail from "../components/HydriqueMonitoring/PlantDetail";

class HydriqueMonitoringManager extends React.Component<any> {


    public state = {
        sort: {field: "quantity", direction: "desc"},
        columns: [],
        page: 1,
        search: '',
        showConsumptionModal: false,
        consumptionLocationReference: 0,
        showExportConsumptionModal: false,
        showPlantDetail: false,
        showPlantMeasures: false,
        plantDetail: null
    };

    public componentDidMount() {
        this.initTable();
    }

    public initTable() {
        this.setData();
        this.setColumn(this.state.sort.field, this.state.sort.direction);
    }

    public getMeasures() {

        const date = new Date();
        date.setHours(date.getHours() - 24);
        const startAt = formatServerDate(new Date(date));
        MeasureService.getMeasures(1, [], startAt, null, 1).then(response => {
            this.props.setListMeasures(response);
        });
    }

    public setData(page = 1, sortFiled = "quantity", sortDirection = "desc") {
        const {query} = this.props.filters;

        return PlantService.getPlants(page, {locationReferences: []}, sortFiled, sortDirection, 20, HYDRIQUE_FUNCTIONAL_TYPE, query).then(
            (response: any) => {
                this.props.setListPlants(response);
                this.setState({search: query});
                this.getMeasures();
            },
        );
    }

    public setColumn(dataField = "", sort = "") {
        const {t} = this.props;
        const sortDirection = sort === "desc" ? "sz-icon-line arrow-down-1" : "sz-icon-line arrow-up-1";
        this.setState({
            columns: [
                this.columnJson("externalId", "externalId", t("hydriqueMonitoring:list.externalId"), dataField, sortDirection, false),
                this.columnJson("name", "name", t("hydriqueMonitoring:list.name"), dataField, sortDirection),
                this.columnJson("varietyName", "varietyName", t("hydriqueMonitoring:list.varietyName"), dataField, sortDirection),
                this.columnJson("detail", "detail", t("hydriqueMonitoring:list.detail"), dataField, sortDirection, false),
                this.columnJson("locationReference", "locationReference", t("hydriqueMonitoring:list.locationReference"), dataField, sortDirection),
                this.columnJson("toMap", "toMap", "", dataField, sortDirection,false),
                this.columnJson("equipment", "equipment", t("hydriqueMonitoring:list.equipment"), dataField, sortDirection, false),
                this.columnJson("risk", "risk", t("hydriqueMonitoring:list.risk"), dataField, sortDirection, false),
                this.columnJson("quantity", "quantity", t("hydriqueMonitoring:list.quantity"), dataField, sortDirection),
                this.columnJson("humidity", "humidity", t("hydriqueMonitoring:list.humidity"), dataField, sortDirection, false),
                this.columnJson("rapport", "rapport", t("hydriqueMonitoring:list.rapport"), dataField, sortDirection, false),
                //this.columnJson("measure", "measure", t("hydriqueMonitoring:list.measure"), dataField, sortDirection, false),
            ],
        });
    }

    public columnJson(
        classes: string,
        dataField: string,
        text: string,
        sortDataField: string,
        sortDirection: string,
        canSort = true,
    ) {
        const {t} = this.props;
        const headerDefaultClass = canSort ? "sz-icon-line move-expand-vertical" : "";
        return {
            classes,
            dataField,
            text: t(text),
            headerClasses: sortDataField === dataField ? sortDirection : headerDefaultClass,
            headerEvents: {
                onClick: canSort ? this.sortByColumn.bind(this) : () => {
                },
            },
        };
    }

    public sortByColumn(e: any, column: any) {
        const {direction} = this.state.sort;
        const sortDirection = direction && direction === "desc" ? "asc" : "desc";
        this.setState({sort: {direction: sortDirection, field: column.dataField}});
        this.setColumn(column.dataField, sortDirection);
        this.setData(this.state.page, column.dataField, sortDirection);

        return true;
    }

    public getPlantHumidity = (plantId: string) => {
        let plantHumidity: any = null;
        Object.values(this.props.measures).forEach((measure: any) => {
            if (plantId === measure.plantId) {
                plantHumidity = measure;
            }
        });

        return plantHumidity ? <div className="position-relative">
            {
                `${(plantHumidity.value * 100).toFixed(1)} ${plantHumidity.unitLabel}`
            }
            <span className={`info-status ${getHumidityValueClass(plantHumidity.value * 100)} mt-2 ml-2`}/>

        </div> : ''


    }

    public  toMapHandle(locationReference:{id:string,sigId:string,name:string}) {
        const selectedLocationReference = {
            locationReference: locationReference,
            filterWateringEquipment: true,
        };
        this.props.filterMapLocationReference(selectedLocationReference);
        this.props.history.push(routesPath.hydriqueMap);
    };

    public getListData(): any {
        const {plants, t} = this.props;
        const result: any = [];
        if (plants && plants.data && plants.data.length) {
            plants.data.forEach((item: any) => {

                const plant = {

                    id: item.id,
                    externalId: item.externalId,
                    name: <div className="text-name">{item.name}</div>,
                    varietyName: <div className="text-variety-name">{item.varietyName}</div>,
                    detail : <SzButton onClick={() => this.setState({plantDetail:item, showPlantDetail:true})} variant="secondary" className="m-0 pt-2" alignIcon="right"><i className="sz-icon-line plant-icon"/></SzButton>,

                    locationReference: item.locationReference ? <>
                        <SzTypographie className="mb-0 text-location-reference" variant="caption" weight="light">
                            {item.locationReference.name}
                        </SzTypographie>
                        <SzTypographie> {item.locationReference.sigId}</SzTypographie>
                    </> : '',
                    toMap: <SzButton onClick={()=>this.toMapHandle(item.locationReference)} variant="secondary" className="m-0" icon="maps-pin-1" alignIcon="right"></SzButton>,
                    equipment: item.equipments && item.equipments[0] ?
                        <>
                            <div className="text-equipment-name">{item.equipments[0].name}</div>
                            <div className="mt-0 position-relative">
                                {t("hydriqueMonitoring:Com")}
                                <span
                                    className={`info-status ${item.equipments[0].status ? `status-${item.equipments[0].status.toLowerCase()}` : ''} mt-2 ml-2`}/>
                            </div>
                        </> : "",
                    risk: <div
                        className={`risk-text risk-${item.risk ? item.risk.toLowerCase().replace(/[^a-zA-Z ]/g, "") : 'nd'}`}>{item.risk ? item.risk.toUpperCase() : 'ND'}</div>,
                    quantity: <div className="watering-text text-center"><SzIcon variant="line" icon="color-drop-pick"
                                                                                 className={`watering-icon ${item.quantity !== null ? (item.quantity > 0 ? 'watering-not-zero' : 'watering-zero') : 'watering-nd'}`}/>
                        <div
                            className="m-0 p-0">{item.quantity!== null ? (item.quantity > 0 ? t('hydriqueMonitoring:list:doWatering') + " " + item.quantity + ' L' : t('hydriqueMonitoring:list:notWatering')) : 'ND'}</div>
                    </div>,
                    humidity: this.getPlantHumidity(item.id),
                    rapport: (
                        <SzButton alignIcon="right"
                                  onClick={() => {
                                      if (item.equipments && item.equipments[0]) {
                                          this.props.history.push(routes(item.equipments[0].id, item.externalId).urbasenseModule)
                                      }
                                  }
                                  }
                                  variant="secondary"
                                  className="mr-3" icon="calendar-3">
                        </SzButton>
                    ),
                   /* measure: (
                        <SzButton
                            alignIcon="right"
                            variant="secondary"
                            onClick={() => this.setPlantMeasureHandle(item)}
                            className="mr-3"
                            icon="analytics-bars-3d"
                        >
                        </SzButton>
                    ),*/
                };
                result.push(plant);
            });
        }

        return result;
    }

    public getPlantMeasures(plantId: string) {
        const date = new Date();
        date.setDate(date.getDate() - 31);
        const startAt = formatServerDate(new Date(date));
        MeasureService.getMeasures(1, [], startAt, null, 1, plantId).then(response => {
            this.props.setListPlantMeasures(response);
        });
    }


    public setPlantMeasureHandle(plant: any) {
        this.setState({showPlantMeasures: true, plantDetail: plant})
        this.getPlantMeasures(plant.id);
    }


    public searchHandle() {
        const query = this.state.search;

        PlantService.getPlants(1, {locationReferences: []}, 'name', 'asc', 20, HYDRIQUE_FUNCTIONAL_TYPE, query).then(
            (response: any) => {
                this.props.setListPlants(response);
                this.props.filterHydrique({query});
                this.getMeasures();
            }
        );
    }

    public resetHandle() {
        this.setState({search: ''});
        PlantService.getPlants().then(
            (response: any) => {
                this.props.setListPlants(response);
                this.props.filterHydrique({query: ''});
                this.getMeasures();
            }
        );

    }


    public enterKeyHandle(event: any) {
        if (event.key === "Enter") {
            this.searchHandle();
        }
    }


    public render() {
        const {columns, showPlantMeasures, plantDetail, showPlantDetail} = this.state;
        const {plants} = this.props;
        return (
            <div>
                <SzModal title={""} show={showPlantDetail} handleClose={() => this.setState({showPlantDetail: false})} size={"xl"}>
                    <PlantDetail plant={plantDetail} />
                </SzModal>
                <SzModal title={""} show={showPlantMeasures}
                         handleClose={() => this.setState({showPlantMeasures: false})} size={"xl"}>
                    <PlantMeasure plant={plantDetail} measures={this.props.plantMeasures}/>
                </SzModal>
                <div className=" d-none d-xl-block">
                    <SzBox className="row equipment-info-box m-0 p-0 mt-4 mb-4  cursor-pointer" tag="div">
                        <div className="col-1 mt-3 d-none d-xl-block d-md-block ">
                            <i className="sz-icon-line gardening-hand-sprinkler float-left"/>
                        </div>
                        <div className="col-md-4 col-sm-12 mt-3">
                            <SzTypographie
                                className="m-0">{this.props.t("hydriqueMonitoring:btn.hydrique_monitoring_text1")}</SzTypographie>
                            <SzTypographie color="inactive" variant="caption">
                                {this.props.t("hydriqueMonitoring:btn.hydrique_monitoring_text2")}
                            </SzTypographie>
                        </div>
                        <div className="offset-md-3  offset-sm-0 mt-3 col-md-4 col-sm-12 d-none d-xl-block ">
                            <div className="row m-0 p-0">
                                <div className="col-8 p-0">
                                    <SzInput
                                        value={this.state.search}
                                        onKeyUp={this.enterKeyHandle.bind(this)}
                                        onChange={({target}) => this.setState({search: target.value})}
                                        placeholder={this.props.t("hydriqueMonitoring:list.search_text")}
                                    />
                                </div>
                                <div className="col-2 m-0 text-center">
                                    <SzButton onClick={this.searchHandle.bind(this)} variant="secondary" icon="search"/>
                                </div>

                                {this.state.search &&
                                < div className="col-2 m-0  p-0">
                                    <SzButton onClick={this.resetHandle.bind(this)} variant="secondary"
                                              icon="diagram-round"/>
                                </div>
                                }
                            </div>
                        </div>
                    </SzBox>
                </div>
                {columns.length &&
                plants &&
                plants.data &&
                plants.data.length ? (
                    <div className="list-table hydrique-list row m-2 mr-3">
                        <SzTable
                            columns={columns}
                            data={this.getListData()}
                            keyField="id"
                            className="table-striped"
                        />
                        {plants && plants.pagination && (
                            <div className="row w-100">
                                <div className="col-6 w-100 offset-5">
                                    {plants.pagination[PAGINATION_PAGE_COUNT] > 1 && (
                                        <SzPagination
                                            totalItemsCount={parseInt(plants.pagination[PAGINATION_TOTAL_COUNT], 10)}
                                            activePage={parseInt(plants.pagination[PAGINATION_CURRENT_PAGE], 10)}
                                            onChange={(pageNumber: any) => {
                                                this.setState({page: pageNumber});
                                                this.setData(pageNumber, this.state.sort.field, this.state.sort.direction);
                                            }}
                                            itemsCountPerPage={parseInt(plants.pagination[PAGINATION_PER_PAGE], 10)}
                                            pageRangeDisplayed={parseInt(plants.pagination[PAGINATION_PAGE_COUNT], 10)}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <EmptyRow/>
                )}
            </div>
        )
    }
}

const mapDispatchToProps = {
    filterHydrique: hydriqueMonitoringAction.filterHydrique,
    setListPlants: hydriqueMonitoringAction.setListPlants,
    setListMeasures: hydriqueMonitoringAction.setListMeasures,
    filterMapLocationReference: mapAction.filterMapLocationReference,
    setListPlantMeasures: hydriqueMonitoringAction.setListPlantMeasures

};
const mapStateToProps = (state: any) => {
    return {
        filters: state.hydriqueMonitoring.filters,
        plants: state.hydriqueMonitoring.plants,
        measures: state.hydriqueMonitoring.measures,
        plantMeasures: state.hydriqueMonitoring.plantMeasures
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(HydriqueMonitoringManager)));
