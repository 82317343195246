
import React from "react";
import {useTranslation} from "react-i18next";
import {SzChart} from "@suezenv/react-theme-components";
import {formatServerDate} from "../../../main/utils";
import "./style.scss";

const ProgramminFlowChart: any = (props: any) => {
    const {t} = useTranslation();
    const {step, startAt, endAt} = props;
    const getWateringCategories = () => {
        const categories = [];
        const startDate: Date = new Date(startAt);
        const endDate: Date = new Date(endAt);
        for (const d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            categories.push(formatServerDate(d));
        }
        return categories;
    };
    const initCategories = () => {
        const categories: any = [];
        const startDate: Date = new Date(startAt);
        const endDate: Date = new Date(endAt);
        for (const d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
            categories[formatServerDate(d)] = null;
        }
        return categories;
    };

    const getFlowData = () => {
        const categories: any = initCategories();
        const maxNumberOfSteps = step;
        // const flow = (props.measureDetailDayByStep && props.measureDetailDayByStep.flow && props.measureDetailDayByStep.flow.length) > maxNumberOfSteps ? props.measureDetailDayByStep.flow.slice(0, maxNumberOfSteps) : props.measureDetailDayByStep.flow ;
        const flow =
          props.measureDetailDayByStep && props.measureDetailDayByStep.flow ? props.measureDetailDayByStep.flow : [];
        flow.forEach((item: any) => {
            const index = formatServerDate(item._id.date);
            categories[index] = item.total ? parseFloat((item.total/1000).toFixed(1)) : null;
        });
        return Object.values(categories);
    };

    const getSensorData = () => {
      const categories: any = initCategories();
      const sensor = (props.measureDetailDayByStep &&
          props.measureDetailDayByStep.sensor)
          ? props.measureDetailDayByStep.sensor: [];
      sensor.forEach((item: any) => {
        const index = formatServerDate(item._id.date);
        categories[index] = item.total ? parseFloat(item.total.toFixed(1)) : null;
      });
      return Object.values(categories);
    };;
    const getChartOptions = () => {

        const yAxis:any[] = [{
            min: 0,
            title: {
                text: "Arrosage",
                useHTML: true,
                style: {
                    color: "#84DFFF",
                }
            },
            labels: {
                format: "{value} m3",
                useHTML: true,
                style: {
                    color: "#84DFFF",
                }
            },
        }];
        const series:any[] = [

            {
                name: "",
                data: getFlowData(),
                pointWidth: 15,
                pointPadding: 0,
                yAxis: 0,
            }

        ];
        if (props.circuitHasHumidity)
        {
            yAxis.push({
                min: 0,
                title: {
                    text: "Humidité",
                    useHTML: true,
                    style: {
                        color: "red",
                    }
                },
                labels: {
                    format: "{value} %",
                    useHTML: true,
                    style: {
                        color: "red",
                    }
                },
                opposite: true
            });

            series.push({
                name: "",
                data: getSensorData(),
                pointWidth: 2,
                color: "red",
                pointPadding: 0,
                type: "line",
                marker: {
                    enabled: false,
                },
                yAxis: 1
            });
        }
        return {
            title: {
                align: "left",
                useHTML: true,
                text: "",
            },
            chart: {
                type: "column",
            },
            rangeSelector: {
                selected: 2,
            },
            xAxis: {
                title: {
                    useHTML: true,
                    text: "",
                },
                labels: {
                    useHTML: true,
                    format: "<h6 class='chart-text'>{value}</h6>",
                    style: {
                        color: "",
                    },
                },
                categories:
                    getWateringCategories(),

                crosshair: true,
            },
            yAxis: yAxis,
            exporting: false,
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0,
                    colors: ["#84DFFF"],
                },
            },
            legend: {
                enabled: false,
            },
            series: series
        };
    };

    return (
        <SzChart options={getChartOptions()}/>);
};

export default ProgramminFlowChart;
