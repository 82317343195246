import React from "react";
import Frame from "../../main/utils/Frame";
import "../style/dashboard.scss";

const Dashboard = (props: { url: string | undefined; }) => {

  return (
    <>
      <div className="container-frame">
          <Frame sandbox={false} url={`${props.url}?token=${window.sessionStorage.getItem("tokenAlmEv")}`}/>
      </div>
    </>
  );
};

export default Dashboard;
