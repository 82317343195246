import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzBox, SzIcon, SzTab, SzTypographie} from "@suezenv/react-theme-components";
import Switch from "../../main/components/Utils/switch";
import {HYDRIQUE_FUNCTIONAL_TYPE, WATERING_FUNCTIONAL_TYPE} from "../../main/store/constants";

const Filter = (props: any) => {
    const {t} = useTranslation();
    const {selectedLocationReference, allFilterActivated, setMapFilter, setFilter, setListEquipment, mapFilter, setListCircuits, setPlantTabHandle} = props;
    const [filterWatering, setFilterWatering] = useState(allFilterActivated);
    const [filterHydriquePlant, setFilterHydriquePlant] = useState(props.functionalType === HYDRIQUE_FUNCTIONAL_TYPE);
    const [filterMaterial, setFilterMaterial] = useState(allFilterActivated);
    const [filterHydrique, setFilterHydrique] = useState(allFilterActivated);
    const [filterAlarm, setFilterAlarm] = useState(mapFilter.isAlarmActivated);
    const [filterLocationReference, setFilterLocationReference] = useState(true);
    const [subFilterCircuitOn, setSubFilterCircuitOn] = useState(true);
    const [subFilterCircuitOff, setSubFilterCircuitOff] = useState(true);
    const [subFilterCircuitPartial, setSubFilterCircuitPartial] = useState(true);

    const [tab, setTab] = useState("tab-circuit");
    const [tabPlant, setTabPlant] = useState("tab-plant-humidity");
    const [showRelay, setShowRelay] = useState(true);
    const [showCircuitRelay, setShowCircuitRelay] = useState(true);
    const [showSensor, setShowSensor] = useState(true);
    const [showMeter, setShowMeter] = useState(true);
    const [showActuator, setShowActuator] = useState(true);
    const [showNormalHydriqueEquipment, setShowNormalHydriqueEquipment] = useState(true);
    const [showNormalEquipment, setShowNormalEquipment] = useState(true);
    const [showDefectHydriqueEquipment, setShowDefectHydriqueEquipment] = useState(true);
    const [showDefectEquipment, setShowDefectEquipment] = useState(true);
    const [showUnavailableEquipment, setShowUnavailableEquipment] = useState(true);
    useEffect(() => {
        setFilterWatering(mapFilter.isWateringActivated && allFilterActivated);
        setFilterMaterial(allFilterActivated);
        setFilterAlarm(mapFilter.isAlarmActivated);
        setFilterHydrique(mapFilter.isHydriqueActivated && allFilterActivated);
    }, [allFilterActivated, mapFilter]);
    useEffect(() => {
        if (mapFilter.showEquipments) {
            setTab("tab-equipment");
        } else {
            setTab("tab-circuit");
        }

    }, [mapFilter.showEquipments]);
    const filterCircuitOnHandle = (value: boolean) => {
        setSubFilterCircuitOn(value);
        setMapFilter({circuitOn: value});
    };

    const filterCircuitOffHandle = (value: boolean) => {
        setSubFilterCircuitOff(value);
        setMapFilter({circuitOff: value});
    };
    const filterCircuitPartialHandle = (value: boolean) => {
        setSubFilterCircuitPartial(value);
        setMapFilter({circuitPartial: value});
    };
    const setFilterLocationReferenceHandle = (value: boolean) => {
        setFilterLocationReference(value);
        setFilter({filterLocationReference: value});
    };
    const selectTabHandle = (tab = "tab-circuit") => {
        if (tab === "tab-equipment") {
            if (!selectedLocationReference.equipments) {
                setListEquipment(selectedLocationReference);
            }
            setMapFilter({showEquipments: true});

        } else {
            if (!selectedLocationReference.circuits) {
                setListCircuits(selectedLocationReference);
            }
            setMapFilter({showEquipments: false});
        }

        setTab(tab);
    };

    const selectTabPlantHandle = (tab: string) => {
        setPlantTabHandle(tab);
        setTabPlant(tab);
    };
    const showRelayHandle = () => {
        setShowRelay(!showRelay);
        setMapFilter({showRelay: !showRelay});
    };
    const showCircuitRelayHandle = () => {
        setShowCircuitRelay(!showCircuitRelay);
        setMapFilter({showCircuitRelay: !showCircuitRelay});
    };
    const showSensorHandle = () => {
        setShowSensor(!showSensor);
        setMapFilter({showSensor: !showSensor});
    };
    const showActuatorHandle = () => {
        setShowActuator(!showActuator);
        setMapFilter({showActuator: !showActuator});
    };
    const showMeterHandle = () => {
        setShowMeter(!showMeter);
        setMapFilter({showMeter: !showMeter});
    };
    const showNormalEquipmentHandle = () => {
        setShowNormalEquipment(!showNormalEquipment);
        setMapFilter({showNormalEquipment: !showNormalEquipment});
    };

    const showDefectEquipmentHandle = () => {
        setShowDefectEquipment(!showDefectEquipment);
        setMapFilter({showDefectEquipment: !showDefectEquipment});
    };

    const showUnavailableEquipmentHandle = () => {
        setShowUnavailableEquipment(!showUnavailableEquipment);
        setMapFilter({showUnavailableEquipment: !showUnavailableEquipment});
    };


    const showNormalHydriqueEquipmentHandle = () => {
        setShowNormalHydriqueEquipment(!showNormalHydriqueEquipment);
        setMapFilter({showNormalHydriqueEquipment: !showNormalHydriqueEquipment});
    };

    const showDefectHydriqueEquipmentHandle = () => {
        setShowDefectHydriqueEquipment(!showDefectHydriqueEquipment);
        setMapFilter({showDefectHydriqueEquipment: !showDefectHydriqueEquipment});
    };

    const renderEquipmentFilters = (type = WATERING_FUNCTIONAL_TYPE) => {
        return (<>
            <div className="row m-0 p-0 w-100">
                <div
                    className={`filter-btn  ml-4 mb-4 on cursor-pointer ${showNormalEquipment === false ? "not-active" : ""}`}
                    onClick={() => showNormalEquipmentHandle()}>
                    <div className="btn-img on"></div>
                    <SzTypographie className="btn-text btn-text-equipment">
                        {t("wateringMap:normal")}
                    </SzTypographie>
                </div>
                <div
                    className={`filter-btn  ml-4 mb-4 off cursor-pointer ${showDefectEquipment === false ? "not-active" : ""}`}
                    onClick={() => showDefectEquipmentHandle()}>
                    <div className="btn-img off"></div>
                    <SzTypographie className="btn-text btn-text-equipment">
                        {t("wateringMap:defect")}
                    </SzTypographie>
                </div>
                <div
                    className={`filter-btn  ml-4 mb-4 unavailable cursor-pointer ${showUnavailableEquipment === false ? "not-active" : ""}`}
                    onClick={() => showUnavailableEquipmentHandle()}>
                    <div className="btn-img unavailable"></div>
                    <SzTypographie className="btn-text btn-text-equipment">
                        {t("wateringMap:unavailable")}
                    </SzTypographie>
                </div>
            </div>
            <div className="row m-0 p-0 w-100">
                <div className="col-6 m-0 p-0 text-right">
                    <SzTypographie className="mt-2">
                        {t("wateringMap:relay")}
                    </SzTypographie>
                </div>
                <div className="col-3 p-0 text-center">
                    <SzIcon variant="line" className="ml-2" icon="wifi-signal-2"/>
                </div>
                <div className="col-2 p-0 m-0">
                    <Switch
                        className="m-0 p-0"
                        checked={showRelay}
                        onChangeHandle={showRelayHandle}
                    />
                </div>
            </div>
            <div className="row m-0 p-0 w-100">
                <div className="col-6  m-0 p-0 text-right">
                    <SzTypographie className="mt-2">
                        {t("wateringMap:meter")}
                    </SzTypographie>
                </div>
                <div className="col-3 p-0 text-center">
                    <SzIcon variant="line" className="ml-2" icon="time-clock-circle-1"/>
                </div>
                <div className="col-2 p-0 m-0">
                    <Switch className="m-0 p-0" onChangeHandle={showMeterHandle} checked={showMeter}/>
                </div>
            </div>
            <div className="row m-0 p-0 w-100">
                <div className="col-6  m-0 p-0 text-right">
                    <SzTypographie className="mt-2">
                        {t("wateringMap:actuator")}
                    </SzTypographie>
                </div>
                <div className="col-3 text-center p-0">
                    <SzIcon variant="line" className="ml-2" icon="time-clock-hand-1"/>
                </div>
                <div className="col-2 p-0 m-0">
                    <Switch className="m-0 p-0" onChangeHandle={showActuatorHandle} checked={showActuator}/>
                </div>
            </div>
            <div className="row m-0 p-0 w-100">
                <div className="col-6  m-0 p-0 text-right">
                    <SzTypographie className="mt-2">
                        {t("wateringMap:sensor")}
                    </SzTypographie>
                </div>
                <div className="col-3 text-center">
                    <SzIcon variant="line" className="ml-2" icon="filter-1"/>
                </div>
                <div className="col-2 p-0 m-0">
                    <Switch className="m-0 p-0" onChangeHandle={showSensorHandle} checked={showSensor}/>
                </div>
            </div>

        </>);
    };


    const renderHydriqueEquipmentFilters = () => {
        return (<>
            <div className="row m-0 p-0 w-100">
                <div
                    className={`filter-btn  ml-4 mb-4 on cursor-pointer ${showNormalHydriqueEquipment === false ? "not-active" : ""}`}
                    onClick={() => showNormalHydriqueEquipmentHandle()}>
                    <div className="btn-img on"></div>
                    <SzTypographie className="btn-text btn-text-equipment">
                        {t("wateringMap:normal")}
                    </SzTypographie>
                </div>
                <div
                    className={`filter-btn  ml-4 mb-4 off cursor-pointer ${showDefectHydriqueEquipment === false ? "not-active" : ""}`}
                    onClick={() => showDefectHydriqueEquipmentHandle()}>
                    <div className="btn-img off"></div>
                    <SzTypographie className="btn-text btn-text-equipment">
                        {t("wateringMap:defect")}
                    </SzTypographie>
                </div>
            </div>

        </>);
    };

    return (
        <>
            <SzBox className="w-100 info-box location-ref pl-2 mb-4" tag="div">
                <div className="row m-0 p-0 hidden">
                    <div className="col-10 location-ref-title mb-4">
                        <SzTypographie weight="bold" color="inactive" variant="caption">
                            {t("wateringMap:filter")}
                        </SzTypographie>
                        <SzTypographie weight="bold"
                                       className="m-0 mr-2">{t("wateringMap:locationReference")}
                        </SzTypographie>
                    </div>
                    < div
                        className="col-2 cursor-pointer">
                        < SzIcon
                            variant="line"
                            onClick={() => {
                                if (!selectedLocationReference) {
                                    setFilterLocationReferenceHandle(!filterLocationReference);
                                }
                            }
                            }
                            icon={filterLocationReference ? "view-1" : "view-off"}/>
                    </div>
                </div>
                <div className="row m-0 p-0 w-100">
                    <div className="location-ref-title mb-4 col-10">
                        <SzTypographie weight="bold" color="inactive" variant="caption">
                            {t("wateringMap:filter")}
                        </SzTypographie>
                        <SzTypographie weight="bold"
                                       color={selectedLocationReference ? "dark" : "disabled"}
                                       className="m-0 float-left mr-2">{t("wateringMap:alarm")}
                        </SzTypographie>
                        < SzIcon
                            className="float-left filter-icon"
                            variant="line"
                            icon="alarm-bell"/>
                    </div>
                    < div
                        className="col-2">
                        < SzIcon
                            onClick={() => {
                                setFilterAlarm(!filterAlarm);
                                setMapFilter({isAlarmActivated: !filterAlarm});
                            }}
                            variant="line"
                            icon={filterAlarm ? "view-1" : "view-off"}/>
                    </div>
                </div>
                {selectedLocationReference && (props.functionalType === WATERING_FUNCTIONAL_TYPE || !props.functionalType) &&
                <>
                    <div className="row m-0 p-0">
                        <div className="location-ref-title mb-4 col-10">
                            <SzTypographie weight="bold" color="inactive" variant="caption">
                                {t("wateringMap:filter")}
                            </SzTypographie>
                            <SzTypographie weight="bold"
                                           color={selectedLocationReference ? "dark" : "disabled"}
                                           className="m-0  mr-2 float-left">{t("wateringMap:watering")}
                            </SzTypographie>
                            <i className="sz-icon-line programming-icon map-icon"/>
                        </div>
                        < div
                            className="col-2">
                            < SzIcon
                                variant="line"
                                icon={filterWatering ? "view-1" : "view-off"}
                                onClick={() => {
                                    setFilterWatering(!filterWatering);
                                    setMapFilter({isWateringActivated: !filterWatering});
                                }}/>
                        </div>
                    </div>

                    {filterWatering && <div className="w-100 mb-4 list-filter">
                        <SzTab
                            activeKey={tab}
                            id="story-tab"
                            selectTabHandle={selectTabHandle}
                        >
                            <SzTab.SzTabContent eventKey="tab-circuit" title={t("wateringMap:tab-circuit")}>
                                <div
                                    className={`filter-btn  ml-4 mb-4 on cursor-pointer ${subFilterCircuitOn === false ? "not-active" : ""}`}
                                    onClick={() => filterCircuitOnHandle(!subFilterCircuitOn)}>
                                    <div className="btn-img on"></div>
                                    <SzTypographie className="btn-text">
                                        {t("wateringMap:on")}
                                    </SzTypographie>
                                </div>
                                <div
                                    className={`filter-btn ml-4 mb-4 unavailable cursor-pointer ${subFilterCircuitOff === false ? "not-active" : ""}`}
                                    onClick={() => filterCircuitOffHandle(!subFilterCircuitOff)}>
                                    <div className="btn-img unavailable"></div>
                                    <SzTypographie className="btn-text">
                                        {t("wateringMap:off")}
                                    </SzTypographie>
                                </div>
                                <div
                                    className={`filter-btn  ml-4 mb-4 partial cursor-pointer ${subFilterCircuitPartial === false ? "not-active" : ""}`}
                                    onClick={() => filterCircuitPartialHandle(!subFilterCircuitPartial)}>
                                    <div className="btn-img partial"></div>
                                    <SzTypographie className="btn-text">
                                        {t("wateringMap:partial")}
                                    </SzTypographie>
                                </div>
                                <div className="row m-0 p-0 w-100">
                                    <div className="col-3 offset-3">
                                        <SzTypographie className="mt-2">
                                            {t("wateringMap:relay")}
                                        </SzTypographie>
                                    </div>
                                    <div className="col-2">
                                        <SzIcon variant="line" className="ml-2" icon="wifi-signal-2"/>
                                    </div>
                                    <div className="col-2 p-0 m-0">
                                        <Switch
                                            className="m-0 p-0"
                                            checked={showCircuitRelay}
                                            onChangeHandle={showCircuitRelayHandle}
                                        />
                                    </div>
                                </div>
                            </SzTab.SzTabContent>
                            <SzTab.SzTabContent eventKey="tab-equipment" title={t("wateringMap:tab-equipment")}>
                                {renderEquipmentFilters()}

                            </SzTab.SzTabContent>

                        </SzTab>
                    </div>

                    }
                </>
                }


                { (props.functionalType === HYDRIQUE_FUNCTIONAL_TYPE || !props.functionalType) &&
                <>
                    {selectedLocationReference &&
                    <div className="row m-0 p-0">
                        <div className="location-ref-title mb-4 col-10">
                            <SzTypographie weight="bold" color="inactive" variant="caption">
                                {t("wateringMap:filter")}
                            </SzTypographie>
                            <SzTypographie weight="bold"
                                           color={selectedLocationReference ? "dark" : "disabled"}
                                           className="m-0  mr-2 float-left">{t("wateringMap:hydrique")}
                            </SzTypographie>
                            < SzIcon variant="line" icon="gardening-hand-sprinkler"/>
                        </div>
                        < div
                            className="col-2">
                            < SzIcon
                                variant="line"
                                icon={filterHydrique ? "view-1" : "view-off"}
                                onClick={() => {
                                    setFilterHydrique(!filterHydrique);
                                    setMapFilter({isHydriqueActivated: !filterHydrique});
                                }}
                            />
                        </div>

                        {filterHydrique &&
                        <div className="w-100 mb-4 list-filter">
                            {renderHydriqueEquipmentFilters()}
                        </div>
                        }
                    </div>
                    }


                    <div className="row m-0 p-0">
                        <div className="location-ref-title mb-4 col-10">
                            <SzTypographie weight="bold" color="inactive" variant="caption">
                                {t("wateringMap:filter")}
                            </SzTypographie>
                            <SzTypographie weight="bold"
                                           color="dark"
                                           className="m-0  mr-2 float-left">{t("wateringMap:hydriquePlant")}
                            </SzTypographie>
                            <i className="sz-icon-line plant-icon"/>
                        </div>
                        < div
                            className="col-2">
                            < SzIcon
                                variant="line"
                                icon={filterHydriquePlant ? "view-1" : "view-off"}
                                onClick={() => {
                                    setFilterHydriquePlant(filterHydriquePlant ? false : true);
                                    setMapFilter({isHydriquePlantActivated: filterHydriquePlant ? false : true});

                                }}
                            />
                        </div>
                        {filterHydriquePlant &&
                        <div className="pl-xl-2  pl-md-0">
                            <SzTab
                                activeKey={tabPlant}
                                id="story-tab-plant"
                                selectTabHandle={selectTabPlantHandle}
                            >
                                <SzTab.SzTabContent eventKey="tab-plant-watering"
                                                    title={t("wateringMap:tab-plant-watering")}>
                                    <div className="row m-0 p-0 w-100 watering-content-tab">
                                        <div
                                            className="filter-btn  ml-4 mb-4 watering-not-zero watering-plant-filter">
                                            <div className="btn-img  watering-plant-filter watering-plant-filter"></div>
                                            <SzTypographie className="btn-text">
                                                {t("hydriqueMonitoring:list:doWatering")}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  watering-zero watering-plant-filter">
                                            <div className="btn-img  "></div>
                                            <SzTypographie className="btn-text">
                                                {t("hydriqueMonitoring:list:notWatering")}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  watering-plant-filter nd">
                                            <div className="btn-img"></div>
                                            <SzTypographie className="btn-text">
                                                {t("wateringMap:nd")}
                                            </SzTypographie>
                                        </div>
                                    </div>
                                </SzTab.SzTabContent>

                                <SzTab.SzTabContent eventKey="tab-plant-humidity"
                                                    title={t("wateringMap:tab-plant-humidity")}>

                                    <div className="row m-0 p-0 w-100">
                                        <div
                                            className="filter-btn  ml-4 mb-4 humidity-1 humidity-plant-filter">
                                            <div className="btn-img  humidity-plant-filter watering-plant-filter"></div>
                                            <SzTypographie className="btn-text">
                                                {'<10%'}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  humidity-2 humidity-plant-filter">
                                            <div className="btn-img  "></div>
                                            <SzTypographie className="btn-text">
                                                {t('wateringMap:10_to_30')}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  humidity-3 humidity-plant-filter">
                                            <div className="btn-img"></div>
                                            <SzTypographie className="btn-text">
                                                {t('wateringMap:30_to_80')}
                                            </SzTypographie>
                                        </div>

                                        <div
                                            className="filter-btn  ml-4 mb-4  humidity-4 humidity-plant-filter">
                                            <div className="btn-img"></div>
                                            <SzTypographie className="btn-text">
                                                {'> 80'}
                                            </SzTypographie>
                                        </div>
                                    </div>
                                </SzTab.SzTabContent>
                                <SzTab.SzTabContent eventKey="tab-plant-risk" title={t("wateringMap:tab-plant-risk")}>
                                    <div className="row m-0 p-0 w-100">
                                        <div
                                            className="filter-btn  ml-4 mb-4 risk-ok risk-plant-filter">
                                            <div className="btn-img  humidity-plant-filter watering-plant-filter"></div>
                                            <SzTypographie className="btn-text">
                                                {t("wateringMap:ok")}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  risk-satur risk-plant-filter">
                                            <div className="btn-img  "></div>
                                            <SzTypographie className="btn-text">
                                                {t("wateringMap:saturated")}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  risk-vigilance risk-plant-filter">
                                            <div className="btn-img"></div>
                                            <SzTypographie className="btn-text">
                                                {t("wateringMap:vigilance")}
                                            </SzTypographie>
                                        </div>

                                        <div
                                            className="filter-btn  ml-4 mb-4  risk-alert risk-plant-filter">
                                            <div className="btn-img"></div>
                                            <SzTypographie className="btn-text">
                                                {t("wateringMap:alert")}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  risk-crise risk-plant-filter">
                                            <div className="btn-img"></div>
                                            <SzTypographie className="btn-text">
                                                {t("wateringMap:Crisis")}
                                            </SzTypographie>
                                        </div>
                                        <div
                                            className="filter-btn  ml-4 mb-4  risk-nd risk-plant-filter">
                                            <div className="btn-img"></div>
                                            <SzTypographie className="btn-text">
                                                {t("wateringMap:nd")}
                                            </SzTypographie>
                                        </div>
                                    </div>
                                </SzTab.SzTabContent>

                            </SzTab>
                        </div>
                        }
                    </div>
                </>
                }

            </SzBox>
        </>
    );
};

export default Filter;
