export enum Constants {
    SET_WATERING_MAP_LOCATION_REFERENCE = "SET_WATERING_MAP_LOCATION_REFERENCE",
    SET_FILTER_MAP_LOCATION_REFERENCE = "SET_FILTER_MAP_LOCATION_REFERENCE",
    SET_SELECT_MAP_LOCATION_REFERENCE = "SET_SELECT_MAP_LOCATION_REFERENCE",
    SET_MAP_ALARMS = "SET_MAP_ALARMS"

}

export const defaultCenter = {lat: "47.444722", lng: "-0.529883"};
export const defaultZoom = 11;
export const DEFAULT_PIN_MAT_COLOR = '#7d8492';
export const OK_RISK_COLOR = '#00bb00';
export const SATURATED_RISK_COLOR = '#0000cc';
export const ALERT_RISK_COLOR = '#FF0000';
export const VIGILANCE_RISK_COLOR = '#FAB51A';
export const CRISIS_RISK_COLOR = '#770088';
export const DO_WATERING_COLOR = '#0d5aa7';
export const DO_NOT_WATERING_COLOR = '#00bb00';
export const FIRST_HUMIDITY_COLOR = '#FAB51A';
export const SECOND_HUMIDITY_COLOR = '#55C277';
export const THIRD_HUMIDITY_COLOR = '#406AE0';
