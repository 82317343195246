import { STATUS_INDEX_DEFECT, STATUS_INDEX_NORMAL, STATUS_INDEX_UNAVAILABLE, STATUS_LIST} from "../../../main/store/constants";
import {Constants} from "../constants";

const initialState: any = {
    listEquipments: {
        pagination: [],
        data: [],
    },
    filters: {
        status: {
            [STATUS_LIST[STATUS_INDEX_DEFECT]]: true,
            [STATUS_LIST[STATUS_INDEX_UNAVAILABLE]]: true,
            [STATUS_LIST[STATUS_INDEX_NORMAL]]: true,
        },
        locationReferences: [],
        query:''
    },
    stats:
        {
            [STATUS_LIST[STATUS_INDEX_DEFECT]]: 0,
            [STATUS_LIST[STATUS_INDEX_UNAVAILABLE]]: 0,
            [STATUS_LIST[STATUS_INDEX_NORMAL]]: 0,
        },
  detailEquipment: null,
};

const EquipmentReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
      case Constants.SET_LIST_EQUIPMENT:
        return {
          ...state,
          listEquipments: { pagination: { ...action.payload.pagination }, data: { ...action.payload.data } },
        };
      case Constants.FILER_STATUS:
        return {
          ...state,
          filters: {...state.filters, status: { ...state.filters.status, [action.payload]: !state.filters.status[action.payload] }},
        };
        case Constants.SET_LOCATION_REFERENCE_FILTER:
            return {
                ...state,
                filters: {...state.filters, locationReferences: action.payload.ids, query: action.payload.query},
            };
      case Constants.SET_EQUIPMENT_STATS:
        return {
          ...state,
          stats: { ...state.stats, ...action.payload },
        };
      case Constants.SET_EQUIPMENT:
        return {
          ...state,
          detailEquipment: action.payload.data,
        };
      default:
        return state;
    }
};

export default EquipmentReducer;
