import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SzAccordion, SzBox, SzButton, SzIcon, SzInput, SzPagination, SzTypographie,SzSelect} from "@suezenv/react-theme-components";
import {mapAction} from "../../../map/store/actions";
import ProgrammingCircuit from "../../components/Programming/ProgrammingCircuit";
import EmptyRow from "../../components/utils/EmptyRow";
import LocationReferenceService from "../../../main/services/LocationReferenceService";
import ProgrammingService from "../../services/ProgrammingService";
import {measureAction} from "../../store/actions";
import programmingAction from "../../store/actions/programmingAction";
import {
    MAX_NBR_ALL_PI,
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT,
    PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT, WATERING_FUNCTIONAL_TYPE,
} from "../../../main/store/constants";
import {withRouter} from "react-router";
import {routesPath} from "../../../routes";
import {MAX_MOBILE_WIDTH} from "../../../main/store/constants";
import locationReference from "../../../main/store/actions/locationReference";
import MeasureService from "../../services/MeasureService";
import {
    CIRCUIT_MODE_ALL,
    CIRCUIT_MODE_CENTER, CIRCUIT_MODE_FORCED,
    CIRCUIT_MODE_SEVRAGE,
    REASON_DUPLICATE,
    REASON_FALSE_ALERT,
    REASON_SOLVED
} from "../../store/constants";

class ProgrammingManager extends React.Component<any> {
    public state = {
        page: 1,
        openedCircuit: 0,
        search: this.props.filters.query,
        forecast: [],
        selectedLocationReference: null,
        selectedMode: {value: "0", label: this.props.t('programming:mode.all')},
    };



    public componentDidMount() {
        if((window.screen && window.screen.width >= MAX_MOBILE_WIDTH)) {
            this.init();
            this.setForecast();
        }
        else
        {
            this.initMobile();
        }
        this.setForecast();

    }

    public initMobile()
    {
        LocationReferenceService.getLocationReferences("", 1, MAX_NBR_ALL_PI, true, 1, WATERING_FUNCTIONAL_TYPE).then((response: any) => {
            const locationReferences = response.data;

            this.props.setLocationReferences(locationReferences);
            const {circuit,location} = this.props;
            if(circuit && location.state && location.state.previous === routesPath.programming.config)
            {
                this.selectMobileLocationReference(circuit.locationReference);
                this.setState({selectedLocationReference:{value:circuit.locationReference.id,label: `${circuit.locationReference.sigId} - ${circuit.locationReference.name}`}});
            }

        });
    }

    public selectMobileLocationReference(locationReference:any)
    {
        const filters: any = {
            ...this.props.filters,
            locationReferences: [locationReference]
        };
        ProgrammingService.getCircuits(this.state.page, filters).then((response: any) => {
            this.props.setCircuitList(response);
            this.props.filterLocationReference([locationReference]);
        });
    }

    public componentDidUpdate(prevProps: { listCircuits: any }) {

        if (prevProps.listCircuits !== this.props.listCircuits) {
            if (Object.values(this.props.listCircuits.data).length !== 0) {
                this.setCalendar();
                this.setProgram();
                this.setLastMeasures();
            }
        }
    }

    public init() {
        const {filters, setCircuitList} = this.props;
        if (filters.locationReferences && filters.locationReferences.length > 0) {
            ProgrammingService.getCircuits(1, filters).then((response: any) => {
                setCircuitList(response);
            });
        } else {
            this.setData();
        }
    }

    public setData(page = 1, filtres:any = null) {
        ProgrammingService.getCircuits(page, filtres ? filtres : this.props.filters).then((response: any) => {
            this.props.setCircuitList(response);
        });
    }

    public searchHandle() {
        const query = this.state.search;
        LocationReferenceService.getLocationReferences(query).then((response: any) => {
            const locationReferencesId = response.data.length === 0 && query ? ["notFoundLocationReferences"] : response.data;
            const filters: any = {
                ...this.props.filters,
                locationReferences: locationReferencesId,
                mode: this.state.selectedMode ? this.state.selectedMode.value : null,
            };
            ProgrammingService.getCircuits(1, filters).then((response: any) => {
                this.props.setCircuitList(response);
                this.props.filterLocationReference({ids: locationReferencesId, query});
            });
        });
    }

    public resetHandle() {
        this.props.filterLocationReference({ids: [], query: ""});
        this.setData(1,{ locationReferences: [] });
        this.setState({search: ""});
    }

    public enterKeyHandle(event: any) {
        if (event.key === "Enter") {
            this.searchHandle();
        }
    }

    public openCircuitHandle(index: any) {
        this.setState({openedCircuit: index !== this.state.openedCircuit ? index : -1});
    }

    public setProgram() {

        ProgrammingService.getProgram(this.props.listCircuits.data, []).then((response: { data: any }) => {
            this.props.setProgramList(response.data);
        });
    }

    public setLastMeasures() {

        MeasureService.getLastMeasures(this.props.listCircuits.data).then((response: { data: any }) => {
            this.props.setLastMeasures(response.data);
        });
    }

    public setCalendar() {

        ProgrammingService.getCalendar(this.props.listCircuits.data).then((response: { data: any }) => {
            this.props.setCalendarList(response.data);
        });
    }

    public hasCalendar(circuit: { id: any }) {
        let hasNoCalendar = true;
        const today = new Date();

        if (this.props.calendars.length !== 0) {
            this.props.calendars.map((calendar: any) => {
                if (calendar.circuitId === circuit.id && today.getDay() === calendar.day) {
                    hasNoCalendar = calendar.isActive;
                }
            });
        }
        return hasNoCalendar;
    }

    public updateCalendar(circuitId: string, day: Date, isActive: boolean) {
        ProgrammingService.updateCalendar(circuitId, day, isActive).then(() => {
            ProgrammingService.getCalendar(this.props.listCircuits.data).then((response: { data: any }) => {
                this.props.setCalendarList(response.data);
            });
        });
    }

    public handleSelectedCircuit(circuit: any) {
        this.props.setSelectedCircuit(circuit);
    }

    public handleSelectedModule(module: any) {
        this.props.setSelectedModule(module);
    }

    public setForecast() {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() + 1);
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 7);

        ProgrammingService.getForecast(startDate, endDate).then((response) => {
            this.setState({forecast: response.data});
        });
    }

    public  getLocationReferenceOptions()
    {
        const options:any =[];
        this.props.locationReferences.forEach((locationReference:any)=>
        {
            options.push({value: locationReference.id, label: `${locationReference.sigId} - ${locationReference.name}`});
        });
        return options;
    }

    public selectLocationReferenceHandle(selectedOption:any)
    {
        this.setState({selectedLocationReference:selectedOption});
        this.selectMobileLocationReference({id:selectedOption.value});
    }

    public renderListCircuit() {
        const {listCircuits, setMeasureDetailDayByStep, measureDetailDayByStep, calendars, circuit, location, programs, lastMeasures} = this.props;
        const isProgrammingPrevious = circuit && location.state && location.state.previous === routesPath.programming.config && 0 === this.state.openedCircuit;
        const isMobile = (window.screen && window.screen.width < MAX_MOBILE_WIDTH);
        return (
            <>
                {isMobile &&
                    <>
                        <SzSelect
                            id={'id-1'}
                            placeholder={'Sélectionner un PI'}
                            options={this.getLocationReferenceOptions()}
                            value={this.state.selectedLocationReference}
                            onChange={this.selectLocationReferenceHandle.bind(this)}
                            isSearchable={true}
                            className="mb-3 border-secondary"
                            name={'input-1'}
                        />
                    </>
                }
                <SzAccordion activeKey={isProgrammingPrevious ? circuit.id : this.state.openedCircuit}>
                    {Object.keys(this.props.listCircuits.data).length > 0 ? Object.values(this.props.listCircuits.data).map((circuit: any, index: any) => {
                        return <ProgrammingCircuit
                            programs={programs}
                            updateCalendar={this.updateCalendar.bind(this)}
                            openCircuitHandle={this.openCircuitHandle.bind(this)}
                            circuit={circuit}
                            hasCalendar={this.hasCalendar(circuit)}
                            calendars={calendars}
                            lastMeasures={lastMeasures}
                            selectCircuit={this.handleSelectedCircuit.bind(this)}
                            selectModule={this.handleSelectedModule.bind(this)}
                            index={circuit.id}
                            key={circuit.id}
                            setMeasureDetailDayByStep={setMeasureDetailDayByStep}
                            measureDetailDayByStep={measureDetailDayByStep}
                            filterMapLocationReference={this.props.filterMapLocationReference}
                            forecast={this.state.forecast}
                            initHandle = {this.init.bind(this)}
                        />
                    }) : <EmptyRow/>}
                </SzAccordion>
                {listCircuits.pagination[PAGINATION_PAGE_COUNT] > 1 && (
                    <div className="list-table row m-2">
                        <div className="row w-100">
                            <div className="col-6 w-100 offset-5">
                                <SzPagination
                                    totalItemsCount={parseInt(listCircuits.pagination[PAGINATION_TOTAL_COUNT], 10)}
                                    activePage={parseInt(listCircuits.pagination[PAGINATION_CURRENT_PAGE], 10)}
                                    onChange={(pageNumber: any) => {
                                        this.setState({page: pageNumber});
                                        this.setData(pageNumber);
                                    }}
                                    itemsCountPerPage={parseInt(listCircuits.pagination[PAGINATION_PER_PAGE], 10)}
                                    pageRangeDisplayed={parseInt(listCircuits.pagination[PAGINATION_PAGE_COUNT], 10)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }

    public getOptions() {
        return [
            {value: CIRCUIT_MODE_ALL.toString(), label: this.props.t(`programming:mode.all`)},
            {value: CIRCUIT_MODE_FORCED.toString(), label: this.props.t(`programming:mode.${CIRCUIT_MODE_FORCED}`)},
            {value: CIRCUIT_MODE_SEVRAGE.toString(), label: this.props.t(`programming:mode.${CIRCUIT_MODE_SEVRAGE}`)},
            {value: CIRCUIT_MODE_CENTER.toString(), label: this.props.t(`programming:mode.${CIRCUIT_MODE_CENTER}`)},
        ];
    }

    public selectChangeHandle(option: any) {
        if (option) {
            this.setState({selectedMode: option});
            const filters: any = {
                ...this.props.filters,
                mode: option.value,
            };
            ProgrammingService.getCircuits(1, filters).then((response: any) => {
                const {query,locationReferences,mode} = filters;
                this.props.setCircuitList(response);
                this.props.filterLocationReference({ids: locationReferences, query, mode});
            });
        } else {
            this.setState({selectedMode: {value: CIRCUIT_MODE_ALL.toString(), label: this.props.t(`programming:mode.all`)}});
        }
    }

    public render() {
        return (
            <>
                <div className=" d-none d-xl-block">
                    <SzBox className="row equipment-info-box m-0 p-0 mt-4 mb-4  cursor-pointer" tag="div">
                        <div className="col-1 mt-3 d-none d-xl-block d-md-block ">
                            <i className="sz-icon-line programming-icon float-left"/>
                        </div>
                        <div className="col-md-4 col-sm-12 mt-3">
                            <SzTypographie
                                className="m-0">{this.props.t("programming:btn.circuit_watering_text1")}</SzTypographie>
                            <SzTypographie color="inactive" variant="caption">
                                {this.props.t("programming:btn.circuit_watering_text2")}
                            </SzTypographie>
                        </div>
                        <div className="col-md-3 col-sm-12 mt-3">
                            <SzSelect
                                className="mb-1"
                                onChange={this.selectChangeHandle.bind(this)}
                                name="mode"
                                placeholder={this.props.t("programming:select_mode")}
                                options={this.getOptions()}
                                value={this.state.selectedMode.value ? this.state.selectedMode : null}
                                isClearable={true}
                            />
                        </div>
                        <div className="offset-sm-0 mt-3 col-md-4 col-sm-12 d-none d-xl-block ">
                            <div className="row m-0 p-0">
                                <div className="col-8 p-0">
                                    <SzInput
                                        value={this.state.search}
                                        onKeyUp={this.enterKeyHandle.bind(this)}
                                        onChange={({target}) => this.setState({search: target.value})}
                                        placeholder={this.props.t("equipment:select_location_reference")}
                                    />
                                </div>
                                <div className="col-2 m-0 text-center">
                                    <SzButton onClick={this.searchHandle.bind(this)} variant="secondary" icon="search"/>
                                </div>

                                {this.state.search &&
                                < div className="col-2 m-0  p-0">
                                    <SzButton onClick={this.resetHandle.bind(this)} variant="secondary"
                                              icon="diagram-round"/>
                                </div>
                                }
                            </div>
                        </div>
                    </SzBox>
                </div>

                {this.renderListCircuit()}
            </>
        );
    }
}

const mapDispatchToProps = {
    setCircuitList: programmingAction.setCircuitsList,
    setSelectedCircuit: programmingAction.setSelectedCircuit,
    setSelectedModule: programmingAction.setSelectedModule,
    setCalendarList: programmingAction.setCalendarList,
    setProgramList: programmingAction.setProgramList,
    setLastMeasures: programmingAction.setLastMeasures,
    filterLocationReference: programmingAction.filterLocationReference,
    setMeasureDetailDayByStep: measureAction.setMeasureDetailDayByStep,
    filterMapLocationReference: mapAction.filterMapLocationReference,
    setLocationReferences: locationReference.setLocationReferences


};
const mapStateToProps = (state: any) => {
    return {
        listCircuits: state.programming.listCircuits,
        filters: state.programming.filters,
        calendars: state.programming.calendars,
        lastMeasures: state.programming.lastMeasures,
        circuit: state.programming.selectedCircuit,
        measureDetailDayByStep: state.measure.measureDetailDayByStep,
        programs:state.programming.programs,
        locationReferences: state.locationReference.list
    };
};
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(ProgrammingManager)));
