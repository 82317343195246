import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Frame from "../../main/utils/Frame";
import {measureAction, programmingAction} from "../../store/actions";
import {withRouter} from "react-router";
import EquipmentService from "../services/EquipmentService";
import HydriqueService from "../services/HydriqueService";

class UrbasenseManager extends React.Component<any> {
  public state = {
      path: "",
  };

  public componentDidMount() {
    this.init();
  }

    public init() {

      const {moduleId, plantId} = this.props.match.params;
        EquipmentService.getEquipment(moduleId).then((response:any) => {
            if (response.data) {
                const module: any = response.data;
                const {circuit} = module;
                if(circuit) {
                    this.generateLink(circuit.externalId, plantId);
                }

            }

        });
    }

    public generateLink(circuitId: string, plantId:string) {
        HydriqueService.generateUrbasenseLink(circuitId, plantId).then((response: any) => {
            const path = response.data;
            this.setState({path});
        });
    }

    public render() {
        return (
            <>
                {(this.state.path) &&
                <>
                    < Frame url={this.state.path} sandbox={true}/>
                </>
                }
            </>
        );
    }
}

const mapDispatchToProps = {


};
const mapStateToProps = (state: any) => {
    return {
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UrbasenseManager)));
