import instanceRequest, {Request} from "./Request";
import {formatIsoDateTime} from "../utils";

class MeteoService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getForecast(startAt: any, endAt: any) {
        const url = `/api/forecast?startAt=${startAt}&endAt=${endAt}`;


        return this.axiosService.get(url, false, false).then((response:any) => {
            return {
                data: response.data,
            };
        });
    }

    public getPrecipitationYesterday() {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday.setHours(0);
        yesterday.setMinutes(0);
        yesterday.setSeconds(0);
        const startAt = formatIsoDateTime(yesterday);

        const today = yesterday;
        today.setDate(today.getDate() +1);
        const endAt = formatIsoDateTime(today);
        const url = `/api/forecast/precipitationVolumes?startAt=${startAt}&endAt=${endAt}&number=1`;


        return this.axiosService.get(url, false, false).then((response:any) => {
            return {
                data: response.data,
            };
        });
    }

}

export default new MeteoService(instanceRequest);
