import instanceRequest, {Request} from "../../main/services/Request";
import {
    PAGINATION_HEADERS,
} from "../../main/store/constants";

import {formatServerDate} from "../../main/utils";
import {CIRCUIT_MODE_ALL} from "../store/constants";

class ProgrammingService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getCircuits(page = 1, filters = {
        locationReferences: [],
        mode: null
    }, functionalType = 'arrosage', itemsPerPage = 20) {
        const functionalTypeParam = functionalType ? `&functionalType=${functionalType}` : "";
        const modeParam = (filters.mode && filters.mode !== CIRCUIT_MODE_ALL.toString()) ? `&mode=${filters.mode}` : "";
        const body = {locationReference: filters.locationReferences ? filters.locationReferences.map((item: any) => item.id) : []};
        const url = `/api/circuit/list?currentPage=${page}&itemsPerPage=${itemsPerPage}${functionalTypeParam}${modeParam}`;
        return this.axiosService.post(url, body, false).then((response) => {
            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data,
            };
        });
    }

    public updateCalendar(circuit: any, day: Date, isActive: boolean) {
        const sector = circuit.locationReference ? circuit.locationReference.sector : null;
        const sectorId = sector ? sector.id : null;
        const body = {circuitId: circuit.id, isActive, day: formatServerDate(day), sectorId};
        const url = `/api/calendar`;
        return this.axiosService.post(url, body, false);
    }


    public updateWatering(circuit: any, isActive: boolean) {
        const body = {state: isActive ? 'on' : 'off'};
        const url = `/api/watering/circuit/${circuit.id}/state`;
        return this.axiosService.post(url, body, false);
    }

    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }

    public getCalendar(circuits: [], day: string | null = null) {
        let filterQuery = "";
        Object.values(circuits).map((item: any) => {
            filterQuery = filterQuery ? `${filterQuery}&circuit[]=${item.id}` : `circuit[]=${item.id}`;
        });
        const queryDay = day ? `day[]=${day}` : "";
        filterQuery = filterQuery ? `${filterQuery}&${queryDay}` : `day[]=${day}`;
        const url = `/api/calendar?${filterQuery}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return {
                data: response.data,
            };
        });
    }

    public getProgram(circuits: any[], modules: any[], day: string | null = null) {
        let filterQuery = "";
        Object.values(circuits).map((item: any) => {
            filterQuery = filterQuery ? `${filterQuery}&circuit[]=${item.id}` : `circuit[]=${item.id}`;
        });
        Object.values(modules).map((item: any) => {
            filterQuery = filterQuery ? `${filterQuery}&module[]=${item.id}` : `module[]=${item.id}`;
        });
        const url = `/api/program?${filterQuery}&sortKey=externalId&sortDir=asc`;
        return this.axiosService.get(url, false, false).then((response) => {
            return {
                data: response.data,
            };
        });
    }

    public getForecast(startDate: Date, endDate: Date) {
        const url = `/api/forecast?startAt=${startDate.toISOString().split(".")[0] + "Z"}&endAt=${endDate.toISOString().split(".")[0] + "Z"}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return response;
        });
    }

    public generateConfigLink(moduleId: any) {
        const url = `/api/programme/link/${moduleId}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return {
                data: response.data,
            };
        });
    }

    public pushEventVisited(moduleId: string) {
        const url = `/api/event/setting/visited?moduleId=${moduleId}`;
        return this.axiosService.get(url, false, false);
    }

    public getAdjustConfigs(circuitId: string, type: string, moduleId: string | null = null) {
        let url = `/api/adjust_config?circuitId=${circuitId}&type=${type}`;
        url = moduleId ? `${url}&moduleId=${moduleId}` : url;
        return this.axiosService.get(url, false, false).then((response) => {
            return response.data;
        });
    }

    public editAdjustConfig(adjustConfig: any) {
        const body = {
            circuitId: adjustConfig.circuitId,
            enable: adjustConfig.enable,
            thresholdMax: adjustConfig.thresholdMax,
            thresholdMin: adjustConfig.thresholdMin,
            type: adjustConfig.type,
            value: adjustConfig.value,
            moduleId:adjustConfig.moduleId
        };

        const url = `/api/adjust_config/${adjustConfig.id}`;
        return this.axiosService.put(url, body, false).then((response) => {
            return {
                data: response.data,
            };
        });
    }

    public newAdjustConfig(adjustConfig: any) {
        const body = adjustConfig;
        const url = `/api/adjust_config`;
        return this.axiosService.post(url, body, false).then((response) => {
            return {
                data: response.data,
            };
        });
    }

    public getProgramById(programId: string) {
        const url = `/api/program/${programId}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return response.data;
        });
    }

    /**
     * @param programId
     * @param body
     */
    public saveProgram(programId: string, body: any) {
        const url = `/api/program/${programId}`;

        return this.axiosService.put(url, body, false).then((response) => {
            return response.data;
        });
    }

    public getComponentsByModule(moduleId: string, type: string, name: string) {
        const url = `/api/module/${moduleId}/composant?type=${type}&name=${name}&sortKey=externalId&sortDir=asc&status=1`;
        return this.axiosService.get(url, false, false).then((response) => {
            return response.data;
        });
    }

    public saveComponents(moduleId: string, body: any) {
        const url = `/api/module/${moduleId}/composants`;

        return this.axiosService.put(url, body, false).then((response) => {
            return response.data;
        });
    }

    public sendManuelWatering(moduleId: string, body: any) {
        const url = `/api/program/${moduleId}`;

        return this.axiosService.post(url, body, false).then((response) => {
            return response.data;
        });
    }
}

export default new ProgrammingService(instanceRequest);
