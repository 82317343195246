import classnames from "classnames";
import React from "react";
import {withTranslation} from "react-i18next";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { SzIcon, SzSideMenu } from "@suezenv/react-theme-components";
import {routesPath} from "../../../routes";
import ErrorAuthenticationMessage from "../../../user/components/messages/ErrorAuthenticationMessage";
import Header from "../Header";
import Loading from "../Utils/loading";
import ErrorMessage from "../Utils/messages/errorMessage";
import "./container.scss";
import {config} from "../../../config";

export enum menuItems {
  home = "home",
  watering = "watering",
  referentialAdmin = "referentialAdmin",
  userAdmin = "userAdmin",
  programming = "programming",
  consumption = "consumption",
  alarm = "alarm",
  map = "map",
  hydriqueCircuit = "hydriqueCircuit"
}
const SUB_MENU:any = {
    programming : [
        routesPath.consumption,
        routesPath.watering,
        routesPath.map,
        routesPath.programming
    ],
    hydriqueCircuit: [
        routesPath.hydriqueCircuit,
        routesPath.hydriqueMap,
        routesPath.hydriqueEquipment
    ]
};
class Container extends React.Component<any> {

  public state = {
    currentTab: menuItems.home,
    isOpen: false,
  };
  public componentDidMount(): void {
    let currentTab: string = menuItems.home;
    this.setState({currentTab});
  }

public render() {
    const { children, connected, error, errorUser, loading, t, roles} = this.props;
    const asideClasses = classnames("alm-ev-aside float-left d-none d-xl-block",
        (this.state.isOpen ? "sz-aside.sz-aside-enter-done" : "sz-aside-exit-done"),
        connected);
    const containerClasses = classnames("alm-ev-container", {
      connected,
      isOpen: connected && this.state.isOpen,
    });

    const menu = [
        {
            className: "material-icons",
            icon: <SzIcon variant="line" icon="house-3"/>,
            link: routesPath.home,
            name: menuItems.home,
            title: t(menuItems.home),
        },
        {
            className: "programming-menu-item",
            icon: <i className="sz-icon-line programming-icon"/>,
            link: routesPath.programming.index,
            name: menuItems.programming,
            title: t(menuItems.programming),
        },
        {
            className: "programming-menu-item",
            icon:< SzIcon  variant="line" icon="gardening-hand-sprinkler"/>,
            link: routesPath.hydriqueCircuit,
            name: menuItems.hydriqueCircuit,
            title: t(menuItems.hydriqueCircuit),
        },
        {
            className: "material-icons",
            icon: <SzIcon variant="line" icon="alarm-bell"/>,
            link: routesPath.alarm,
            name: menuItems.alarm,
            title: t(menuItems.alarm),
        }
    ];

    const menuAdmin = [
      {
        className: "material-icons",
        icon: <SzIcon variant="line" icon="cog" />,
        link: routesPath.referentieladmin,
        name: menuItems.referentialAdmin,
        title: t(menuItems.referentialAdmin),
      },
        {
            className: "material-icons",
            icon: <SzIcon variant="line" icon="single-neutral-actions-setting" />,
            link: routesPath.useradmin,
            name: menuItems.userAdmin,
            title: t(menuItems.userAdmin),
        },
    ];

    return (
        <>
          {error.message && !error.jwtCheck && <ErrorMessage show={true}/>}
          {errorUser.message && errorUser.jwtCheck && <ErrorAuthenticationMessage show={true}/>}
          <div className="alm-ev-header-container">
            <Header handleOnClick={(e) => this.setState({isOpen: !this.state.isOpen})} menuIsOpen={this.state.isOpen}
                    currentTab={this.state.currentTab}/>
          </div>
          {loading && <Loading/>}
          { connected && (
              <>
                <SzSideMenu className={asideClasses} isOpen={this.state.isOpen}>
                  <ul className="sz-aside__list list-unstyled m-0">
                    {menu.map((item, key) => (
                        <li key={key} className="d-flex align-items-center" onClick={() => this.setState({currentTab: item.name})}>
                          <NavLink
                              activeClassName="active"
                              className={`sz-aside__item d-flex align-items-center
                    font-weight-bold text-uppercase text-decoration-none pl-3  ${SUB_MENU[item.name] && SUB_MENU[item.name].includes(this.props.history.location.pathname) ? "active" : ""}`}
                              exact={true}
                              to={item.link}
                          >
                            <i className={`sz-aside__item--icon ${item.className}`}>{item.icon}</i>
                            <span className={"sz-aside__item--title"}>{item.title}</span>
                          </NavLink>
                        </li>
                    ))}
                  </ul>
                    {roles.includes("ROLE_ADMIN") && <ul className="alm_admin list-unstyled m-0">
                        {menuAdmin.map((item, key) => (
                            <li key={key} className="d-flex align-items-center"
                                onClick={() => this.setState({currentTab: item.name})}>
                                <NavLink
                                    activeClassName="active"
                                    className="alm_admin__item d-flex align-items-center
                              font-weight-bold text-uppercase text-decoration-none"
                                    exact={true}
                                    to={item.link}
                                >
                                    <i className={`sz-aside__item--icon ${item.className}`}>{item.icon}</i>
                                    <span className={"sz-aside__item--title"}>{item.title}</span>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    }
                </SzSideMenu>
              </>
          )}

            <div className="alm-ev-main-container">
            <div className="no-padding container-content">
                {connected}
              <div className={containerClasses}>{children}</div>
                <div className="alm-version">{`Version : ${config.REACT_APP_VERSION}`}</div>
            </div>
          </div>
        </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    connected: state.user.logged,
    roles: state.user.roles,
    error: state.error,
    errorUser: state.errorUser || null,
    loading: state.loading,
    showResultDetail: false,
    showSearchResult: false,
    pathname: state.router.location.pathname,
  };
};

export default connect(mapStateToProps)(withTranslation()(Container));
