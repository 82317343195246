import instanceRequest, {Request} from "../../main/services/Request";
import {
    PAGINATION_HEADERS,
    STATUS_INDEX_DEFECT,
    STATUS_INDEX_NORMAL,
    STATUS_INDEX_UNAVAILABLE,
    STATUS_LIST,
} from "../../main/store/constants";

class EquipmentService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getEquipments(
        page = 1,
        filters = {status: null, locationReferences: [], equipment: [], query : ""},
        sortField = "status_state_changed_at",
        sortDirection = "desc",
        itemsPerPage = 20,
        functionalType="suivi_hydrique"
    ) {
        let filtersStatusQuery = "";
        if (filters.status) {
            filtersStatusQuery = this.formatStatusFilters(filters.status);
        }
        const body = {
            locationReference: filters.locationReferences ? filters.locationReferences.map((item: any) => item.id) : [],
            equipment: filters.equipment || []
        };

        // all filters are deactivated
        if (filtersStatusQuery === "" && filters.status) {
            return new Promise((resolve, reject) => {
                resolve({
                    pagination: [],
                    data: [],
                });
            });
        }
        const url = `/api/equipment/list?currentPage=${page}&itemsPerPage=${itemsPerPage}&sortKey=${sortField}&sortDir=${sortDirection}&${filtersStatusQuery}&functionalType=${functionalType}&query=${filters.query}`;
        return this.axiosService.post(url, body, false).then((response) => {
            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data,
            };
        });
    }

    public getEquipment(id: string) {
        const url = `/api/equipment/${id}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return {
                data: response.data,
            };
        });
    }

    public getEquipmentStats(
        filters = {status: null, locationReferences: [], query: ""},
        functionalType="suivi_hydrique"
    ) {
        let filtersStatusQuery = "";
        if (filters.status) {
            filtersStatusQuery = this.formatStatusFilters(filters.status);
        }
        const body = {locationReference: filters.locationReferences ? filters.locationReferences.map((item: any) => item.id): []};

        // all filters are deactivated
        if (filtersStatusQuery === "" && filters.status) {
            return new Promise((resolve, reject) => {
                resolve({
                    [STATUS_LIST[STATUS_INDEX_DEFECT]]: 0,
                    [STATUS_LIST[STATUS_INDEX_UNAVAILABLE]]: 0,
                    [STATUS_LIST[STATUS_INDEX_NORMAL]]: 0,
                });
            });
        }
        const url = `/api/equipment/count/status?${filtersStatusQuery}&functionalType=${functionalType}&query=${filters.query}`;
        return this.axiosService.post(url, body, false).then((response) => {
            return response.data;
        });
    }

    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }

    private formatStatusFilters(filters: any) {
        let filterQuery = "";
        for (const [key, value] of Object.entries(filters)) {
            if (value === true) {
                filterQuery = filterQuery ? `${filterQuery}&filterStatus[]=${key}` : `filterStatus[]=${key}`;
            }
        }

        return filterQuery;
    }
}

export default new EquipmentService(instanceRequest);
