import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzButton, SzIcon, SzList, SzTypographie} from "@suezenv/react-theme-components";
import MeasureDetailService from "../../services/MeasureDetailService";
import MeasureService from "../../services/MeasureService";
import {DAY_LIST, MONTH_LIST} from "../../store/constants";
import ProgrammingChart from "./ProgrammingChart";
import ProgramminFlowChart from "./ProgrammingFlowChart";
import {SOLEM_SOURCE} from "../../../main/store/constants";

const CURRENT_MONTH = 31;
const ProgrammingLastDayModal: any = (props: any) => {
    const {t} = useTranslation();
    const LAST_7_DAYS = "last7Days";
    const LAST_THREE_MONTH = "lastThreeMonth";
    const {circuit, measureDetailDayByStep} = props;
    const [indexDay, setIndexDay] = useState(circuit.source === SOLEM_SOURCE ? "0" : LAST_7_DAYS);
    const [step, setStep] = useState(24);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const CURRENT_MONTH = "currentMonth";
    const formatDate = (date: Date) => {

        const day = t(DAY_LIST[date.getDay()]);
        const month = t(MONTH_LIST[date.getMonth()]);
        const nbrDay = (date.getDate() < 10) ? `0${date.getDate()}` : date.getDate();
        return `${day} ${nbrDay} ${month}`;
    };
    useEffect(() => {
        if(circuit.source === SOLEM_SOURCE ) {
            const startDate: Date = new Date();
            startDate.setDate(startDate.getDate() - 1);
            selectDay(0, startDate);
        }else
        {
            selectPeriod(LAST_7_DAYS);
        }
    }, [circuit]);

    const selectDay = (index: any, startDate: Date) => {
        const EndDate: Date = new Date();
        EndDate.setDate(startDate.getDate() + 1);
        setIndexDay(index);
        MeasureDetailService.getMeasureDetailDayByStep(circuit.id, startDate, EndDate).then((data: any) => {
           props.setMeasureDetailDayByStep(data);
        });
    };
    const last7Days = () => {
        const result = [];
        for (let i = 1; i <= 7; i++) {
            const date: Date = new Date();
            date.setDate(date.getDate() - i);
            result.push({date, formatDate: formatDate(date)});
        }

        return result;
    };

    const selectPeriod = (index: any) => {
        setIndexDay(index);
        let numberOfStep = index === LAST_7_DAYS ? 7 : 31;
        if (index === LAST_THREE_MONTH) {numberOfStep = numberOfStep * 3; }
        const endDate: Date = new Date();
        const startDate: Date = new Date();
        startDate.setDate(startDate.getDate() - numberOfStep);
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        setStep(numberOfStep);
        MeasureService.getFlowMeasureBetweenDates(circuit.id, startDate, endDate).then((data: any) => {
           props.setMeasureDetailDayByStep(data);
        });
    };

    const getSensorData =  ()=>
    {
       return props.measureDetailDayByStep && props.measureDetailDayByStep.sensor
            ? props.measureDetailDayByStep.sensor
            : [];
    }
    const renderDaysBtn = () => {
        return (
            <>
                {circuit.source === SOLEM_SOURCE &&
                <div className="mt-4 ml-4 text-center">
                    {
                        last7Days().map((item: any, index: any) =>
                            <SzButton isDisabled={indexDay === index} key={index} variant="secondary"
                                      onClick={() => selectDay(index, item.date)} className="mr-2 ml-2 mb-4 float-left">
                                {item.formatDate}
                            </SzButton>,
                        )
                    }
                </div>
                }
            <div className="mt-4 ml-4 text-center">
                <SzButton isDisabled={indexDay === LAST_7_DAYS} key="current_7_days" variant="secondary" onClick={() => selectPeriod(LAST_7_DAYS)} className="mr-2 ml-2 mb-4 float-left">
                    {t("programming:current7Days")}
                </SzButton>
                <SzButton isDisabled={indexDay === CURRENT_MONTH} key="current_month" variant="secondary" onClick={() => selectPeriod(CURRENT_MONTH)} className="mr-2 ml-2 mb-4 float-left">
                        {t("programming:currentMonth")}
                </SzButton>

                <SzButton isDisabled={indexDay === LAST_THREE_MONTH} key="last_three_month" variant="secondary" onClick={() => selectPeriod(LAST_THREE_MONTH)} className="mr-2 ml-2 mb-4 float-left">
                        {t("programming:lastThreeMonth")}
                </SzButton>
            </div>
          </>);
    };
    return (
      <>
        <SzList>
          <SzList.Item>
            <div className="row">
              <div className="col-1">
                <SzIcon variant="line" icon="analytics-bars-3d" />
              </div>
              <div className="col-3">
                <SzTypographie className="mb-1">{t("programming:last_day_title")}</SzTypographie>
                <SzTypographie className="m-0" color="inactive" variant="caption">
                  {t("programming:last_day_title_2")}
                </SzTypographie>
              </div>
            </div>
          </SzList.Item>
        </SzList>
        <div className="row ml-3 mt-2 mb-4 ">
          <div className="col-2">
            <SzTypographie className="m-0" color="inactive" variant="caption">
              {t("programming:circuit")}
            </SzTypographie>
            <SzTypographie>{circuit.sigId}</SzTypographie>
          </div>
          <div className="col-2">
            <SzTypographie className="m-0" color="inactive" variant="caption">
              {t("programming:mode.title")}
            </SzTypographie>
            <SzTypographie>{t(`programming:mode.${circuit.mode}`)}</SzTypographie>
          </div>
        </div>
        {renderDaysBtn()}
        <div className="row w-100 p-0 m-0">
          <div className="offset-5 col-6 mt-5">
            <SzTypographie>{t("programming:evolution_watering")}</SzTypographie>
          </div>
        </div>
        <div className="row w-100 p-0 m-0">
            <div className="col-4 offset-6 mt-2">
                <div className="float-left mr-5">
                    {t("programming:legend_watering")} <span className="legend-watering-humidity m-1"></span>
                </div>
                {props.circuitHasHumidity &&
                <div>
                    {t("programming:legend_humidity")} <span
                    className="legend-watering-humidity humidity m-1"></span>
                </div>
                }
            </div>
        </div>
        <div className="row w-100 mt-5 p-0 m-0">
          <div className="col-12">
            {indexDay === CURRENT_MONTH || indexDay === LAST_THREE_MONTH || indexDay === LAST_7_DAYS  ?
                <ProgramminFlowChart  measureDetailDayByStep={measureDetailDayByStep}
                                     startAt={selectedStartDate} endAt={selectedEndDate} step={step}
                                      circuitHasHumidity = {props.circuitHasHumidity} /> :
                <ProgrammingChart  circuit={circuit} measureDetailDayByStep={measureDetailDayByStep}
                                   circuitHasHumidity = {props.circuitHasHumidity}/>}
          </div>
        </div>
      </>
    );
};

export default ProgrammingLastDayModal;
