import {Constants} from "../constants";

const initialState: any = {
  listCircuits: {
    pagination: [],
    data: [],
  },
  filters: {
    locationReferences: [],
    query: "",
    mode: null
  },
  calendars: [],
  programs: [],
  lastMeasures: [],
  selectedCircuit: null,
  selectedModule: null,
};

const ProgrammingReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
      case Constants.SET_LIST_CIRCUIT:
        return {
          ...state,
          listCircuits: { pagination: { ...action.payload.pagination }, data: { ...action.payload.data } },
        };
      case Constants.SET_CIRCUIT_LOCATION_REFERENCE_FILTER:
        return {
          ...state,
          filters: {
            ...state.filters,
            locationReferences: action.payload.ids,
            query: action.payload.query,
            mode: action.payload.mode || null
          },
        };
      case Constants.SET_CIRCUIT_CALENDAR:
        return {
          ...state,
          calendars: action.payload,
        };
      case Constants.SET_SELECTED_CIRCUIT:
        return {
          ...state,
          selectedCircuit: action.payload,
        };
      case Constants.SET_SELECTED_MODULE:
        return {
          ...state,
          selectedModule: action.payload,
        };
      case Constants.SET_CIRCUIT_PROGRAM:
        return {
          ...state,
          programs: action.payload,
        }
      case Constants.SET_LAST_MEASURES:
        return {
          ...state,
          lastMeasures: action.payload,
        }
      default:
        return state;
    }
};

export default ProgrammingReducer;
