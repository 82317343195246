import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {SzBox, SzButton, SzIcon, SzInput, SzModal, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import ProgramDetail from "../../components/Programming/ProgramDetail";
import EquipmentService from "../../services/EquipmentService";
import ProgrammingService from "../../services/ProgrammingService";
import {programmingAction} from "../../store/actions";

class ConfigHunterManager extends React.Component<any> {
    public state: any = {
        programs: [],
        selectedProgram: null,
        selectedOption: null,
        editName: false,
        isDataChanged: false,
        showConfirm:false,
        lastProgramChanged:''
    };

    public componentDidMount() {
        this.init();
    }


    public init() {

        EquipmentService.getEquipment(this.props.match.params.moduleId).then((response) => {
            if (response.data) {
                const module: any = response.data;
                this.props.setSelectedCircuit(module.circuit);
                this.props.setSelectedModule(module);

                ProgrammingService.getProgram([], [module]).then((response: { data: any }) => {
                    const programs = response.data;
                    this.setState({programs});
                });
            }

        });
    }

    public getOptions = () => {
        const options: any = [];

        const listProgram = this.state.programs.sort(function (programA: any, programB: any) {
            return programA.externalId- programB.externalId;
        });

        listProgram.forEach((program: any) => {
            options.push({value: program.id, label: `${program.externalId} - ${program.name}`});
        });
        return options;
    }

    public selectProgramHandle = (option: { value: string, label: string }) => {
        if (this.state.isDataChanged) {
            this.setState({
                showConfirm:true,
                lastProgramChanged: option.value
            });
        } else {
            this.changeProgram(option.value);
        }
    }

    public changeProgram = (programId: string) => {
        ProgrammingService.getProgramById(programId).then((response: any) => {
            this.setState({
                selectedProgram: response,
                selectedOption: {
                    value: response.id,
                    label: `${response.externalId} - ${response.name}`
                }
            });
        });
    }

    public updateProgramName = (event: any) => {
        const selectedProgram = this.state.selectedProgram;
        ProgrammingService.saveProgram(selectedProgram.id, {name: selectedProgram.name}).then(() => {
            this.setState({editName: false,
                selectedOption: {
                    value: selectedProgram.id,
                    label: `${selectedProgram.externalId} - ${selectedProgram.name}`
                }
            });
            this.init();
        });
        event.preventDefault();
    }

    public setDataChanged = (isDataChanged: boolean) => {

        this.setState({isDataChanged});
    }


    public render() {
        return (
            <>
                {(this.props.circuit && this.props.module) &&
                    <>
                        <SzModal title={""} show={this.state.editName} handleClose={() => {this.setState({editName: false}); }} size={"lg"}>
                            <form onSubmit={this.updateProgramName}>
                                <SzInput
                                    name="name"
                                    value={this.state.selectedProgram?.name}
                                    onChange={({ target }) => {
                                        this.setState({selectedProgram: {...this.state.selectedProgram, name: target.value }});
                                    }}
                                    type={"text"}
                                    required={true}
                                    label={this.props.t("programming:program.edit_name_label")}
                                />
                                <div className="text-center mt-5 mb-4">
                                    <SzButton variant="secondary" className="mr-3" onClick={() => {this.setState({editName: false}); }}>{this.props.t("programming:btn.close")}</SzButton>
                                    <SzButton className="mr-3 text-white" type={"submit"}>{this.props.t("programming:btn.validate")}</SzButton>
                                </div>

                            </form>
                        </SzModal>
                        {(this.state.isDataChanged && this.state.selectedProgram) &&
                            <SzModal title='' show={this.state.showConfirm} handleClose={() => {
                                this.setState({showConfirm: false})
                            }} size={"lg"}>
                                <p className="text-center">{this.props.t("programming:confirm_change_program", {programName: this.state.selectedProgram.name})}</p>
                                <div className="mt-1 m-auto">
                                    <div className="row justify-content-center">
                                        <SzButton variant="secondary" className="mr-3" onClick={() => {
                                            this.setState({showConfirm: false});
                                        }}>{this.props.t("programming:btn.no")}</SzButton>
                                        <SzButton
                                            onClick={() => {
                                                this.setState({showConfirm: false});
                                                this.changeProgram(this.state.lastProgramChanged);
                                            }}
                                            className="mr-3 text-white">{this.props.t("programming:btn.yes")}</SzButton>
                                    </div>
                                </div>
                            </SzModal>
                        }
                        <SzBox
                            className="row equipment-info-box m-0 p-0 mt-0 mt-xl-4 mb-4  cursor-pointer d-none d-xl-block"
                            tag="div">
                            <div className="col-1 mt-3">
                                <SzIcon variant="line" icon="gardening-hand-sprinkler"/>
                            </div>
                            <div className="col-4 mt-3">
                                <SzTypographie color="inactive" variant="caption">
                                    {this.props.t("programming:title_circuit", {circuit: this.props.circuit.name})}
                                </SzTypographie>
                                <SzTypographie className="m-0">{this.props.module.name}</SzTypographie>
                            </div>
                        </SzBox>
                        <div className="equipment-info-box">
                            <div className="row">
                                <div className="col-md-3 col-sm-12 mb-4">
                                    <SzSelect
                                        id={"id-1"}
                                        placeholder={"Sélectionner un programme"}
                                        options={this.getOptions()}
                                        value={this.state.selectedOption}
                                        isSearchable={false}
                                        isClearable={false}
                                        onChange={this.selectProgramHandle}
                                    />
                                </div>
                                <div className="col d-none d-sm-block">
                                    {this.state.selectedProgram &&
                                        <SzButton variant="tertiary" className="mr-3" icon="pencil-write" onClick={() => {this.setState({editName: true}); this.setState({newName: this.state.selectedProgram?.name});}}/>
                                    }
                                </div>
                            </div>
                            {this.state.selectedProgram &&
                            <ProgramDetail
                                resetHandle = {()=>{this.setState({selectedOption:null, selectedProgram:null})}}
                                program={this.state.selectedProgram}
                                setDataChanged={this.setDataChanged.bind(this)}
                                module={this.props.module}
                            />
                            }
                        </div>

                    </>
                }
            </>
        );
    }
}

const mapDispatchToProps = {
    setSelectedCircuit: programmingAction.setSelectedCircuit,
    setSelectedModule: programmingAction.setSelectedModule,
};
const mapStateToProps = (state: any) => {
    return {
        circuit: state.programming.selectedCircuit,
        module: state.programming.selectedModule,
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfigHunterManager)));
