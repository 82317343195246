import React, {useEffect} from "react";
import DashboardManager from "../../../admin/manager/DashboardManager";
import {routesPath} from "../../../routes";
import Breadcrumb from "../../components/Utils/Breadcrumb";
import {useHistory} from "react-router";
import {config} from "../../../config";
import ReactGA from "react-ga";

const Dashboard = () => {
    const history = useHistory();
    useEffect(() => {
        if (config.googleAnalyticsId && history.location) {
            ReactGA.initialize(config.googleAnalyticsId);
            ReactGA.set({page: history.location.pathname}); // Update the user's current page
            ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
        }
    }, [history.location.pathname]);
    return (
        <div className="m-2 w-100">
            <Breadcrumb elements={[{text: "admin", link: routesPath.home}]}/>
            <DashboardManager/>
        </div>
    );
};

export default Dashboard;
