import {Constants} from "../../store/constants";

const initialState: any = {
    filters: {
        query:''
    },
    locationReferences:{
        data:[],
        pagination:[]
    },
    plants:{
        data:[],
        pagination:[]
    },
    measures: [],
    plantMeasures: []
};

const hydriqueMonitoringReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case Constants.SET_HYDRIQUE_MONITORING_FILTER:
            return {
                ...state,
                filters: {...state.filters, query: action.payload.query},
            };

        case Constants.SET_HYDRIQUE_MONITORING_LOCATION_REFERENCE:
            return {
                ...state,
                locationReferences: {... action.payload},
            };
        case Constants.SET_HYDRIQUE_MONITORING_MEASURES:
            return {
                ...state,
                measures: {... action.payload},
            };
        case Constants.SET_HYDRIQUE_MONITORING_PLANT_MEASURES:
            return {
                ...state,
                plantMeasures: {... action.payload},
            };
        case Constants.SET_HYDRIQUE_MONITORING_PLANTS:
            return {
                ...state,
                plants: {... action.payload},
            };
      default:
        return state;
    }
};

export default hydriqueMonitoringReducer;
