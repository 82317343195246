import React from "react";
import { useTranslation } from "react-i18next";
import { SzBox, SzTypographie } from "@suezenv/react-theme-components";

const EmptyRow: any = (props: any) => {
  const { t } = useTranslation();
  return (
    <>
      <SzBox className="row alarm-info-box m-2 mt-4 w-100" tag="div">
        <div className="col-12 p-0 m-0 row justify-content-center">
          <SzTypographie color="inactive" className="m-0">
            {t("emptyList")}
          </SzTypographie>
        </div>
      </SzBox>
    </>
  );
};

export default EmptyRow;
