import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzIcon, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import ConsumptionChart from "./ConsumptionChart";
import "./style.scss";

const ConsumptionByLocationReference: any = (props: any) => {
    const {t} = useTranslation();
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState({value: `${currentYear - 1}`, label: `${t("consumption:selected_reference_year_label")} : ${currentYear - 1}`});
    const selectChangeHandle = (option: any) => {
        setYear(option);
    };
    useEffect(() => {
        props.setMeasureByMonthAndLocationReference([currentYear, year.value], props.locationReference.id);
    }, [year]);
    const getOptions = () => {
        return [{value: `${currentYear - 1}`, label: `${t("consumption:selected_reference_year_label")} : ${currentYear - 1}`},
            {value: `${currentYear - 2}`, label: `${t("consumption:selected_reference_year_label")} : ${currentYear - 2}`},
            {value: `${currentYear - 3}`, label: `${t("consumption:selected_reference_year_label")} : ${currentYear - 3}`}];
    };

    const getDataSum = (selectedYear: string) => {
        const data: any = [];
        const measureLocationReference = props.measureByMonAndLocationReference[props.locationReference.id] || [];
        if (measureLocationReference[selectedYear]) {
            Object.values(measureLocationReference[selectedYear]).forEach((value: any) => {
                data.push(value ? parseFloat((value.sum/1000).toFixed(1)) : 0);
            });
        }
        return data;
    };

    const getDataCumul = (selectedYear: string) => {
        const data: any = [];
        const measureLocationReference = props.measureByMonAndLocationReference[props.locationReference.id] || [];
        if (measureLocationReference[selectedYear]) {
            Object.values(measureLocationReference[selectedYear]).forEach((value: any) => {
                data.push(value ?parseFloat((value.cumul/1000).toFixed(1)) : 0);
            });
        }
        return data;
    };
    return (<>
        <div className="history-modal-title">
            <SzTypographie className="ml-5" color="inactive">
                {t("consumption:history_title")}
            </SzTypographie>
            <SzIcon variant="line" icon="analytics-bars-3d" className="float-left ml-2 mr-3"/>
            <SzTypographie>
                {`${t("consumption:location_reference_title")} : ${props.locationReference.sigId || ""} - ${props.locationReference.name}`}
            </SzTypographie>
        </div>
        <div className="row mt-5">
            <div className="col-4">
                <SzTypographie className="required"  color="inactive">
                    {t("consumption:reference_year_label")} :
                </SzTypographie>
                <SzSelect
                    className="mb-1"
                    onChange={selectChangeHandle}
                    name={"years"}
                    placeholder=""
                    options={getOptions()}
                    value={year}
                />
            </div>

        </div>
        <div className="row  mt-5 p-0 m-0">
            <div className="col-6">
                <SzTypographie  weight="bold">
                    {t("consumption:chart_title_month_sum")}
                </SzTypographie>
                <div className="row p-0 m-0">
                    <div className="col-4 offset-8 ">
                        <div className="float-left mr-5">{currentYear} <span className="legend-year m-1"></span></div>
                        <div>{year.value} <span className="legend-year compare m-1"></span></div>
                    </div>
                </div>
                <ConsumptionChart getDataYear={getDataSum} year={year}/>
            </div>
            <div className="col-6">
                <SzTypographie weight="bold">
                    {t("consumption:chart_title_month_cumul")}
                </SzTypographie>                <div className="row p-0 m-0">
                    <div className="col-4 offset-8">
                        <div className="float-left mr-5">{currentYear} <span className="legend-year m-1"></span></div>
                        <div>{year.value} <span className="legend-year compare m-1"></span></div>
                    </div>
                </div>
                <ConsumptionChart getDataYear={getDataCumul} year={year}/>
            </div>
        </div>
    </>);
};

export default ConsumptionByLocationReference;
