import {Constants} from "../constants";

export default {
    setLocationReferences(payload: any) {
        return {
            payload,
            type: Constants.SET_WATERING_MAP_LOCATION_REFERENCE,
        };
    },
    filterMapLocationReference(payload: any) {
        return {
            payload,
            type: Constants.SET_FILTER_MAP_LOCATION_REFERENCE,
        };
    },
    selectMapLocationReference(payload: any) {
        return {
            payload,
            type: Constants.SET_SELECT_MAP_LOCATION_REFERENCE,
        };
    },
    setMapAlarms(payload: any) {
        return {
            payload,
            type: Constants.SET_MAP_ALARMS,
        };
    },
};
