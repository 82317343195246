import React, {useEffect} from "react";
import {routesPath} from "../../../routes";
import AlarmManager from "../../../watering/manager/AlarmManager";
import Breadcrumb from "../../components/Utils/Breadcrumb";
import "./index.scss";
import WateringTabNavigation from "../../components/Utils/WateringTabNavigation";
import {useHistory} from "react-router";
import {config} from "../../../config";
import ReactGA from "react-ga";

const Alarm = () => {
    const history = useHistory();
    useEffect(() => {
        if (config.googleAnalyticsId && history.location) {
            ReactGA.initialize(config.googleAnalyticsId);
            ReactGA.set({ page: history.location.pathname }); // Update the user's current page
            ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
        }
    },[history.location.pathname]);

    const selectTabHandle = (tab: any) => {
        history.push(tab)
    }
    return (
        <div className="m-2 w-100 overflow-auto">
           <div className="row p-0 m-0">
                <div className="col-5 m-0 p-0">
                    <Breadcrumb elements={[{text: "alarms"}]}/>
                </div>
            </div>
            <AlarmManager/>
        </div>
    );
};

export default Alarm;
