import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzIcon, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import ConsumptionChart from "./ConsumptionChart";
import "./style.scss";

const ConsumptionHistory: any = (props: any) => {
    const {t} = useTranslation();
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState({value: `${currentYear - 1}`, label: `${currentYear - 1}`});
    const selectChangeHandle = (option: any) => {
        setYear(option);
    };
    useEffect(() => {
        props.setMeasureByMonth([currentYear, year.value]);
    }, [year]);
    const getOptions = () => {
        return [{value: `${currentYear - 1}`, label: `${currentYear - 1}`},
            {value: `${currentYear - 2}`, label: `${currentYear - 2}`},
            {value: `${currentYear - 3}`, label: `${currentYear - 3}`}];
    };

    const getDataYear = (selectedYear: string) => {
        const data: any = [];
        if (props.measure.measureByMonth[selectedYear]) {
            Object.values(props.measure.measureByMonth[selectedYear]).forEach((value: any) => {
                data.push(value ? parseFloat((value.cumul/1000).toFixed(1)) : 0);
            });
        }

        return data;
    };

    return (<>
        <div className="history-modal-title">
            <SzTypographie className="ml-5" color="inactive" variant="caption">
                {t("consumption:history_title")}
            </SzTypographie>
            <SzIcon variant="line" icon="analytics-bars-3d" className="float-left ml-2 mr-3"/>
            <SzTypographie>
                {t("consumption:history_title_2")}
            </SzTypographie>
        </div>
        <div className="row mt-4">
            <div className="col-2">
                <SzTypographie className="required">
                    {t("consumption:year_label")}
                </SzTypographie>
                <SzSelect
                    className="mb-1"
                    onChange={selectChangeHandle}
                    name={"years"}
                    placeholder=""
                    options={getOptions()}
                    value={year}
                />
            </div>
            <div className="col-2 offset-8 mt-5">
                <div className="float-left mr-5">{currentYear} <span className="legend-year m-1"></span></div>
                <div>{year.value} <span className="legend-year compare m-1"></span></div>
            </div>
        </div>
        <ConsumptionChart getDataYear={getDataYear} year={year}/>
    </>);
};

export default ConsumptionHistory;
