import instanceRequest, {Request} from "../../main/services/Request";
import {
    PAGINATION_HEADERS,
    STATUS_INDEX_DEFECT,
    STATUS_INDEX_NORMAL,
    STATUS_INDEX_UNAVAILABLE,
    STATUS_LIST,
} from "../../main/store/constants";

class HydriqueService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public generateUrbasenseLink(circuitId: string, plantId : string) {
        const url = `/api/urbasense/link/circuit/${circuitId}/plant/${plantId}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return {
                data: response.data,
            };
        });
    }
}

export default new HydriqueService(instanceRequest);
