import instanceRequest, {Request} from "../../main/services/Request";
import {
    PAGINATION_HEADERS,
} from "../../main/store/constants";

class MeasureService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getMeasures(
        page: number = 1,
        locationReferences: any = [],
        startAt: string | null,
        itemsPerPage: number | null = 20,
        allItems: number = 0,
        plantId:string|null =null
    ) {
        let itemsPerPageQuery = "";
        let allItemsQuery = "";
        if (allItems) {
            allItemsQuery = `&allItems=${allItems}`;
        } else {
            itemsPerPageQuery = `&itemsPerPage=${itemsPerPage}`;
        }

        let filtersLocationReferenceQuery = "";
        if (locationReferences.length > 0) {
            filtersLocationReferenceQuery = this.formatLocationReferenceFilters(locationReferences);
        }

        let plantIdQuery = "";
        if (plantId) {
            plantIdQuery = `&plantId=${plantId}`;
        }

        let startAtQuery = "";

        if(startAt)
        {
            startAtQuery = `&startAt=${startAt}`;
        }

        // all filters are deactivated
        const url = `/api/measure/hydrique?currentPage=${page}${itemsPerPageQuery}${allItemsQuery}${filtersLocationReferenceQuery}${startAtQuery}${plantIdQuery}`;
        return this.axiosService.get(url, false).then((response) => {
            if (allItems) {
                return response.data;
            }
            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data,
            };
        });
    }

    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }


    private formatLocationReferenceFilters(locationReferences = []) {
        let filterQuery = "";
        locationReferences.forEach((locationReference: any) => {
            filterQuery = filterQuery ? `${filterQuery}&locationReference[]=${locationReference.id}` : `&locationReference[]=${locationReference.id}`;
        });

        return filterQuery;
    }
}

export default new MeasureService(instanceRequest);
