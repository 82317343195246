import L from "leaflet";
import React, { createRef, useEffect, useState } from "react";
import { FeatureGroup, LayersControl, Map as LeafletMap, Marker, Polygon, Popup, TileLayer } from "react-leaflet";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { config } from "../../config";
import { routes, routesPath } from "../../routes";
import {
  ACTUATOR_MODULE,
  DEBIT_METER_INPUTS_TYPE,
  DEVICE_MODEL_TYPE_LR_MS_SENSOR,
  METER_MODULE,
  NETWORK_MODULE,
  SENSOR_MODULE,
} from "../../watering/store/constants";
import { DEFAULT_PIN_MAT_COLOR, defaultCenter, defaultZoom } from "../store/constants";
import "../style/map.scss";
import { getCentroidMultipolygon, getCoordinateCenter } from "../../main/utils";
import programmingIcon from "../../assets/img/programming.svg";
import { getHumidityValueColor, getRiskColor, getWateringColor } from "../../hydrique/components/utils/utile";
import { HUNTER_SOURCE, HYDRIQUE_FUNCTIONAL_TYPE } from "../../main/store/constants";
import { toArray } from "geojson-tools";

const { BaseLayer } = LayersControl;

interface IState {
  lat: string;
  lng: string;
  zoom: number;
}

interface IMapComponent {
  zoom: number;
  center: { lat: string; lng: string };
  className: string;
  locationReferences: any;
  selectedLocationReference: any;
  selectLocationReferenceHandle: any;
  filter: any;
  clickAlarmHandle: any;
  clickCircuitHandle: any;
  clickEquipmentHandle: any;
  clickHydriqueEquipmentHandle: any;
  clickHydriqueMonitoringHandle: any;
  alarms: any;
  functionalType: string;
  hydriquePlants: any;
  hydriqueMeasures: any;
  plantTab: string;
  autoZoom: boolean;
}

const Map = (props: IMapComponent) => {
  const initState: IState = {
    ...defaultCenter,
    zoom: defaultZoom,
  };
  const mapRef: any = createRef();
  const groupRef: any = createRef();
  const [state, setState] = useState({ ...initState });
  const history = useHistory();

  useEffect(() => {
    if (props.locationReferences == null) {
      setState({ ...state, zoom: props.zoom, lat: props.center.lat, lng: props.center.lng });
    } else {
      fitBounds();
    }
  }, [props.locationReferences, props.zoom, props.center]);

  const drawGeoJson = () => {
    if (props.locationReferences === null) {
      return <></>;
    }
    const { filter, clickAlarmHandle, alarms } = props;
    return props.locationReferences.map((locationReference: any, index: any) => {
      const { geojson } = locationReference;
      const locationReferenceCenter = geojson
        ? geojson.type == "Polygon"
          ? getCoordinateCenter(geojson.coordinates[0])
          : getCentroidMultipolygon(geojson.coordinates[0])
        : null;
      let alarmsLocationReference: any[] = [];
      if (filter.isAlarmActivated) {
        Object.values(alarms).forEach((alarm: any) => {
          if (alarm.locationReferenceId === locationReference.id) {
            alarmsLocationReference.push(alarm);
          }
        });
      }
      if (geojson) {
        return (
          <>
            <Polygon
              key={index}
              color={alarmsLocationReference.length > 0 ? "#eb4a65" : "blue"}
              onClick={() => props.selectLocationReferenceHandle(locationReference)}
              positions={toArray(geojson)}
              onMouseOver={(e: any) => {
                e.target.openPopup();
              }}
              onMouseOut={(e: any) => {
                e.target.closePopup();
              }}
            >
              <Popup>{`${locationReference.name} / ${locationReference.sigId}`}</Popup>
            </Polygon>
            {alarmsLocationReference.length > 0 && locationReferenceCenter && (
              <Marker
                icon={getAlarmIcon()}
                position={[locationReferenceCenter[1], locationReferenceCenter[0]]}
                zIndexOffset={1}
                onClick={() => {
                    clickAlarmHandle({ids: [locationReference], query: locationReference?.sigId || ''});
                  history.push(routesPath.alarm);
                }}
              ></Marker>
            )}
          </>
        );
      }
    });
  };

  const fitBounds = () => {
    if (props.autoZoom) {
      const timer = setInterval(() => {
        if (mapRef.current && groupRef.current) {
          const map = mapRef.current.leafletElement; // get native Map instance
          const group = groupRef.current.leafletElement; // get native featureGroup instance
          try {
            map.fitBounds(group.getBounds());
            clearInterval(timer);
          } catch (e) {}
        }
      }, 100);
    }
  };
  const drawSelectedLocationReference = () => {
    const {
      selectedLocationReference,
      filter,
      clickAlarmHandle,
      clickCircuitHandle,
      clickEquipmentHandle,
      alarms,
      clickHydriqueEquipmentHandle,
      clickHydriqueMonitoringHandle,
    } = props;
    if (selectedLocationReference) {
      const { geojson, circuits, equipments, hydriqueEquipments } = selectedLocationReference;
      return geojson ? (
        <>
          <Polygon
            fillColor="blue"
            color={filter.isAlarmActivated && alarms && Object.values(alarms).length > 0 ? "#eb4a65" : "blue"}
            positions={toArray(geojson)}
            onMouseOver={(e: any) => {
              e.target.openPopup();
            }}
            onMouseOut={(e: any) => {
              e.target.closePopup();
            }}
          >
            <Popup>{`${selectedLocationReference.name} / ${selectedLocationReference.sigId}`}</Popup>
          </Polygon>
          {filter.isAlarmActivated && alarms && Object.values(alarms).length > 0 && (
            <Marker
              icon={getAlarmIcon()}
              position={[state.lat, state.lng]}
              zIndexOffset={1}
              onClick={() => {
                clickAlarmHandle([selectedLocationReference]);
                history.push(routesPath.alarm);
              }}
            ></Marker>
          )}
          {filter.isWateringActivated &&
            circuits &&
            !filter.showEquipments &&
            Object.values(circuits).map((circuit: any, index: any) => {
              const nbrNotNullState = circuit.modules.filter(function (item: any) {
                return item.state !== null;
              }).length;
              const nbrNotOffState = circuit.modules.filter(function (item: any) {
                return item.state === "off";
              }).length;
              let circuitState = "on";
              if (nbrNotNullState === nbrNotOffState && nbrNotOffState > 0) {
                circuitState = "off";
              } else if (nbrNotOffState > 0) {
                circuitState = "partial";
              }
              if (
                (true === filter.circuitOn && circuitState === "on") ||
                (true === filter.circuitOff && circuitState === "off") ||
                (true === filter.circuitPartial && circuitState === "partial")
              ) {
                return circuit.latitude && circuit.longitude ? (
                  <Marker
                    icon={getCircuitIcon(circuit, circuitState)}
                    key={index}
                    position={[circuit.latitude, circuit.longitude]}
                    onClick={() => {
                      clickCircuitHandle([selectedLocationReference]);
                      history.push(routesPath.programming.index);
                    }}
                    onMouseOver={(e: any) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e: any) => {
                      e.target.closePopup();
                    }}
                  >
                    <Popup>{`${circuit.name} / ${circuit.sigId}`}</Popup>
                  </Marker>
                ) : (
                  <></>
                );
              }
            })}
          {filter.isWateringActivated &&
            equipments &&
            filter.showEquipments &&
            Object.values(equipments).map((equipment: any, index: any) => {
              const showIcon = filterEquipmentType(equipment);
              if (equipment.latitude && equipment.longitude && showIcon) {
                return (
                  <Marker
                    icon={getEquipmentIcon(equipment)}
                    key={index}
                    position={[equipment.latitude, equipment.longitude]}
                    onClick={() => {
                      if (equipment.source === HUNTER_SOURCE) {
                        if (equipment.relay) {
                          history.push(routes(equipment.id).programmingHunter.config);
                        } else {
                          const { locationReference } = equipment;
                          if (locationReference) {
                            clickEquipmentHandle({
                              ids: [locationReference],
                              query: locationReference.sigId,
                            });
                          }
                          history.push(routesPath.watering);
                        }
                      } else {
                        history.push(routes(equipment.id).programming.config);
                      }
                    }}
                    onMouseOver={(e: any) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e: any) => {
                      e.target.closePopup();
                    }}
                  >
                    <Popup>{`${equipment.name}`}</Popup>
                  </Marker>
                );
              }
            })}
          {filter.isWateringActivated &&
            equipments &&
            !filter.showEquipments &&
            filter.showCircuitRelay &&
            Object.values(equipments).map((equipment: any, index: any) => {
              const isRelay = !equipment.relay;
              if (equipment && equipment.latitude && equipment.longitude && isRelay) {
                return (
                  <Marker
                    icon={getCircuitRelayIcon(equipment)}
                    key={`circuit-relay-${index}`}
                    position={[equipment.latitude, equipment.longitude]}
                    onClick={() => {
                      clickEquipmentHandle({
                        ids: [selectedLocationReference],
                        query: selectedLocationReference.sigId,
                      });
                      history.push(routesPath.watering);
                    }}
                    onMouseOver={(e: any) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e: any) => {
                      e.target.closePopup();
                    }}
                  >
                    <Popup>{`${equipment.name}`}</Popup>
                  </Marker>
                );
              }
            })}

          {filter.isHydriqueActivated &&
            hydriqueEquipments &&
            Object.values(hydriqueEquipments).map((hydriqueEquipment: any, index: any) => {
              const showIcon = filterHydriqueEquipmentType(hydriqueEquipment);
              if (hydriqueEquipment.latitude && hydriqueEquipment.longitude && showIcon) {
                return (
                  <Marker
                    icon={getHydriqueEquipmentIcon(hydriqueEquipment)}
                    key={index}
                    position={[hydriqueEquipment.latitude, hydriqueEquipment.longitude]}
                    onClick={() => {
                      clickHydriqueEquipmentHandle({
                        ids: [selectedLocationReference],
                        query: selectedLocationReference.sigId,
                      });
                      history.push(routesPath.hydriqueEquipment);
                    }}
                    onMouseOver={(e: any) => {
                      e.target.openPopup();
                    }}
                    onMouseOut={(e: any) => {
                      e.target.closePopup();
                    }}
                  >
                    <Popup>{`${hydriqueEquipment.name}`}</Popup>
                  </Marker>
                );
              }
            })}
        </>
      ) : (
        <></>
      );
    }
  };

  const drawPlants = () => {
    const { filter, clickHydriqueMonitoringHandle, hydriquePlants } = props;
    return (
      filter.isHydriquePlantActivated &&
      hydriquePlants &&
      Object.values(hydriquePlants).map((hydriquePlant: any, index: any) => {
        if (hydriquePlant.latitude && hydriquePlant.longitude) {
          return (
            <Marker
              icon={getHydriquePlantIcon(hydriquePlant)}
              key={index}
              position={[hydriquePlant.latitude, hydriquePlant.longitude]}
              onClick={() => {
                if (hydriquePlant.locationReference) {
                  clickHydriqueMonitoringHandle({
                    query: hydriquePlant.externalId,
                  });
                }
                history.push(routesPath.hydriqueCircuit);
              }}
              onMouseOver={(e: any) => {
                e.target.openPopup();
              }}
              onMouseOut={(e: any) => {
                e.target.closePopup();
              }}
            >
              <Popup>{hydriquePlant.varietyName || hydriquePlant.name}</Popup>
            </Marker>
          );
        }
      })
    );
  };

  const getCircuitIcon = (circuit: any, circuitState: string = "on") => {
    let color = "#30d158";

    if (circuitState === "off") {
      color = "#ADB5BD";
    } else if (circuitState === "partial") {
      color = "#d97817";
    }
    const markerHtmlStyles = `
      background-color: ${color};
      width: 2.6rem;
      height: 2.6rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      vertical-align: middle;
      position: relative;
      border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;}
   `;
    const icon = L.divIcon({
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" ><span style="position:absolute; top:2px;left:8px">
            <img src=${programmingIcon}  width="24" height="39" />
            </span></span>`,
    });

    return icon;
  };
  const filterEquipmentType = (equipement: any) => {
    const status = equipement.status;
    if (status === "NORMAL" && !props.filter.showNormalEquipment) {
      return false;
    }
    if (status === "DEFECT" && !props.filter.showDefectEquipment) {
      return false;
    }
    if (status === "UNAVAILABLE" && !props.filter.showUnavailableEquipment) {
      return false;
    }
    const isRelay = !equipement.relay;
    if (isRelay && !props.filter.showRelay) {
      return false;
    }

    const deviceModel = equipement.deviceModel;
    const category = deviceModel ? deviceModel.category : null;
    const categoryCode = category ? category.code : null;

    const isActuator = categoryCode === ACTUATOR_MODULE;
    if (isActuator && !props.filter.showActuator) {
      return false;
    }
    const isSensor = categoryCode === SENSOR_MODULE && !hasMeterInput(equipement);
    if (isSensor && !props.filter.showSensor) {
      return false;
    }
    const isMeter = categoryCode === METER_MODULE || (hasMeterInput(equipement) && !isActuator);
    if (isMeter && !props.filter.showMeter) {
      return false;
    }

    return true;
  };

  const filterHydriqueEquipmentType = (equipement: any) => {
    const status = equipement.status;
    if (status === "NORMAL" && !props.filter.showNormalHydriqueEquipment) {
      return false;
    }
    if (status === "DEFECT" && !props.filter.showDefectHydriqueEquipment) {
      return false;
    }
    return true;
  };

  const hasMeterInput = (module: any) => {
    const deviceModelType = module.deviceModel.type;
    if (DEVICE_MODEL_TYPE_LR_MS_SENSOR.includes(deviceModelType)) {
      let hasMeter = false;
      if (module.components && module.components.length > 0) {
        module.components.forEach((component: any) => {
          if (DEBIT_METER_INPUTS_TYPE.includes(component.deviceModel.type)) {
            hasMeter = true;
            return true;
          }
        });
      }
      return hasMeter;
    }
    return true;
  };

  const getEquipmentIcon = (equipement: any) => {
    let iconEquipment = "wifi-signal-2";
    const colors: any = { NORMAL: "#30d158", DEFECT: "#eb4a65", UNAVAILABLE: "#adb5bd" };
    const deviceModel = equipement.deviceModel;
    const category = deviceModel ? deviceModel.category : null;
    const categoryCode = category ? category.code : null;
    const isRelay = !equipement.relay;
    if (isRelay && !props.filter.showRelay) {
      return <></>;
    }
    if (!isRelay) {
      const isActuator = categoryCode === ACTUATOR_MODULE;
      if (isActuator) {
        iconEquipment = "time-clock-hand-1";
      } else if (hasMeterInput(equipement)) {
        iconEquipment = "time-clock-circle-1";
      } else {
        const icons: any = [];
        icons[ACTUATOR_MODULE] = "time-clock-hand-1";
        icons[METER_MODULE] = "time-clock-circle-1";
        icons[SENSOR_MODULE] = "filter-1";
        icons[NETWORK_MODULE] = "analytics-bars-3d";

        iconEquipment = icons[categoryCode];
      }
    }
    const markerHtmlStyles = `
      background-color: ${colors[equipement.status]};
      width: 2.6rem;
      height: 2.6rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      vertical-align: middle;
      position: relative;
      border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;}
   `;

    const icon = L.divIcon({
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" ><span style="position:absolute; top:2px;left:8px">
            <i class="sz-icon-line cursor-pointer ${iconEquipment}"/>
            </span></span>`,
    });

    return icon;
  };

  const getHydriqueEquipmentIcon = (equipement: any) => {
    let iconEquipment = "filter-1";
    const colors: any = { NORMAL: "#30d158", DEFECT: "#eb4a65", UNAVAILABLE: "#adb5bd" };
    const markerHtmlStyles = `
      background-color: ${colors[equipement.status]};
      width: 2.6rem;
      height: 2.6rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      vertical-align: middle;
      position: relative;
      border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;}
   `;

    const icon = L.divIcon({
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" ><span style="position:absolute; top:2px;left:8px">
            <i class="sz-icon-line cursor-pointer ${iconEquipment}"/>
            </span></span>`,
    });

    return icon;
  };

  const getHydriquePlantIcon = (hydriquePlants: any) => {
    const plantHumidity = getPlantHumidity(hydriquePlants.id);
    let bgColor = DEFAULT_PIN_MAT_COLOR;
    if (plantHumidity && props.plantTab === "tab-plant-humidity") {
      bgColor = getHumidityValueColor(plantHumidity.value * 100);
    } else if (props.plantTab === "tab-plant-watering") {
      bgColor = getWateringColor(hydriquePlants.quantity);
    } else if (props.plantTab === "tab-plant-risk") {
      bgColor = getRiskColor(hydriquePlants.risk) || DEFAULT_PIN_MAT_COLOR;
    }

    const markerHtmlStyles = `
      background-color: ${bgColor};
      width: 2.6rem;
      height: 2.6rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      vertical-align: middle;
      position: relative;
      border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;}
   `;

    const icon = L.divIcon({
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" ><span style="position:absolute; top:2px;left:4px">
              <i class="sz-icon-line cursor-pointer plant-icon"/>
            </span></span>`,
    });

    return icon;
  };

  const getCircuitRelayIcon = (relay: any) => {
    const colors: any = { NORMAL: "#30d158", DEFECT: "#eb4a65", UNAVAILABLE: "#adb5bd" };
    const markerHtmlStyles = `
      background-color: ${colors[relay.status]};
      width: 2.6rem;
      height: 2.6rem;
      display: block;
      left: -1.5rem;
      top: -1.5rem;
      vertical-align: middle;
      position: relative;
      border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;}
   `;

    const icon = L.divIcon({
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" ><span style="position:absolute; top:2px;left:8px">
            <i class="sz-icon-line cursor-pointer wifi-signal-2"/>
            </span></span>`,
    });

    return icon;
  };

  const getAlarmIcon = () => {
    const markerHtmlStyles = `
      background-color: #eb4a65;
      width: 2.1rem;
      height: 2.1rem;
      display: block;
      left: -0.3rem;
      top: -0.3rem;
      vertical-align: middle;
      position: relative;
      border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;}
   `;

    const icon = L.divIcon({
      iconAnchor: [0, 24],
      labelAnchor: [-6, 0],
      popupAnchor: [0, -36],
      html: `<span style="${markerHtmlStyles}" ><span style="position:absolute; top:0px;left:5px">
            <i class="sz-icon-line cursor-pointer alarm-bell"/>
            </span></span>`,
    });

    return icon;
  };

  const getPlantHumidity = (plantId: string) => {
    const { hydriqueMeasures } = props;
    let plantMeasure: any = null;
    if (hydriqueMeasures) {
      Object.values(hydriqueMeasures).forEach((measure: any) => {
        if (plantId === measure.plantId) {
          plantMeasure = measure;
        }
      });
    }

    return plantMeasure;
  };

  return (
    <>
      <LeafletMap
        className={props.className}
        center={[state.lat, state.lng]}
        zoom={state.zoom}
        // maxZoom={10}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        ref={mapRef}
        onZoomEnd={fitBounds()}
      >
        <LayersControl position="topright">
          <BaseLayer checked name="Plan">
            <TileLayer url={config.mapPlanUrl} />
          </BaseLayer>
          <BaseLayer name="Satellite">
            <TileLayer url={config.mapSatelliteUrl} />
          </BaseLayer>
        </LayersControl>
        <FeatureGroup ref={groupRef}>
          {drawGeoJson()}
          {(props.functionalType === HYDRIQUE_FUNCTIONAL_TYPE || !props.functionalType) && drawPlants()}
          {drawSelectedLocationReference()}
        </FeatureGroup>
      </LeafletMap>
    </>
  );
};

export default connect()(Map);
