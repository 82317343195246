import {
    ALERT_RISK_COLOR,
    CRISIS_RISK_COLOR,
    DEFAULT_PIN_MAT_COLOR,
    DO_NOT_WATERING_COLOR,
    DO_WATERING_COLOR,
    FIRST_HUMIDITY_COLOR,
    OK_RISK_COLOR,
    SATURATED_RISK_COLOR, SECOND_HUMIDITY_COLOR, THIRD_HUMIDITY_COLOR,
    VIGILANCE_RISK_COLOR
} from "../../../map/store/constants";

const RELAY_ATTRIBUTE_CODE = 'connectivite'

export const relay = (equipment: any) => {
    let relayName = '';
    if (!equipment.relay && equipment.deviceModel && equipment.deviceModel.deviceAttributeValues) {

        Object.values(equipment.deviceModel.deviceAttributeValues).forEach((deviceAttributeValue: any) => {
            if (deviceAttributeValue.deviceTypeAttribute.code === RELAY_ATTRIBUTE_CODE) {
                relayName = deviceAttributeValue.value;
            }
        })


    } else if (equipment.relay) {
        relayName = equipment.relay.name;
    }
    return relayName;
}

export const getHumidityValueClass = (value: number) => {

    if (value < 10) {
        return 'humidity-red';
    }
    if (value >= 10 && value <= 30) {
        return 'humidity-yellow';
    }
    if (value > 50 && value <= 80) {
        return 'humidity-green';
    }

    return 'humidity-blue';
}


export const getHumidityValueColor = (value: number) => {

    if (value < 10) {
        return DEFAULT_PIN_MAT_COLOR;
    }
    if (value >= 10 && value <= 30) {
        return FIRST_HUMIDITY_COLOR;
    }
    if (value > 30 && value <= 80) {
        return SECOND_HUMIDITY_COLOR;
    }

    return THIRD_HUMIDITY_COLOR;
}


export const getWateringColor = (value: number) => {
    if (value === null) {
        return DEFAULT_PIN_MAT_COLOR
    } else {
        return (value > 0) ? DO_WATERING_COLOR : DO_NOT_WATERING_COLOR;
    }
}

export const getRiskColor = (value: string) => {

    const riskColors: any = {
        'ok': OK_RISK_COLOR,
        'saturé': SATURATED_RISK_COLOR,
        'vigilance': VIGILANCE_RISK_COLOR,
        'alert': ALERT_RISK_COLOR,
        'crise': CRISIS_RISK_COLOR,
    };

    return value ? riskColors[value.toLowerCase()] : null;
}