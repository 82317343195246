import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzBox,  SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import Frame from "../../../main/utils/Frame";
import ProgrammingService from "../../services/ProgrammingService";
import {measureAction, programmingAction} from "../../store/actions";
import {withRouter} from "react-router";
import EquipmentService from "../../services/EquipmentService";

class ConfigManager extends React.Component<any> {
  public state = {
      path: "",
  };

  public componentDidMount() {
    this.init();
  }

    public init() {

        EquipmentService.getEquipment(this.props.match.params.moduleId).then((response) => {
            if (response.data) {
                const module: any = response.data;
                this.props.setSelectedCircuit(module.circuit);
                this.props.setSelectedModule(module);
                this.generateLink(module.externalId);
                ProgrammingService.pushEventVisited(module.id);

            }

        });
    }
  public generateLink(moduleId: any) {
     ProgrammingService.generateConfigLink(moduleId).then((response: any) => {
      const path = response.data;
      this.setState({ path });
    });
  }

    public render() {
        return (
            <>
                {(this.props.circuit && this.props.module) &&
                <>
                    <SzBox className="row equipment-info-box m-0 p-0 mt-0 mt-xl-4 mb-4  cursor-pointer d-none d-xl-block" tag="div">
                        <div className="col-1 mt-3">
                            <SzIcon variant="line" icon="gardening-hand-sprinkler"/>
                        </div>
                        <div className="col-4 mt-3">
                            <SzTypographie color="inactive" variant="caption">
                                {this.props.t("programming:title_circuit", {circuit: this.props.circuit.name})}
                            </SzTypographie>
                            <SzTypographie className="m-0">{this.props.module.name}</SzTypographie>
                        </div>
                    </SzBox>
                    < Frame url={this.state.path} sandbox={true}/>
                </>
                }
            </>
        );
    }
}

const mapDispatchToProps = {
    setSelectedCircuit: programmingAction.setSelectedCircuit,
    setSelectedModule: programmingAction.setSelectedModule,


};
const mapStateToProps = (state: any) => {
    return {
      circuit: state.programming.selectedCircuit,
      module: state.programming.selectedModule,
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ConfigManager)));
