// action strings
export enum Constants {
  SET_LIST_EQUIPMENT = "SET_LIST_EQUIPMENT",
  SET_LIST_CIRCUIT = "SET_LIST_CIRCUIT",
  SET_SELECTED_CIRCUIT = "SET_SELECTED_CIRCUIT",
  SET_CIRCUIT_CALENDAR = "SET_CIRCUIT_CALENDAR",
  SET_SELECTED_MODULE = "SET_SELECTED_MODULE",
  SET_CIRCUIT_LOCATION_REFERENCE_FILTER = "SET_CIRCUIT_LOCATION_REFERENCE_FILTER",
  SET_ALARM_LOCATION_REFERENCE_FILTER = "SET_ALARM_LOCATION_REFERENCE_FILTER",
  FILER_STATUS = "FILER_STATUS",
  SET_EQUIPMENT_STATS = "SET_EQUIPMENT_STATS",
  SET_EQUIPMENT = "SET_EQUIPMENT",
  SET_LIST_LOCATION_REFERENCE = "SET_LIST_LOCATION_REFERENCE",
  SET_LOCATION_REFERENCE_FILTER = "SET_LOCATION_REFERENCE_FILTER",
  SET_MEASURE_BY_YEAR = "SET_MEASURE_BY_YEAR",
  SET_MEASURE_BY_MONTH = "SET_MEASURE_BY_MONTH",
  SET_MEASURE_BY_MONTH_AND_LOCATION_REFERENCE = "SET_MEASURE_BY_MONTH_AND_LOCATION_REFERENCE",
  SET_MEASURE_BY_LOCATION_REFERENCE = "SET_MEASURE_BY_LOCATION_REFERENCE",
  SET_MEASURE_LOCATION_REFERENCE_FILTER = "SET_MEASURE_LOCATION_REFERENCE_FILTER",
  SET_MEASURE_DETAIL_DAY_BY_STEP = "SET_MEASURE_DETAIL_DAY_BY_STEP",
  SET_EQUIPMENT_EVENT_LIST = "SET_EQUIPMENT_EVENT_LIST",
  SET_LATEST_EVENT_EQUIPMENT = "SET_LATEST_EVENT_EQUIPMENT",
  SET_RAISED_ALARM_LIST = "SET_RAISED_ALARM_LIST",
  SET_RAISED_ALARM_COUNT = "SET_RAISED_ALARM_COUNT",
  SET_ACQUITTED_LIST = "SET_ACQUITTED_ALARM_LIST",
  SET_ACQUITTED_COUNT = "SET_ACQUITTED_ALARM_COUNT",
  SET_WATERING_MAP_LOCATION_REFERENCE= "SET_WATERING_MAP_LOCATION_REFERENCE",
  SET_CIRCUIT_PROGRAM="SET_CIRCUIT_PROGRAM",
  SET_LAST_MEASURES="SET_LAST_MEASURES",
}

export const AUTO_COMPLETE_NBR_CHAR = 3;
export const  ACTUATOR_MODULE = "actuator";
export const  METER_MODULE = "meter";
export const  SENSOR_MODULE = "sensor";
export const  NETWORK_MODULE = "network";
export const REASON_SOLVED = "SOLVED";
export const REASON_DUPLICATE = "DUPLICATE";
export const REASON_FALSE_ALERT = "FALSE_ALERT";
export const MAX_CHAR_ACK_ALARM= "100";
export const MONTH_LIST = ["Jan", "Feb",  "Mar",  "Apr",  "May",  "Jun",  "Jul",  "Aug",  "Sep",  "Oct",  "Nov",  "Dec"];
export const DAY_LIST = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const CIRCUIT_MODE_ALL = 0;
export const CIRCUIT_MODE_FORCED = 1;
export const CIRCUIT_MODE_SEVRAGE = 2;
export const CIRCUIT_MODE_CENTER = 3;
export const  RAISED_ALARM_BLACKOUT = "BLACKOUT";
export const  RAISED_ALARM_HARDWARE_FAILURE = "HARDWARE_FAILURE";
export const  RAISED_ALARM_VANDALISM = "VANDALISM";
export const  RAISED_ALARM_LEAK = "LEAK";
export const  RAISED_ALARM_UNIDENTIFIED_ORIGIN = "UNIDENTIFIED_ORIGIN";
export const  RAISED_ALARM_OTHER= "OTHER";
export const MEASURE_SENSOR = 12;
export const DEVICE_MODEL_TYPE_LR_MS_4 = "lr-ms-4";
export const DEVICE_MODEL_TYPE_LR_MS_1 = "lr-ms-1";
export const DEVICE_MODEL_TYPE_LR_MS_WAN = "lr-ms-wan";
export const DEVICE_MODEL_TYPE_LR_MS_SENSOR = [DEVICE_MODEL_TYPE_LR_MS_4, DEVICE_MODEL_TYPE_LR_MS_WAN, DEVICE_MODEL_TYPE_LR_MS_1];
export const HUMIDITY_INPUTS_TYPE = ["12","3"];
export const DEBIT_METER_INPUTS_TYPE = ["1", "lr-fl-1"];
export const  WATER_BUDGET_TYPE= "waterbudget";
export const  HUMIDITY_TYPE= "humidity";
export const  STATION_TYPE= "hunter_icd";


