import {Constants} from "../constants";

const initialState: any = {
    listEquipmentEvents: {
        pagination: [],
        data: [],
    },
    latestEvents: [],
};

const EventReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
      case Constants.SET_HYDRIQUE_EQUIPMENT_EVENT_LIST:
        return {
          ...state,
          listEquipmentEvents: { pagination: { ...action.payload.pagination }, data: { ...action.payload.data } },
        };

      case Constants.SET_HYDRIQUE_LATEST_EVENT_EQUIPMENT:
        return {
          ...state,
          latestEvents: action.payload.data };
      default:
        return state;
    }
};

export default EventReducer;
