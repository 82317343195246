import { Constants } from "../constants";
export default {
  setEquipmentsList(payload: any) {
    return {
      payload,
      type: Constants.SET_LIST_EQUIPMENT,
    };
  },
  filterStatus(payload: string) {
    return {
      payload,
      type: Constants.FILER_STATUS,
    };
  },
  filterLocationReference(payload: any) {
    return {
      payload,
      type: Constants.SET_LOCATION_REFERENCE_FILTER,
    };
  },
  setEquipmentsStats(payload: string) {
    return {
      payload,
      type: Constants.SET_EQUIPMENT_STATS,
    };
  },
  setEquipment(payload: any) {
    return {
      payload,
      type: Constants.SET_EQUIPMENT,
    };
  },
};
