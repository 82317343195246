import moment from "moment";
import {DAY_LIST} from "../../watering/store/constants";

export const stringToSlug = (str: string) => {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length ; i < l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

    return str;
};

export const formatDate = (date: string) => {
    return date ? moment(date, "YYYY/MM/DD").format("DD-MM-YYYY") : "-";
};

export const formatServerDate = (date: Date) => {
    return date ? moment(date).format("YYYY-MM-DD") : "-";

};

export const formatDateTime = (date: string) => {
    return date ? moment(date, "YYYY/MM/DD hh:mm:ss").format("DD/MM/YYYY HH:mm:ss") : "-";
};

export const formatLocalDateTime = (date: string,local="fr") => {
    return date ? moment.utc(date, "YYYY/MM/DD hh:mm:ss").local().format("DD/MM/YYYY HH:mm:ss") : "-";
};

export const formatIsoDateTime = (date: Date) => {
    return date ? `${moment(date).format("YYYY-MM-DDTHH:mm:ss")}Z` : "-";
};

export const formatMeteoDate = (date: string,t:any) => {
    const dt =  new Date(date);

    return `${t('for_date')} ${t(`full${DAY_LIST[dt.getDay()]}`)} ${moment(dt).format(`DD/MM ${t('at')} HH:mm`)}`;
};

export const formatDateWithDay = (date: string) => {
  return date ? moment(date, "YYYY/MM/DD").format("DD/MM/YYYY") : "-";
};

export const  hasService = (code: string, user: { services: any[]; }) => {
    const service = user.services.find((item) => item.code === code );

    return service !== undefined;
};

export const checkTimeInHours = (date: string) => {
    const currentTime = moment();
    const timeStore = moment(date);
    return currentTime.diff(timeStore, "h");
};

export const getCoordinateCenter = (geojson: any) => {
    return geojson.reduce( (x: any, y: any) => {
        return [x[0] + y[0] / geojson.length, x[1] + y[1] / geojson.length];
    }, [0, 0]);
};

export const getCentroidMultipolygon = (geojson: any): any => {
        return getCoordinateCenter(geojson[0]);
};

export const validateDateFormat = (date: string, format: string, strict: boolean = false): boolean => {
    return moment(date, format, strict).isValid();
}