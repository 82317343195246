import React, {useEffect} from "react";
import MapManager from "../../../map/manager/MapManager";
import {routesPath} from "../../../routes";
import Breadcrumb from "../../components/Utils/Breadcrumb";
import {useHistory} from "react-router";
import WateringTabNavigation from "../../components/Utils/WateringTabNavigation";
import {config} from "../../../config";
import ReactGA from "react-ga";

const Map = () => {
    const history = useHistory();
    useEffect(() => {
        if (config.googleAnalyticsId && history.location) {
            ReactGA.initialize(config.googleAnalyticsId);
            ReactGA.set({ page: history.location.pathname }); // Update the user's current page
            ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
        }
    },[history.location.pathname]);

    const selectTabHandle = (tab: any) => {
        history.push(tab)
    }
  return (
    <div className="m-2 w-100 overflow-auto">
        <div className="row p-0 m-0">
            <div className="col-5 p-0 m-0">
                <Breadcrumb elements={[{ text: "Map" }]} />
            </div>
            <div className="col-7">
                <WateringTabNavigation route={routesPath.map} selectTabHandle={selectTabHandle}/>
            </div>
        </div>
        <MapManager functionalType="arrosage" isAffectedToCircuit={1}/>
    </div>
  );
};

export default Map;
