import { Constants } from "../constants";
export default {
  setAlarmsRaisedList(payload: any) {
    return {
      payload,
      type: Constants.SET_RAISED_ALARM_LIST,
    };
  },
  setAlarmsAcquittedList(payload: any) {
    return {
      payload,
      type: Constants.SET_ACQUITTED_LIST,
    };
  },
  setAlarmsRaisedCount(payload: any) {
    return {
      payload,
      type: Constants.SET_RAISED_ALARM_COUNT,
    };
  },
  setAlarmsAcquittedCount(payload: any) {
    return {
      payload,
      type: Constants.SET_ACQUITTED_COUNT,
    };
  },
  filterLocationReference(payload: any) {
    return {
      payload,
      type: Constants.SET_ALARM_LOCATION_REFERENCE_FILTER,
    };
  },
};
