import React from "react";
import { routesPath } from "../../../routes";
import ManuelConfigHunterManager from "../../../watering/manager/Programming/ManuelConfigHunterManager";
import Breadcrumb from "../../components/Utils/Breadcrumb";

const ProgrammingMobileHunterConfig = () => {
    return (
      <div className="m-0 m-xl-2 w-100 overflow-auto">
        <Breadcrumb
          backUrl={
              {
                  pathname: routesPath.programming.index,
                  state: { previous: routesPath.programming.config},
              }
              }
          elements={[
            { text: "manuelProgramming", link: routesPath.programming.index },
          ]}
        />
        <ManuelConfigHunterManager />
      </div>
    );
};

export default ProgrammingMobileHunterConfig;
