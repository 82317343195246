import instanceRequest, {Request} from "../../main/services/Request";
import { formatServerDate } from "../../main/utils";
import {MEASURE_SENSOR} from "../store/constants";
import {PAGINATION_HEADERS} from "../../main/store/constants";

class MeasureService {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public getMeasureByYear() {
    const url = `/api/measure/cumul/year`;
    return this.axiosService.post(url, false, false).then((response) => {
      return response.data;
    });
  }

  public getMeasureByMonth(years: any[], locationReferenceId = "", format = "json") {
    const url = `/api/measure/cumul/month${this.getQueryYears(years)}&format=${format}`;
    let body = {};
    if (locationReferenceId.length) {
      body = { locationReference: [locationReferenceId] };
    }

    if (format === "json") {
      return this.axiosService.post(url, body, false).then((response) => {
        return response.data;
      });
  } else {
      return this.axiosService.getBlobWithPost(url, body).then((response) => {
          return response;
      });
  }
  }

  public getMeasureByLocationReference(
    filters = { locationReferences: [] },
    page = 1,
    sortField = "yesterday",
    sortDirection = "desc",
    itemsPerPage = 20,
  ) {
    let url = `/api/measure/accumulation/locationReference`;
    const sort = this.formatSort(sortField, sortDirection);
    let body = {};
    if (filters.locationReferences.length) {
      const locationReferenceIds: any[] = [];
      filters.locationReferences.map((item: any) => {
        if (item.id) {
          locationReferenceIds.push(item.id);
        }
      });
      body = { locationReference: locationReferenceIds };
    }
    url = `${url}?currentPage=${page}&itemsPerPage=${itemsPerPage}&${sort}`;
    return this.axiosService.post(url, body, false).then((response) => {
      return {
        pagination: this.getPaginationInfo(response.headers),
        data: response.data,
      };
    });
  }

  public getPaginationInfo(headers: any): any {
    const pagination: any = [];
    Object.entries(headers).map((header: any) => {
      if (PAGINATION_HEADERS.includes(header[0])) {
        pagination[header[0]] = header[1];
      }
      return pagination;
    });

    return pagination;
  }

  public getFlowMeasureBetweenDates(circuitId: string, startDate: Date, endDtae: Date) {
    const url = `/api/measure/cumul/${circuitId}?startAt=${formatServerDate(startDate)}&endAt=${formatServerDate(endDtae)}`;
    return this.axiosService.get(url, false, false).then((response) => {
        return response.data;
    });
  }
  public getLastMeasures(circuits:any) {
    let filterQuery = "";
    Object.values(circuits).map((item: any) => {
      filterQuery = filterQuery ? `${filterQuery}&circuit[]=${item.id}` : `circuit[]=${item.id}`;
    });
    const url = `/api/measure/last/${MEASURE_SENSOR}?${filterQuery}`;
    return this.axiosService.get(url, false, false).then((response) => {
      return response;
    });
  }

  private getQueryYears(years: any[]) {
    let query = "";
    years.forEach((year: string) => {
      query = query ? `${query}&year[]=${year}` : `?year[]=${year}`;
    });

    return query;
  }

  private formatSort(sortField: string, sortDirection: string): string {
    return `sortKey=${sortField}&sortDir=${sortDirection}`;
  }
}

export default new MeasureService(instanceRequest);
