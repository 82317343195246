import React from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {SzIcon, SzTypographie} from "@suezenv/react-theme-components";
import "./index.scss";

interface IBreadcrumbElement {
    text: string;
    link?: string;
}

interface IBreadcrumb {
    elements: IBreadcrumbElement[];
    backUrl?: any
}

const Breadcrumb = (props: IBreadcrumb) => {
    const {t} = useTranslation();
    const history = useHistory();
    return (
        <div className="breadcrumb-container">
            <SzIcon variant="line"
                    onClick={() => history.goBack()} className="float-left pl-1 pr-1 border" icon="arrow-left"/>
            <ol className="breadcrumb font-weight-bold mb-0">
                {
                    props.elements.map((element: IBreadcrumbElement, index: any) =>
                        <li key={index}className={`breadcrumb-item ${element.link && "cursor-pointer"}`}
                            onClick={() => element.link && history.push(element.link)}>
                            <SzTypographie>{t(`breadcrumb.${element.text}`)}</SzTypographie>
                        </li>,
                    )
                }
            </ol>
        </div>
    );
};

export default Breadcrumb;
