import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SzBox, SzButton, SzIcon, SzInput, SzTypographie} from "@suezenv/react-theme-components";
import Switch from "../../../main/components/Utils/switch";
import {STATUS_INDEX_DEFECT, STATUS_INDEX_NORMAL, STATUS_INDEX_UNAVAILABLE, STATUS_LIST} from "../../../main/store/constants";

const Filter: any = (props: any) => {
    const {t} = useTranslation();
    const {filters} = props;
    const [state, setState] = useState({search: filters.query});
    const renderFilter = (status: string) => {
        return (<>
            <div className="row p-0 m-0">

                <div className=" pt-1 offset-2 col-10">
                    <SzTypographie color="inactive" weight="bold" variant="caption"
                                   className="m-0 p-0 ">{t(`equipment:status_code.${status}`)}</SzTypographie>
                </div>
            </div>
            <div className="row p-0 m-0">
                <div className="col-2">
                    <div className={`info-status status-${status.toLowerCase()} mt-3`}></div>
                </div>
                <div className="col-2">
                    <SzTypographie className="mt-2">{props.stats[status]}</SzTypographie>
                </div>
                <div className="col-8">
                    <Switch className="ml-4" onChangeHandle={() => props.filterStatusHandle(status)}
                            checked={props.filters.status[status]}/>
                </div>
            </div>
        </>);
    };
    const enterKeyHandle = (event: any) => {
        if (event.key === "Enter") {
            props.searchHandle(state.search);
        }
    };
    return (
        <SzBox className="row equipment-info-box pt-1  mb-4 m-0 p-0" tag="div">
            <div className="col-3">
                <SzIcon variant="line" className="float-left m-3" icon="gardening-hand-sprinkler"/>
                <SzTypographie className="m-0 p-0">{t("equipment:list_equipment_title1")}</SzTypographie>
                <SzTypographie color="inactive" variant="caption"
                               className="">{t("equipment:list_equipment_title2")}</SzTypographie>

            </div>
            <div className="col-3 mt-3 row  p-0 m-0">
                <div className="col-8 p-0">
                    <SzInput
                        value={state.search}
                        onKeyUp = {enterKeyHandle}
                        onChange={({target}) => setState({...state, search: target.value})}
                        placeholder={t("equipment:select_location_reference2")}
                    />
                </div>
                <div className="col-2 text-center">
                    <SzButton onClick={() => props.searchHandle(state.search)} variant="secondary" icon="search"/>
                </div>
                {state.search &&
                < div className="col-2 p-0">
                    <SzButton onClick={()=>{
                        setState({...state, search: ""})
                        props.resetHandle()
                    }} variant="secondary" icon="diagram-round"/>
                </div>
                }
            </div>
            <div className="col-2  p-0 m-0">
                {renderFilter(STATUS_LIST[STATUS_INDEX_DEFECT])}
            </div>
            <div className="col-2  p-0 m-0">
                {renderFilter(STATUS_LIST[STATUS_INDEX_UNAVAILABLE])}
            </div>
            <div className="col-2  p-0 m-0">
                {renderFilter(STATUS_LIST[STATUS_INDEX_NORMAL])}
            </div>
        </SzBox>
    );
};

export default Filter;
