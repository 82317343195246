import React from "react";
import {useTranslation} from "react-i18next";
import "./index.scss";
import {SzTab} from "@suezenv/react-theme-components/build/components/SzTab/SzTab";
import {routesPath} from "../../../../routes";
import {menuItems} from "../../Container";
import {MAX_MOBILE_WIDTH} from "../../../store/constants";


const WateringTabNavigation = (props: any) => {
    const {t} = useTranslation();
    return (window.screen && window.screen.width >= MAX_MOBILE_WIDTH) ? (
        <SzTab
            activeKey={props.route}
            id="story-tab"
            selectTabHandle={props.selectTabHandle}
        >

            <SzTab.SzTabContent eventKey={routesPath.programming.index} title={t(menuItems.programming)}>
            </SzTab.SzTabContent>
            <SzTab.SzTabContent eventKey={routesPath.map} title={t(menuItems.map)}>
            </SzTab.SzTabContent>
            <SzTab.SzTabContent eventKey={routesPath.consumption} title={t(menuItems.consumption)}>
            </SzTab.SzTabContent>
            <SzTab.SzTabContent eventKey={routesPath.watering} title={t(menuItems.watering)}>
            </SzTab.SzTabContent>
        </SzTab>
    ) : <></>;
};

export default WateringTabNavigation;
