import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {SzBox, SzButton, SzIcon, SzInput, SzModal, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import {TimeSelect} from "../../../main/components/Utils/TimeSelect";
import EquipmentService from "../../services/EquipmentService";
import ProgrammingService from "../../services/ProgrammingService";
import {programmingAction} from "../../store/actions";

class ManuelConfigHunterManager extends React.Component<any> {
    public state: any = {
        components: [],
        selectedOption: null,
        programMinutes: "00",
        programSeconds: "00",
    };

    public componentDidMount() {
        EquipmentService.getEquipment(this.props.match.params.moduleId).then((response) => {
            if (response.data) {
                const module: any = response.data;
                this.props.setSelectedCircuit(module.circuit);
                this.props.setSelectedModule(module);
            }
        });
        ProgrammingService.getComponentsByModule(this.props.match.params.moduleId, "actuator", "").then((components: any) => {
            this.setState({components});
        });
    }

    public getOptions = () => {
        const options: any = [];

        const listComponent = this.state.components.sort((componentA: any, componentB: any) => {
            return componentA.externalId - componentB.externalId;
        });

        listComponent.forEach((component: any) => {
            options.push({value: component.id, label: `${component.externalId} - ${component.name}`,number: component.externalId});
        });
        return options;
    }

    public sendManuelWatering = (event: any) => {
        const body = {
            id: this.state.selectedOption.value,
            number: this.state.selectedOption.number,
            duration: (parseInt(this.state.programMinutes) * 60) + (parseInt(this.state.programSeconds)),
        };

        ProgrammingService.sendManuelWatering(this.props.match.params.moduleId, body).then(() => {
        });
        event.preventDefault();
    }

    private isDisabledBtn =()=>{
        if(!this.state.selectedOption || !this.state.programMinutes || !this.state.programSeconds)
        {
            return true;
        }

        return (this.state.programMinutes * 60 +  this.state.programSeconds < 15) ;
    }

    public render() {
        return (
            <>
                {(this.props.circuit && this.props.module) &&
                    <>
                        <SzBox
                            className="row equipment-info-box m-0 p-0 mt-0 mt-xl-4 mb-4"
                            tag="div">
                            <div className="col-1 mt-3">
                                <SzIcon variant="line" icon="gardening-hand-sprinkler"/>
                            </div>
                            <div className="col mt-3">
                                <SzTypographie color="inactive" variant="caption">
                                    {this.props.t("programming:title_circuit", {circuit: this.props.circuit.name})}
                                </SzTypographie>
                                <SzTypographie className="m-0">{this.props.module.name}</SzTypographie>
                            </div>
                        </SzBox>
                        <div className="equipment-info-box p-3">
                            <form onSubmit={this.sendManuelWatering}>
                                <div className="row">
                                    <div className="col-12 w-25 mb-4">
                                        <SzTypographie variant="caption">Stations :</SzTypographie>
                                        <SzSelect
                                            id={"id-1"}
                                            placeholder={this.props.t("programming:station.select_title")}
                                            options={this.getOptions()}
                                            value={this.state.selectedOption}
                                            isSearchable={false}
                                            isClearable
                                            onChange={(option: { value: string, label: string, number: string }) => {
                                                this.setState({
                                                    selectedOption: {
                                                        value: option.value,
                                                        label: option.label,
                                                        number: option.number,
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-4">
                                        <TimeSelect
                                            required={true}
                                            onChangeFirst={(value: string) => {
                                                this.setState({programMinutes: value});
                                            }}
                                            onChangeSecond={(value: string) => {
                                                this.setState({programSeconds: value});
                                            }}
                                            firstLabel={'Min'}
                                            secondLabel={'Sec'}
                                            maxFirstValue={(12*60)}
                                            firstName=""
                                            firstValue={this.state.programMinutes}
                                            secondName=""
                                            secondValue={this.state.programSeconds}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <SzButton
                                            isDisabled={this.isDisabledBtn()}
                                            className="mr-3 text-white" icon="send-email-1" alignIcon="right"
                                                  type={"submit"}>{this.props.t("programming:program.save")}</SzButton>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </>
                }
            </>
        );
    }
}

const mapDispatchToProps = {
    setSelectedCircuit: programmingAction.setSelectedCircuit,
    setSelectedModule: programmingAction.setSelectedModule,
};
const mapStateToProps = (state: any) => {
    return {
        circuit: state.programming.selectedCircuit,
        module: state.programming.selectedModule,
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ManuelConfigHunterManager)));
