import instanceRequest, {Request} from "../../main/services/Request";
import {formatServerDate} from "../../main/utils";
import {MEASURE_SENSOR} from "../store/constants";
import {PAGINATION_HEADERS} from "../../main/store/constants";

class MeasureDetailService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getMeasureDetailDayByStep(circuitId: string, startDate: Date, endDtae: Date) {
        const url = `/api/measure/cumul/detailed/${circuitId}?startAt=${formatServerDate(startDate)}&endAt=${formatServerDate(endDtae)}`;
        return this.axiosService.get(url, false, false).then((response) => {
            return response.data;
        });
    }
}

export default new MeasureDetailService(instanceRequest);
