import {Constants} from "../constants";

const initialState: any = {
    totalYear: null,
    totalLastYear: null,
    measureByMonth: {},
    measureByMonAndLocationReference: {},
    measuresByLocationReference: {},
    filters: {
        locationReferences: [],
        query: ""
    },
    measureDetailDayByStep: {flow: [], sensor: []},
};

const MeasureReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
      case Constants.SET_MEASURE_BY_YEAR:
        return { ...state, ...action.payload };
      case Constants.SET_MEASURE_BY_MONTH:
        return { ...state, measureByMonth: action.payload };
      case Constants.SET_MEASURE_BY_MONTH_AND_LOCATION_REFERENCE:
            return { ...state, measureByMonAndLocationReference: {
                    ...state.measureByMonAndLocationReference,
                    [action.payload.locationReferenceId]: action.payload.data,
                },
            };
      case Constants.SET_MEASURE_BY_LOCATION_REFERENCE:
        return { ...state, measuresByLocationReference: action.payload };
      case Constants.SET_MEASURE_LOCATION_REFERENCE_FILTER:
        return {
          ...state,
          filters: { ...state.filters, locationReferences: action.payload.ids, query: action.payload.query },
        };
        case Constants.SET_MEASURE_DETAIL_DAY_BY_STEP:
            return {
                ...state,
                measureDetailDayByStep: {...action.payload},
            };
      default:
        return state;
    }
};

export default MeasureReducer;
