import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzButton, SzModal, SzPagination, SzTable, SzTypographie } from "@suezenv/react-theme-components";
import ConsumptionByLocationReference from "../components/Consumption/ConsumptionByLocationReference";
import ConsumptionHeaderList from "../components/Consumption/ConsumptionHeaderList";
import ExportConsumption from "../components/Consumption/ExportConsumption";
import EmptyRow from "../components/utils/EmptyRow";
import LocationReferenceService from "../../main/services/LocationReferenceService";
import MeasureService from "../services/MeasureService";
import { measureAction } from "../store/actions";
import {
  PAGINATION_CURRENT_PAGE,
  PAGINATION_PAGE_COUNT,
  PAGINATION_PER_PAGE,
  PAGINATION_TOTAL_COUNT,
} from "../../main/store/constants";

class ListConsumptionManager extends React.Component<any> {
  public state = {
    sort: { field: "yesterday", direction: "asc" },
    columns: [],
    page: 1,
    showConsumptionModal: false,
    consumptionLocationReference: 0,
    showExportConsumptionModal: false,
  };

  public componentDidMount() {
    this.initTable();
  }

  public initTable() {
    this.setData();
    this.setColumn(this.state.sort.field, this.state.sort.direction);
  }

  public setData(page = 1, sortFiled = "yesterday", sortDirection = "desc", filters:any= null) {
    MeasureService.getMeasureByLocationReference(filters ? filters : this.props.filters, page, sortFiled, sortDirection).then(
      (response: any) => {
        this.props.setMeasureByLocationReference(response);
      },
    );
  }

  public setColumn(dataField = "", sort = "") {
    const { t } = this.props;
    const sortDirection = sort === "desc" ? "sz-icon-line arrow-down-1" : "sz-icon-line arrow-up-1";
    this.setState({
      columns: [
        this.columnJson("locationReferenceName", "locationReferenceName", "PI", dataField, sortDirection, false),
        this.columnJson("yesterday", "yesterday", t("consumption:list.yesterday"), dataField, sortDirection),
        this.columnJson("month", "month", t("consumption:list.currentMonth"), dataField, sortDirection),
        this.columnJson("year", "year", t("consumption:list.currentYear"), dataField, sortDirection),
        this.columnJson("action", "action", "", dataField, sortDirection, false),
      ],
    });
  }

  public columnJson(
    classes: string,
    dataField: string,
    text: string,
    sortDataField: string,
    sortDirection: string,
    canSort = true,
  ) {
    const { t } = this.props;
    const headerDefaultClass = canSort ? "sz-icon-line move-expand-vertical" : "";
    return {
      classes,
      dataField,
      text: t(text),
      headerClasses: sortDataField === dataField ? sortDirection : headerDefaultClass,
      headerEvents: {
        onClick: canSort ? this.sortByColumn.bind(this) : () => {},
      },
    };
  }

  public sortByColumn(e: any, column: any) {
    const { direction } = this.state.sort;
    const sortDirection = direction && direction === "desc" ? "asc" : "desc";
    this.setState({ sort: { direction: sortDirection, field: column.dataField } });
    this.setColumn(column.dataField, sortDirection);
    this.setData(this.state.page, column.dataField, sortDirection);

    return true;
  }

  public getListData(): any {
    const { measuresByLocationReference } = this.props;
    const result: Array<{ locationReferenceName: any; yesterday: any; month: any; year: any; id: any }> = [];
    if (measuresByLocationReference && measuresByLocationReference.data && measuresByLocationReference.data.length) {
      measuresByLocationReference.data.forEach((item: any) => {
        let nameRef = item.locationReferenceName;
        let sigId = item.locationReferenceSigId;
        if (this.props.filters.locationReferences && this.props.filters.locationReferences.length) {
          this.props.filters.locationReferences.map((filterLocalRef: any) => {
            if (filterLocalRef.id === item.locationReferenceId ) {
              nameRef = filterLocalRef.name;
              sigId = filterLocalRef.sigId;
          }});
        }
        const infoLocationRef = {
          locationReferenceName: (
            <>
              <SzTypographie className="mb-0" variant="caption" weight="light">
                {nameRef}
              </SzTypographie>
              <SzTypographie>{sigId}</SzTypographie>
            </>
          ),
          yesterday: `${item.yesterday.value || item.yesterday.value === 0 ? `${parseFloat((item.yesterday.value/1000).toFixed(1))} m3` : "-"}` ?? "-",
          month: `${item.currentMonth.value || item.currentMonth.value === 0 ? `${parseFloat((item.currentMonth.value/1000).toFixed(1))} m3` : "-"}` ?? "-",
          year: `${item.currentYear.value || item.currentYear.value === 0 ? `${parseFloat((item.currentYear.value/1000).toFixed(1))} m3` : "-"}` ?? "-",
          id: item.locationReferenceId,
          action: (
            <SzButton variant="secondary"
              onClick={() => this.showConsumptionChart(item)}
              className="mr-3 float-right"
              icon="analytics-bars-3d" />
          ),
        };
        result.push(infoLocationRef);
      });
    }

    return result;
  }

  public showConsumptionChart(measure: any) {
    this.setState({
      consumptionLocationReference: {
        id: measure.locationReferenceId,
        name: measure.locationReferenceName,
        sigId: measure.locationReferenceSigId,
      },
      showConsumptionModal: true,
    });
  }
  public searchHandle(query: string) {
    LocationReferenceService.getLocationReferences(query).then((response: any) => {
      const localReferencesId = query !== "" ? response.data.length === 0 ? false : response.data : [];
      if (!localReferencesId) {
        this.props.setMeasureByLocationReference([]);
        this.props.filterLocationReference({ids: localReferencesId, query});
      } else {
        const filters = {
          ...this.props.filters,
          locationReferences: localReferencesId,
        };
        MeasureService.getMeasureByLocationReference(filters).then((response: any) => {
          this.props.filterLocationReference({ids: localReferencesId, query});
          this.props.setMeasureByLocationReference(response);
        });
      }
    });
  }
  public resetHandle() {
    this.props.filterLocationReference({ids: [], query: ""});
    this.setData(1,'yesterday', 'desc',{ locationReferences: [] });
  }

  public setMeasureByMonthAndLocationReference(years = [], locationReferenceId: string) {

    MeasureService.getMeasureByMonth(years, locationReferenceId).then((response) => {
      this.props.setMeasureByMonthAndLocationReference(locationReferenceId, response);
    });
  }

  public render() {
    const { measuresByLocationReference, measureByMonAndLocationReference , t, filters} = this.props;
    return (
      <div className="">
        <SzModal
          title={""}
          show={this.state.showConsumptionModal}
          handleClose={() => this.setState({ showConsumptionModal: false })}
          size={"xl"}
        >
          <ConsumptionByLocationReference
            setMeasureByMonthAndLocationReference={this.setMeasureByMonthAndLocationReference.bind(this)}
            locationReference={this.state.consumptionLocationReference}
            measureByMonAndLocationReference={measureByMonAndLocationReference}
          />
        </SzModal>
         <SzModal
          title={""}
          show={this.state.showExportConsumptionModal}
          handleClose={() => this.setState({ showExportConsumptionModal: false })}
          size={"lg"}
        >
          <ExportConsumption/>
        </SzModal>
        <ConsumptionHeaderList filters={filters}  resetHandle= {this.resetHandle.bind(this)} searchHandle={this.searchHandle.bind(this)} />
        {this.state.columns.length &&
        this.props.measuresByLocationReference &&
        this.props.measuresByLocationReference.data &&
        this.props.measuresByLocationReference.data.length ? (
          <div className="list-table consumption row m-2 mr-3">
            <SzTable
              rowClasses={(row: any) => {
                return row.id === this.props.currentProjectId ? "selected-project" : "";
              }}
              columns={this.state.columns}
              data={this.getListData()}
              keyField="id"
              className="table-striped"
            />
            {measuresByLocationReference && measuresByLocationReference.pagination && (
              <div className="row w-100">
                <div className="col-6 w-100 offset-5">
                  {measuresByLocationReference.pagination[PAGINATION_PAGE_COUNT] > 1 && (
                    <SzPagination
                      totalItemsCount={parseInt(measuresByLocationReference.pagination[PAGINATION_TOTAL_COUNT], 10)}
                      activePage={parseInt(measuresByLocationReference.pagination[PAGINATION_CURRENT_PAGE], 10)}
                      onChange={(pageNumber: any) => {
                        this.setState({ page: pageNumber });
                        this.setData(pageNumber, this.state.sort.field, this.state.sort.direction);
                      }}
                      itemsCountPerPage={parseInt(measuresByLocationReference.pagination[PAGINATION_PER_PAGE], 10)}
                      pageRangeDisplayed={parseInt(measuresByLocationReference.pagination[PAGINATION_PAGE_COUNT], 10)}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <EmptyRow />
        )}
         <SzButton variant="secondary" className="mr-3 float-right" icon="download-bottom" onClick={() => this.setState({ showExportConsumptionModal: true })}>
          {t("export")}
        </SzButton>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMeasureByLocationReference: measureAction.setMeasureByLocationReference,
  setMeasureByMonthAndLocationReference: measureAction.setMeasureByMonthAndLocationReference,
  filterLocationReference: measureAction.filterLocationReference,
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    measuresByLocationReference: state.measure.measuresByLocationReference,
    measureByMonAndLocationReference: state.measure.measureByMonAndLocationReference,
    filters: state.measure.filters,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ListConsumptionManager));
