import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {SzBox, SzButton, SzIcon, SzModal, SzTypographie} from "@suezenv/react-theme-components";
import ConsumptionHistory from "./ConsumptionHistory";
import "./style.scss";

const ConsumptionHeader: any = (props: any) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (<>
    <div className="m-2 w-100">
      <SzModal title={""}  show={show} handleClose={() => setShow(false)} size={"xl"}>
        <ConsumptionHistory setMeasureByMonth={props.setMeasureByMonth} measure={props.measure}/>
      </SzModal>
      <SzBox className="equipment-info-box row mr-4  m-0 p-0 pt-4  cursor-pointer" tag="div">
        <div className="float-left mr-2 ml-2">
          <SzIcon variant="line" icon="color-drop-pick"/>
        </div>
        <div className="col-3 mt-2">
          <SzTypographie className="float-left mr-2" >
            {t(`consumption:consumption_year`)} :
          </SzTypographie>
          <SzTypographie weight="bold">
            {`${props.measure.totalYear ? parseFloat((props.measure.totalYear/1000).toFixed(1)): 0} m3`}
          </SzTypographie>
        </div>
        <div className="col-3 mt-2">
          <SzTypographie className="float-left mr-2" >
            {t(`consumption:consumption_last_year`)} :
          </SzTypographie>
          <SzTypographie weight="bold">
            {`${props.measure.totalLastYear ? parseFloat((props.measure.totalLastYear/1000).toFixed(1)) : 0} m3`}
          </SzTypographie>
        </div>
        <div className="col-2 p-0">
          <SzButton variant="secondary" onClick={() => setShow(true)} className="mr-3" icon="time-clock-circle-1">
            {t(`consumption:show_history`)}
          </SzButton>
        </div>
      </SzBox>
    </div>
  </>);
};

export default ConsumptionHeader;
