import React from "react";
import { useTranslation } from "react-i18next";
import { SzIcon, SzList, SzTypographie } from "@suezenv/react-theme-components";
import { formatDateTime } from "../../../main/utils";
import "./style.scss";

const DetailEquipment: any = (props: {equipment: any}) => {
  const { t } = useTranslation();
  const { equipment } = props;
  const renderAttribute = (label: string, value: any, isNotText = false) => {
     return (
       <div className="col-3">
         <SzTypographie className="m-0" color="inactive" variant="caption">
           {t(`equipment:detail.${label}`)}
         </SzTypographie>
         {isNotText ? value ? value : "-" : <SzTypographie>{value ? value : "-"}</SzTypographie>}
       </div>
     );
   };

  const renderCoordinate = (equipment: any) => {
    return equipment.latitude && equipment.longitude ? `${equipment.latitude}, ${equipment.longitude}` : "-";
  };

  const renderStatus = (status: string) => {
    return (
      <div className="row p-0 m-0">
        <div className="col-1">
          <div className={`info-status status-${status.toLowerCase()}`}></div>
        </div>
        <div className="col-5">
          <SzTypographie>{t(`equipment:status_code.${status}`)}</SzTypographie>
        </div>
      </div>
    );
  };

  const renderTitle = (label: string, name: string) => {
  return (
    < div className="row">
      <div className="col-1">
        <SzIcon variant="line" icon="task-list-text-1" />
      </div>
      <div className="col-3">
        <SzTypographie className="m-0" color="inactive" variant="caption">
          {t(`equipment:detail.${label}`)}
        </SzTypographie>
        <SzTypographie>{name ? name : "-"}</SzTypographie>
      </div>
    </div>
  );
  };

  return (
    <SzList>
      <SzList.Item>{renderTitle("titleModal", equipment.name)}</SzList.Item>
      <SzList.Item>
        <div className="row">
          {renderAttribute("name", equipment.deviceModel.name)}
          {renderAttribute("type", equipment.deviceModel.label)}
          {renderAttribute("description", equipment.deviceModel.description)}
          {renderAttribute("category", equipment.deviceModel.category.label)}
          {renderAttribute("serialNumber", equipment.serialNumber)}
          {renderAttribute("dateInstalled", equipment.dateInstalled)}
        </div>
      </SzList.Item>
      <SzList.Item>
        <div className="row">
          {renderAttribute("address", equipment.address)}
          {renderAttribute("coordinate", renderCoordinate(equipment))}
        </div>
      </SzList.Item>
      <SzList.Item>
        <div className="row">{renderAttribute("status", renderStatus(equipment.status), true)}</div>
      </SzList.Item>
      {equipment.relay &&
      <SzList.Item>
        <div className="row">
          {renderAttribute("relay", equipment.relay.name)}
        </div>
      </SzList.Item>}
      <SzList.Item>
        <div className="row">
          {renderAttribute("macAddress", equipment.macAddress)}
          {renderAttribute("softwareVersion", equipment.softwareVersion)}
          {renderAttribute("hardwareVersion", equipment.hardwareVersion)}
          {renderAttribute("battery", equipment.battery)}
          {renderAttribute("rssi", equipment.rssi)}
          {renderAttribute("lastCommunication", formatDateTime(equipment.lastCommunication))}
        </div>
      </SzList.Item>
    </SzList>
  );
};

export default DetailEquipment;
