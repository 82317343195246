import {Constants} from "../constants";

const initialState: any = {
    listRaisedAlarms: {
        pagination: [],
        data: [],
        equipments:[],
        nbr: 0,
    },
    listAcquittedAlarms: {
        pagination: [],
        data: [],
        nbr: 0,
    },
    filters: {
        locationReferences: [],
        query:''
    },
};

const AlarmReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case Constants.SET_RAISED_ALARM_LIST:
            return {
                ...state,
                listRaisedAlarms: {
                    ...state.listRaisedAlarms,
                    pagination: {...action.payload.pagination},
                    data: {...action.payload.data},
                    equipments: action.payload.equipments
                },
            };
        case Constants.SET_ACQUITTED_LIST:
            return {
                ...state,
                listAcquittedAlarms: {
                    ...state.listAcquittedAlarms,
                    pagination: {...action.payload.pagination},
                    data: {...action.payload.data},
                },
            };
        case Constants.SET_RAISED_ALARM_COUNT:
            return {
                ...state,
                listRaisedAlarms: {...state.listRaisedAlarms, nbr: action.payload},
            };
        case Constants.SET_ACQUITTED_COUNT:
            return {
                ...state,
                listAcquittedAlarms: {...state.listAcquittedAlarms, nbr: action.payload},
            };
        case Constants.SET_ALARM_LOCATION_REFERENCE_FILTER:
            return {
                ...state,
                filters: {...state.filters, locationReferences: action.payload.ids, query: action.payload.query},
            };
        default:
            return state;
    }
};

export default AlarmReducer;
