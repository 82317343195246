import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {SzBox, SzButton, SzIcon, SzModal, SzTypographie} from "@suezenv/react-theme-components";
import {formatLocalDateTime} from "../../../main/utils";
import {routesPath} from "../../../routes";
import {RAISED_STATUS, WATERING_FUNCTIONAL_TYPE} from "../../../main/store/constants";
import { displayValues } from "../../store/tools";
import AcknowledgeAlarm from "./AcknowledgeAlarm";
import "./style.scss";
import ViewEventManager from "../../manager/ViewEventManager";

const AlarmRow: any = (props: any) => {
    const {t} = useTranslation();
    const {alarm, acknowledgeAlarmHandle, user, equipment} = props;
    const [show, setShow] = useState(false);
    const [showEvents, setShowEvents] = useState(false);
    const history = useHistory();
    const toMapHandle = (alarm: any) => {
        const selectedLocationReference = {
            locationReference: {
                sigId: alarm.locationReferenceSigId,
                id: alarm.locationReferenceId,
                name: alarm.locationReferenceName,
            },
            filterWateringEquipment: false,
        };
        props.filterMapLocationReference(selectedLocationReference);
        history.push(alarm.functionalType === WATERING_FUNCTIONAL_TYPE ? routesPath.map : routesPath.hydriqueMap);
    };
    return (
      <>
          <SzModal title={""} show={showEvents} handleClose={() => setShowEvents(false)} size={"xl"}>
              {equipment &&
                  <ViewEventManager equipment={equipment}/>
              }
          </SzModal>
        <SzModal title={""} show={show} handleClose={() => setShow(false)} size={"lg"}>
          <AcknowledgeAlarm
            closeHandle={() => setShow(false)}
            acknowledgeAlarmHandle={acknowledgeAlarmHandle}
            alarm={alarm}
            user={user}
          />
        </SzModal>
        <SzBox className="row alarm-info-box m-0 p-0 p-2 mb-2" tag="div">
          <div className="col-2 p-0 m-0 row border-right">
            <SzIcon variant="line" icon="time-clock-circle-1" />
            <div className="col-8">
              <SzTypographie color="inactive" variant="caption" className="m-0">
                {t("alarm:list.detection")}
              </SzTypographie>
              <SzTypographie>{formatLocalDateTime(alarm.startAt)}</SzTypographie>
            </div>
          </div>
            <div className="col-2 p-0 m-0 row pl-4">
                <div className="col-4">
                    {alarm.locationReferenceSigId && <div title={t("alarm:btn.map")}>
                        <SzButton
                            variant="secondary"
                            icon="maps-pin-1"
                            alignIcon="right"
                            onClick={() => toMapHandle(alarm)}
                        ></SzButton>
                    </div>
                    }
                </div>
                <div className="col-6 pl-2">
                    <SzTypographie color="inactive" variant="caption" className="m-0">
                        {alarm.locationReferenceName}
                    </SzTypographie>
                    <SzTypographie>{alarm.locationReferenceSigId}</SzTypographie>
                </div>
            </div>
          <div className="col-6 p-0 m-0 row border-right">
              <div className="col-4">
                  <SzTypographie color="inactive" variant="caption" className="m-0">
                      {t("alarm:list.alarm")}
                  </SzTypographie>
                  <SzTypographie>{t(`alarm:types.${alarm.type}`)}</SzTypographie>
              </div>
            <div className="col-4">
              <SzTypographie color="inactive" variant="caption" className="m-0">
                {t("alarm:list.equipment_serial_numbr")}
              </SzTypographie>
              <SzTypographie>{`${alarm.equipmentName} / ${alarm.equipmentSerialNumber}`}</SzTypographie>
                <div title={t("equipment:list.btn_journal_title")}>
                    <SzButton onClick={() => setShowEvents(true)} variant="secondary" className="m-0" icon="calendar-3" alignIcon="right"></SzButton>
                </div>
            </div>

            <div className="col-4">
              <pre>{displayValues(alarm.values)}</pre>
            </div>
          </div>
          <div className="col-2 p-0 m-0 row pl-4">
              {alarm.status === RAISED_STATUS && (
                  <>
                      <div className="w-100">
                      <SzButton className="alarm-btn" onClick={() => setShow(true)} alignIcon="right"
                                variant="secondary" icon="check-circle-1">
                          {t("alarm:btn.acknowledge")}
                      </SzButton>
                      </div>
                      <div>
                         <span >
                          {t("equipment:list.status_title")} :
                         </span>
                          <span className={` mt-2 ml-2 info-status status-${equipment ? equipment.status.toLowerCase() : ''}`}> </span>
                      </div>
                  </>
                  )}
            </div>
        </SzBox>
      </>
    );
};

export default AlarmRow;
