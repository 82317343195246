import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzButton, SzCheckbox, SzIcon, SzInput, SzList, SzTypographie} from "@suezenv/react-theme-components";
import ProgrammingService from "../../services/ProgrammingService";

const ListStationsModal: any = (props: any) => {
    const {t} = useTranslation();
    const {moduleId, closeHandle} = props;

    const [state, setState] = useState({ search: "" });
    const [components, setComponents] = useState<any[]>([]);

    useEffect(() => {
        ProgrammingService.getComponentsByModule(moduleId, "actuator", "").then((components: any) => {
            setComponents(components);
        });
    }, []);

    const enterKeyHandle = (event: any) => {
        if (event.key === "Enter") {
            searchHandle(state.search);
        }
    };

    const searchHandle = (query: string)  => {
        ProgrammingService.getComponentsByModule(moduleId, "actuator", query).then((components: any) => {
            setComponents(components);
        });
    };

    const saveHandle = (event: any) => {

        const body = components.map((component: any) => {
            return {
                id: component.id,
                name: component.name,
                description: component.description,
                status: component.status,
            };
        });

        ProgrammingService.saveComponents(moduleId, body).then((components: any) => {
            setComponents(components);
        });
        closeHandle();
        event.preventDefault();
    };

    return (
        <>
            <SzList>
                <SzList.Item>
                    <div className="row">
                        <div className="col-1">
                            <SzIcon variant="line" icon="task-list-text-1"/>
                        </div>
                        <div className="col-6 p-0 mt-1">
                            <SzTypographie>{t("programming:station.list_title")}</SzTypographie>
                        </div>
                    </div>
                </SzList.Item>
            </SzList>
            <div className="row">
                <div className="col-6 offset-6">
                    <div className="row">
                        <div className="col-8 offset-2">
                            <SzInput
                                value={state.search}
                                onKeyUp={enterKeyHandle}
                                onChange={({target}) => setState({...state, search: target.value})}
                                placeholder={t("equipment:select_location_reference")}
                            />
                        </div>
                        <div className="col-2 text-center">
                            <SzButton onClick={() => searchHandle(state.search)} variant="secondary" icon="search"/>
                        </div>
                    </div>

                </div>
            </div>
            <form onSubmit={saveHandle}>
                <div className="row mt-5 station-list">
                    <div className="col">
                        <SzList>
                            {components.sort((a, b) => {
                                return a.externalId - b.externalId;
                            }).map((component: any, index: number) => {
                                return <SzList.Item key={index}>
                                    <div className="row">
                                        <div className="col text-center">
                                            <SzTypographie className="m-0" color="inactive" variant="caption">
                                                {t("programming:station.number_title")}
                                            </SzTypographie>
                                            <SzTypographie>{component.externalId}</SzTypographie>
                                        </div>
                                        <div className="col-4">
                                            <SzInput
                                                placeholder={t("programming:station.name_title")}
                                                value={component.name ? component.name : ""}
                                                onChange={({ target }) => {
                                                    components[index] = {...components[index], name: target.value};
                                                    setComponents([...components]);
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 p-0">
                                            <SzInput
                                                placeholder={t("programming:station.description_title")}
                                                value={component.description ? component.description : ""}
                                                onChange={({ target }) => {
                                                    components[index] = {...components[index], description: target.value};
                                                    setComponents([...components]);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </SzList.Item>;
                            })}
                        </SzList>
                    </div>
                </div>

                <div className="text-center mt-5 mb-4">
                    <SzButton variant="secondary" icon="bin-2-alternate" alignIcon="right" className="mr-3" onClick={closeHandle}>{t("programming:btn.close")}</SzButton>
                    <SzButton className="mr-3 text-white" icon="floppy-disk" alignIcon="right" type={"submit"}>{t("programming:btn.validate")}</SzButton>
                </div>
            </form>
        </>
    );
};

export default ListStationsModal;
