import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {SzAccordion, SzBox, SzButton, SzModal, SzTypographie} from "@suezenv/react-theme-components";
import {routes, routesPath} from "../../../routes";
import {
    ACTUATOR_MODULE,
    DEVICE_MODEL_TYPE_LR_MS_SENSOR,
    HUMIDITY_INPUTS_TYPE,
    SENSOR_MODULE
} from "../../store/constants";

import NextWeekModal from "./NextWeekModal";
import ProgrammingLastDayModal from "./ProgrammingLastDayModal";
import "./style.scss";
import {checkTimeInHours} from "../../../main/utils";
import WaterBudgetModal from "./WaterBudgetModal";
import {HUNTER_SOURCE, STATE} from "../../../main/store/constants";
import ShowHumidityAdjustModal from "./ShowHumidityAdjustModal";

const ProgrammingCircuit: any = (props: any) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [showNextWeek, setShowNextWeek] = useState(false);
    const [showWaterBudget, setShowWaterBudget] = useState(false);
    const [showHumidityAdjust, setShowHumidityAdjust] = useState(false);
    const [adjustModule, setAdjustModule] = useState(null);
    const hasHumidityInput = (module:any) =>
    {
        const deviceModelType = module.deviceModel.type;
        if(DEVICE_MODEL_TYPE_LR_MS_SENSOR.includes(deviceModelType) )
        {
            let  hasHumidity = false;
            if(module.components && module.components.length > 0)
            {
                module.components.forEach((component:any)=>{

                    if(HUMIDITY_INPUTS_TYPE.includes(component.deviceModel.type))
                    {
                        hasHumidity = true;
                        return true;
                    }
                });
            }

            return hasHumidity;
        }
        return true;
    }
    const toMapHandle = (circuit: any) => {
        const selectedLocationReference = {
            locationReference: circuit.locationReference,
            filterWateringEquipment: false,
        };
        props.filterMapLocationReference(selectedLocationReference);
        history.push(routesPath.map);
    };
    const {circuit, hasCalendar, measureDetailDayByStep, setMeasureDetailDayByStep, calendars, updateCalendar, forecast} = props;

    const circuitHasHumidity = () => {
        let hasHumidity = false;
        circuit.modules.forEach((module: any) => {
            const categoryLabel = module.deviceModel.category ? module.deviceModel.category.label : "";
            if (categoryLabel === SENSOR_MODULE &&  hasHumidityInput(module)) {
                hasHumidity = true;
            }
        });
        return hasHumidity;
    }

    const nbrNotNullState = circuit.modules.filter(function(item:any){
        return (item.state !== null);
    }).length;
    const nbrNotOffState = circuit.modules.filter(function(item:any){
        return (item.state === STATE.OFF);
    }).length;
    let circuitState: string = STATE.ON;
    if (nbrNotNullState === nbrNotOffState && nbrNotOffState > 0) {
        circuitState = STATE.OFF;
    } else if (nbrNotOffState > 0) {
        circuitState = "partial";
    }




    const getCircuitLastMeasure = ()=>{
        let measure = "--";
        props.lastMeasures.forEach((lastMeasure:any)=>{
            if (lastMeasure._id.circuitId === circuit.id && checkTimeInHours(lastMeasure.date) <= 24)
            {
                measure = `${lastMeasure.value.toFixed(2)}%`;
            }
        });
        return measure;
    }

    const getModuleWaterBudget = (moduleId: string) => {

        const moduleWaterBudget = waterBudgets()[moduleId];
        if (moduleWaterBudget) {
            if (circuit.source === HUNTER_SOURCE) {
                return moduleWaterBudget[0] ? `${moduleWaterBudget[0]}%` : '';
            }
            const sum = moduleWaterBudget.reduce((a: any, b: any) => (parseInt(a) || 0) + (parseInt(b) || 0), 0);
            const number = moduleWaterBudget.length;

            return number > 0 ? `${(sum / number).toFixed(2)}%` : '';
        }

        return '';
    }

    const getCircuitWaterBudget = () => {
        let sum = 0;
        let number = 0;
        const circuitWaterBudgets = waterBudgets();
        if(circuit.source === HUNTER_SOURCE)
        {
            const programs:any = Object.values(circuitWaterBudgets)[0];
            return programs?`${programs[0]}%`:'';
        }

        let minValue: any = null;
        Object.values(circuitWaterBudgets).forEach((moduleWaterBudget: any) => {
            const sumModule = moduleWaterBudget.reduce((a: any, b: any) => (parseInt(a) || 0) + (parseInt(b) || 0), 0);
            const numberModule = moduleWaterBudget.length;
            if (numberModule > 0) {
                 const value = sumModule / numberModule;
                minValue = (minValue === null || (minValue > value)) ? value : minValue;
                number++;
            }
        });
        return number > 0 ? `${(minValue).toFixed(2)}%` : '';
    }

    const waterBudgets = ()=>
    {
        let waterBudgets:any = [];
        props.programs.forEach((program:any)=> {
            if (program.circuitId === circuit.id  && program.waterBudgets) {
                if(!waterBudgets[program.moduleId])
                {
                    waterBudgets[program.moduleId] = [];
                    waterBudgets[program.moduleId].push(program.waterBudgets);
                }else
                {
                    waterBudgets[program.moduleId].push(program.waterBudgets);
                }
            }
        });
        return waterBudgets;
    }

    const programmeHandle = (module: any, isActuator: any) => {
        props.selectCircuit(props.circuit);
        props.selectModule(module);
        if (props.circuit.source === HUNTER_SOURCE && DEVICE_MODEL_TYPE_LR_MS_SENSOR.includes(module.deviceModel.type)) {
            showHumidityAdjustHandle(circuit, module)
        }
        else if(props.circuit.source === HUNTER_SOURCE && module.source === HUNTER_SOURCE)
        {
            showHumidityAdjustHandle(circuit, module);
            history.push(routes(module.id).programmingHunter.config);
        }else {
            history.push(routes(module.id).programming.config);
        }

    };

    const showHumidityAdjustHandle = (circuit:any , module:any)=>
    {
        setAdjustModule(module);
        setShowHumidityAdjust(true);
    }

    const renderHeader = () => {


        return (
          <>
              <SzModal title={""} show={showWaterBudget} handleClose={() => setShowWaterBudget(false)} size={"xl"}>
                  <WaterBudgetModal
                      circuit={circuit}
                      closeHandle={() => setShowWaterBudget(false)}
                  />
              </SzModal>
              <SzModal title={""} show={showHumidityAdjust} handleClose={() => setShowHumidityAdjust(false)} size={"lg"}>
                  <ShowHumidityAdjustModal
                      circuit={circuit}
                      module={adjustModule}
                      closeHandle={() => setShowHumidityAdjust(false)}
                  />
              </SzModal>
              <SzModal title={""} show={showNextWeek} handleClose={() => setShowNextWeek(false)} size={"xl"}>
                  <NextWeekModal
                      circuit={circuit}
                      calendars={calendars}
                      closeHandle={() => setShow(false)}
                      updateCalendar={updateCalendar}
                      forecast={forecast}
                      initHandle={props.initHandle}
                  />
              </SzModal>
              <SzModal title={""} show={show} handleClose={() => setShow(false)} size={"xl"}>
                  <ProgrammingLastDayModal
                      circuit={circuit}
                      closeHandle={() => setShow(false)}
                      setMeasureDetailDayByStep={setMeasureDetailDayByStep}
                      measureDetailDayByStep={measureDetailDayByStep}
                      circuitHasHumidity={circuitHasHumidity()}
                  />
              </SzModal>
            <SzBox className="row m-0 p-0 pl-3" tag="div">
              <div className="col-md-7  col-xl-7 col-sm-12 row p-0 m-0 border-right">
                <div className="col-md-4 col-xl-3 col-sm-12">
                  <SzTypographie className="m-0" color="inactive" variant="caption">
                    {circuit.name}
                  </SzTypographie>
                  <SzTypographie>{circuit.sigId}</SzTypographie>
                </div>
                <div className="col-2  d-none d-xl-block">
                  <div title={t("equipment:list.btn_detail_map")}>
                    <SzButton onClick={() => toMapHandle(circuit)} variant="secondary" className="m-0" icon="maps-pin-1" alignIcon="right"></SzButton>
                  </div>
                </div>
                <div className="col-md-4 col-xl-3 col-sm-12">
                  <SzTypographie className="m-0" color="inactive" variant="caption">
                      {circuit.locationReference ? circuit.locationReference.name : ''}
                  </SzTypographie>
                  <SzTypographie>{circuit.locationReference ? circuit.locationReference.sigId :''} </SzTypographie>
                </div>
                  <div className="col-md-4 col-xl-3 col-sm-12">
                      {circuit.mode &&
                      <>
                          <SzTypographie className="m-0" color="inactive" variant="caption">
                              {t("programming:mode.title")}
                          </SzTypographie>
                          <SzTypographie>{t(`programming:mode.${circuit.mode}`)}</SzTypographie>
                      </>
                      }
                  </div>
              </div>
              <div className="col-md-5  col-xl-5 col-sm-12 p-0 m-0 pl-3 row">
                <div className="pl-4 pr-4 d-none d-xl-block">
                  <div className={`info-status-circuit info-status status-${circuitState}`}></div>
                </div>
                <div className={`col-md-4  col-xl-2 col-sm-12 p-0 m-0 pt-3 ml-2`}>
                  <SzTypographie >{t(`watering_${circuitState}`)} </SzTypographie>
                </div>
                <div className="col-md-4  col-xl-2 p-0 m-0 col-sm-12 p-0 m-0 pt-3">
                  <SzTypographie >{getCircuitWaterBudget()} </SzTypographie>
                </div>

                  <div className=" col-xl-2 p-0 m-0 d-none d-xl-block">
                      {circuit.source === HUNTER_SOURCE &&
                      <div title={t("programming:btn.water_budget")}>
                          <SzButton alignIcon="right"
                                    onClick={() => setShowWaterBudget(true)}
                                    variant="secondary"
                                    className="p-1"

                                    icon="cog">

                          </SzButton>
                      </div>
                      }
                </div>
                <div title={t("programming:btn.next_week")} className="col-xl-2 p-0 m-0 d-none d-xl-block">
                  <SzButton alignIcon="right"
                            onClick={() => setShowNextWeek(true)}
                            variant="secondary"
                            className="p-1"
                             icon="calendar-3">

                  </SzButton>
                </div>
                <div title={t("programming:btn.measure_last_day")} className="col-xl-2 p-0 m-0 d-none d-xl-block">
                  <SzButton
                    alignIcon="right"
                    className="p-1"
                    variant="secondary"
                    onClick={() => setShow(true)}
                    icon="analytics-bars-3d"
                  >

                  </SzButton>
                </div>
              </div>
            </SzBox>
          </>
        );
    };
    const showModules = (modules: any, showActuator:boolean) =>
    {
        return modules.map((module: any, index: number) => {
                const categoryLabel = module.deviceModel.category ? module.deviceModel.category.label : "";
                const isActuator = categoryLabel === ACTUATOR_MODULE;
                return  (isActuator ===  showActuator) && <SzBox className={`row mt-2  m-0 p-1 ${index % 2 === 0 ? "module-circuit-line-pair" : ""}`} tag="div" key={module.id}>
                    <div className="col-md-2 col-sm-12 ">
                        <SzTypographie>
                            {module.name}
                        </SzTypographie>
                    </div>
                    <div className="col-md-1 col-sm-12 ">
                        <div>
                            {t("programming:com")}
                            <span className={`info-status status-${module.status.toLowerCase()} mt-2 ml-2`}/>

                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12">
                        <SzTypographie className="programming-device-name">
                            {module.deviceModel.name}
                        </SzTypographie>
                    </div>
                    <div className="pl-1">
                        {module.state && circuit.source !== HUNTER_SOURCE &&
                            <div className={`info-status info-status-module status-${module.state}`}/>}
                        {module.state && circuit.source === HUNTER_SOURCE
                            && (
                                ((module.state === STATE.ON || (module.state === STATE.OFF && module.offDays === 0)) &&
                                    <div className={`info-status info-status-module status-${module.state}`}/>) ||
                                (module.state === STATE.OFF && module.offDays > 0 &&
                                    <div className={`info-status info-status-module status-down`}/>)
                            )
                        }
                    </div>
                    <div className="col-md-2 col-sm-12 m-0 p-0 ml-3">
                        {module.state && circuit.source !== HUNTER_SOURCE &&
                            <SzTypographie className="ml-2">{t(`watering_${module.state}`)} </SzTypographie>
                        }
                        {module.state === STATE.ON && circuit.source === HUNTER_SOURCE &&
                            <SzTypographie className="ml-2">{t("watering_active")}</SzTypographie>}

                        {module.state === STATE.OFF && circuit.source === HUNTER_SOURCE
                            && (
                                (module.offDays === 0 &&
                                    <SzTypographie className="ml-2">{t("watering_off_permanent")}</SzTypographie>) ||
                                (module.offDays > 0 &&
                                    <SzTypographie className="ml-2">{t("watering_off_during_x_days", {offDays: module.offDays})}</SzTypographie>)
                            )
                        }
                    </div>

                    <div className="col-md-2 col-sm-12  m-0 p-0 pl-2">
                        <SzTypographie >{getModuleWaterBudget(module.id) && t(`programming:waterBudget`)}{getModuleWaterBudget(module.id)} </SzTypographie>
                        {(categoryLabel === SENSOR_MODULE && hasHumidityInput(module)) && <SzTypographie >{t(`programming:humidity`)}{getCircuitLastMeasure()} </SzTypographie>}
                    </div>

                    <div className="col-lg-2 col-md-3 col-sm-12 text-md-right">
                        <div className={`${!(categoryLabel === ACTUATOR_MODULE || module.source === HUNTER_SOURCE) && "d-none d-sm-block"}`}>
                            <SzButton variant="secondary" className="m-0 mb-3 btn-programming" icon={categoryLabel === ACTUATOR_MODULE ? "time-clock-hand-1" : "cog"}
                                      onClick={() => programmeHandle(module, categoryLabel === ACTUATOR_MODULE )}
                                      alignIcon="right">{t((categoryLabel === ACTUATOR_MODULE || module.source === HUNTER_SOURCE) ? "programming:btn.programme" : "programming:btn.setting")}</SzButton>
                        </div>

                        {module.source === HUNTER_SOURCE &&
                            <SzButton variant="secondary"
                                      onClick={() => {
                                          history.push(routes(module.id).programmingMobileHunter.config);
                                      }}
                                      className="m-0 btn-programming d-block d-xl-none">{t("programming:btn.manual_watering")}</SzButton>
                        }
                    </div>
                </SzBox>;
            },
        );
    };
    const renderBody = () => {
        const modules = circuit.modules.sort((firstModule: any, secModule: any) => (firstModule.name > secModule.name) ? 1 : -1);

        return <div className="p-1">
            {
                // show programateurs
                showModules(modules, true)
            }
            {
                // show others modules
                showModules(modules, false)
            }
        </div>;
    };
    return (<>

        <div className={`m-2  w-100 circuit-box circuit-${circuitState}`}>
            <SzAccordion.SzCollapse
                header={renderHeader()}
                body={renderBody()}
                footer={<></>}
                eventKey={props.index}
                onClick={() => props.openCircuitHandle(props.index)}
            />
        </div>
    </>);
};

export default ProgrammingCircuit;
