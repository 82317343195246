import { Constants } from "../constants";
export default {
  setCircuitsList(payload: any) {
    return {
      payload,
      type: Constants.SET_LIST_CIRCUIT,
    };
  },
  filterLocationReference(payload: any) {
    return {
      payload,
      type: Constants.SET_CIRCUIT_LOCATION_REFERENCE_FILTER,
    };
  },
  setCalendarList(payload: string) {
    return {
      payload,
      type: Constants.SET_CIRCUIT_CALENDAR,
    };
  },
  setProgramList(payload: string) {
    return {
      payload,
      type: Constants.SET_CIRCUIT_PROGRAM,
    };
  },
  setSelectedCircuit(payload: any) {
    return {
      payload,
      type: Constants.SET_SELECTED_CIRCUIT,
    };
  },
  setSelectedModule(payload: any) {
    return {
      payload,
      type: Constants.SET_SELECTED_MODULE,
    };
  },
  setLastMeasures(payload: any) {
    return {
      payload,
      type: Constants.SET_LAST_MEASURES,
    };
  }
};
