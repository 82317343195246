import instanceRequest, {Request} from "./Request";
import {
    PAGINATION_HEADERS,
} from "../store/constants";

class EventService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getEvents(
        page = 1,
        filters = {equipments: []},
        itemsPerPage = 20,
    ) {
        let filtersStatusQuery = "";
        if (filters.equipments) {
            filtersStatusQuery = this.formatEquipmentFilters(filters.equipments);
        }
        const url = `/api/event?currentPage=${page}&itemsPerPage=${itemsPerPage}&${filtersStatusQuery}`;
        return this.axiosService.get(url).then((response) => {
            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data,
            };
        });
    }

    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }

    public getLatestEvents(response: any) {
        const body = { equipments: response.data.map((item: any) => item.id) };
        const url = `/api/event/latest`;
        return this.axiosService.post(url, body, false).then((response) => {
          return {
            data: response.data,
          };
        });
    }
    private formatEquipmentFilters(equipments = []) {
        let filterQuery = "";
        equipments.forEach((equipment: string) => {
            filterQuery = filterQuery ? `${filterQuery}&equipment[]=${equipment}` : `equipment[]=${equipment}`;
        });

        return filterQuery;
    }
}

export default new EventService(instanceRequest);
