import {Constants, ERROR} from "../constants";

const initialState: any = {
    forecast: [],
    yesterdayPrecipitation: 0
};

const meteoReducers = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.SET_METEO_FORECAST:
      return {...state, forecast: action.payload};
    case Constants.SET_METEO_YESTERDAY_PRECIPITATION:
      return {...state, yesterdayPrecipitation: action.payload};
    default:
      return state;
  }
};

export default meteoReducers;
