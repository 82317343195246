import instanceRequest, {Request} from "./Request";
import {MAX_NBR_ALL_PI, PAGINATION_HEADERS} from "../store/constants";

class LocationReferenceService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getLocationReferences(query = "", page = 1, itemsPerPage = MAX_NBR_ALL_PI, showLoading = false, isAffectedToCircuit = 0, circuitFunctionalType:string|null = null,withPlant = 0) {

        const queryParam = (query !== "") ? `query=${query}&` : "";
        const withPlantParam = `&withPlants=${withPlant}`
        const circuitFunctionalTypeParam = (circuitFunctionalType) ? `&circuitFunctionalType=${circuitFunctionalType}` : "";

        const url = `/api/location_reference?${queryParam}currentPage=${page}&itemsPerPage=${itemsPerPage}&isAffectedToCircuit=${isAffectedToCircuit}${circuitFunctionalTypeParam}${withPlantParam}`;
        return this.axiosService.get(url, false, false, showLoading).then((response) => {
            return response;
        });
    }

    public getLocationReferencesWithPagination (query = "", page = 1, itemsPerPage = MAX_NBR_ALL_PI, showLoading = false, isAffectedToCircuit = 0, circuitFunctionalType: string | null = null, withPlant = 0) {
       return  this.getLocationReferences(query, page, itemsPerPage, showLoading, isAffectedToCircuit, circuitFunctionalType, withPlant).then((response: any) => {

            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data,
            }
        });
    }


    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }

}

export default new LocationReferenceService(instanceRequest);
