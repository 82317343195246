import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import DetailEquipment from "../components/Equipement/DetailEquipment";
import EquipmentService from "../services/EquipmentService";
import { equipmentAction } from "../store/actions";

class ViewEquipmentManager extends React.Component<any> {
  public componentDidMount() {
    this.setEquipmentDetail(this.props.idEquipment);
  }

  public setEquipmentDetail(idEquipment: string) {
    EquipmentService.getEquipment(idEquipment).then((response) => {
      this.props.setEquipment(response);
    });
  }

  public render() {
    const { equipment } = this.props;
    return <>{equipment && <DetailEquipment equipment={equipment} />}</>;
  }
}

const mapDispatchToProps = {
  setEquipment: equipmentAction.setEquipment,
};

const mapStateToProps = (state: any, props: any) => {
    return {
      equipment: state.equipment.detailEquipment,
      idEquipment: props.idEquipment,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ViewEquipmentManager));
