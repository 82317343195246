import instanceRequest, {Request} from "../../main/services/Request";
import {
    PAGINATION_HEADERS,
    STATUS_INDEX_DEFECT,
    STATUS_INDEX_NORMAL,
    STATUS_INDEX_UNAVAILABLE,
    STATUS_LIST,
} from "../../main/store/constants";


class PlantService {
    private axiosService: Request;
    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getPlants(
        page = 1,
        filters:{ locationReferences: {id:string,name:string, geojson:{coordinates:string[]}} []} = {locationReferences:[]},
        sortField = "quantity",
        sortDirection = "desc",
        itemsPerPage = 20,
        functionalType="suivi_hydrique",
        query=""
    ) {
        const body = {
            locationReference: filters.locationReferences ? filters.locationReferences.map((item: any) => item.id) : [],
        };

        let functionalTypeQuery ="";

        if(functionalType)
        {
            functionalTypeQuery = `&functionalType=${functionalType}`;
        }
        let serachQuery = ''
        if(query)
        {
            serachQuery = `&query=${query}`;
        }

        // all filters are deactivated
        const url = `/api/plant/list?currentPage=${page}&itemsPerPage=${itemsPerPage}&sortKey=${sortField}&sortDir=${sortDirection}${functionalTypeQuery}${serachQuery}`;
        return this.axiosService.post(url, body, false).then((response) => {

            return {
                pagination: this.getPaginationInfo(response.headers),
                data: response.data.map((plant:any) => plant[0]),
            };
        });
    }

    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }
}

export default new PlantService(instanceRequest);
