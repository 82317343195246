import React, {useEffect} from "react";
import {useHistory} from "react-router";
import Breadcrumb from "../../components/Utils/Breadcrumb";
import {routesPath} from "../../../routes";
import HydriqueTabNavigation from "../../components/Utils/HydriqueTabNavigation";
import ListHydriqueEquipmentManager from "../../../hydrique/manager/ListHydriqueEquipmentManager";
import {config} from "../../../config";
import ReactGA from "react-ga";

const HydriqueEquipment = () => {
    const history = useHistory();
    useEffect(() => {
        if (config.googleAnalyticsId && history.location) {
            ReactGA.initialize(config.googleAnalyticsId);
            ReactGA.set({ page: history.location.pathname }); // Update the user's current page
            ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
        }
    },[history.location.pathname]);
    const selectTabHandle = (tab: any) => {
        history.push(tab)
    }
    return (
        <div className="m-2 w-100 overflow-auto">
            <div className="row p-0 m-0">
                <div className="col-5 p-0 m-0">
                    <Breadcrumb elements={[{text: "hydriqueEquipment"}]}/>
                </div>
                <div className="col-7">
                    <HydriqueTabNavigation route={routesPath.hydriqueEquipment} selectTabHandle={selectTabHandle}/>
                </div>
            </div>
            <ListHydriqueEquipmentManager history={history}/>
        </div>);
};

export default HydriqueEquipment;
