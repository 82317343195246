import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzButton, SzInput, SzTypographie } from "@suezenv/react-theme-components";
import { formatServerDate, getCentroidMultipolygon, getCoordinateCenter } from "../../main/utils";
import AlarmService from "../../watering/services/AlarmService";
import EquipmentService from "../../watering/services/EquipmentService";
import LocationReferenceService from "../../main/services/LocationReferenceService";
import ProgrammingService from "../../watering/services/ProgrammingService";
import { alarmAction, equipmentAction, programmingAction } from "../../watering/store/actions";
import { equipmentAction as hydriqueEquipmentAction } from "../../hydrique/store/actions";
import {
  HYDRIQUE_FUNCTIONAL_TYPE,
  MAX_NBR_ALL_PI,
  RAISED_STATUS,
  WATERING_FUNCTIONAL_TYPE,
} from "../../main/store/constants";
import Filter from "../components/Filter";
import Map from "../components/map";
import mapAction from "../store/actions/mapAction";
import PlantService from "../../hydrique/services/PlantService";
import hydriqueMonitoringAction from "../../hydrique/store/actions/hydriqueMonitoringAction";
import MeasureService from "../../hydrique/services/MeasureService";

class MapManager extends React.Component<any> {
  public initialState: any = {
    center: { lat: "47.4667", lng: "-0.55" },
    zoom: 13,
    allFilterActivated: false,
    mapFilter: {
      circuitOn: true,
      circuitOff: true,
      circuitPartial: true,
      showEquipments: true,
      showCircuitRelay: true,
      showRelay: true,
      showSensor: true,
      showActuator: true,
      showMeter: true,
      showNormalEquipment: true,
      showNormalHydriqueEquipment: true,
      showUnavailableEquipment: true,
      showDefectEquipment: true,
      showDefectHydriqueEquipment: true,
      isWateringActivated: true,
      isHydriqueActivated: true,
      isHydriquePlantActivated: true,
      isAlarmActivated: false,
    },
    plantTab: "tab-plant-humidity",
    filter: {
      filterLocationReference: true,
    },
    search: this.props.filterQuery,
    plants: [],
    hydriqueMeasures: [],
    autoZoom: true,
  };
  public state: any = this.initialState;

  public componentDidMount() {
    this.init(this.props.functionalType === HYDRIQUE_FUNCTIONAL_TYPE);
  }

  public init(showPlants = true) {
    const { filterLocationReference, filterMapLocationReference, selectMapLocationReference } = this.props;
    const selectedLocationReference = filterLocationReference.locationReference;
    if (selectedLocationReference) {
      this.setState({
        mapFilter: {
          ...this.state.mapFilter,
          showEquipments: filterLocationReference.filterWateringEquipment,
        },
      });
      selectMapLocationReference(null);
      this.setData(selectedLocationReference.sigId || selectedLocationReference.name);
      filterMapLocationReference({ locationReference: null, filterWateringEquipment: false });
    } else {
      selectMapLocationReference(null);
      this.setData(this.state.search, showPlants);
    }
  }

  public setPlantTab(tab: string) {
    this.setState({ plantTab: tab, autoZoom: false });
  }
  public setData(query = "", showPlants = true) {
    const { selectMapLocationReference, isAffectedToCircuit, functionalType } = this.props;
    LocationReferenceService.getLocationReferences(
      query,
      1,
      MAX_NBR_ALL_PI,
      true,
      isAffectedToCircuit,
      functionalType,
    ).then((response: any) => {
      const { data } = response;
      this.setListPlants(data);
      if (data.length === 1) {
        this.props.setLocationReferences({ ids: data, query });
        this.selectLocationReferenceHandle(data[0]);
        if (functionalType === HYDRIQUE_FUNCTIONAL_TYPE || !functionalType) {
          this.setListHydriqueMeasures(data[0]);
        }
      } else {
        this.props.setLocationReferences({ ids: data, query });
        selectMapLocationReference(null);
        this.setState({
          ...this.initialState,
          search: this.state.search,
          mapFilter: {
            ...this.state.mapFilter,
            isHydriquePlantActivated: showPlants,
          },
        });
        this.setListAlarms(data);
        this.setListHydriqueMeasures(null);
      }
    });
  }

  public selectLocationReferenceHandle = (locationReference: any) => {
    this.setListCircuits(locationReference);
    this.setListEquipment(locationReference);
    this.setListAlarms([locationReference]);
    this.setListPlants([locationReference]);
    this.setListHydriqueMeasures(locationReference);
  };

  public setListAlarms(locationReferences: any = null) {
    const { selectMapLocationReference, setMapAlarms, functionalType } = this.props;
    const raisedAlarm: any = RAISED_STATUS;
    const filters: any = {
      status: raisedAlarm,
      locationReferences: locationReferences,
    };
    if (functionalType) {
      filters.functionalType = functionalType;
    }
    AlarmService.getAlarms(1, filters, "startAt", "desc", "all").then((response: any) => {
      if (locationReferences.length === 1) {
        selectMapLocationReference({
          ...this.props.selectedLocationReference,
          ...locationReferences[0],
          alarms: response.data,
        });
      }
      setMapAlarms(response.data);
    });
  }

  public setListEquipment(locationReference: any) {
    const { selectMapLocationReference } = this.props;
    const functionalType = this.props.functionalType || null;
    const filerLocationReferences: any = [];
    filerLocationReferences.push(locationReference);
    const { geojson } = locationReference;
    let { center } = this.state;
    if (geojson && geojson.type == "Polygon") {
      center = getCoordinateCenter(geojson.coordinates[0]);
    } else if (geojson && geojson.type == "MultiPolygon") {
      center = getCentroidMultipolygon(geojson.coordinates[0]);
    }
    const setList = (type: string) => {
      EquipmentService.getEquipments(
        1,
        {
          status: null,
          locationReferences: filerLocationReferences,
          equipment: [],
          query: ""
        },
        type,
      ).then((response: any) => {
        if (type === WATERING_FUNCTIONAL_TYPE) {
          selectMapLocationReference({
            ...this.props.selectedLocationReference,
            ...locationReference,
            equipments: response.data,
          });
        } else {
          selectMapLocationReference({
            ...this.props.selectedLocationReference,
            ...locationReference,
            hydriqueEquipments: response.data,
          });
        }
        this.setState({
          center: { lat: center[1], lng: center[0] },
          allFilterActivated: true,
        });
      });
    };

    if (functionalType === WATERING_FUNCTIONAL_TYPE || !functionalType) {
      setList(WATERING_FUNCTIONAL_TYPE);
    }

    if (functionalType === HYDRIQUE_FUNCTIONAL_TYPE || !functionalType) {
      setList(HYDRIQUE_FUNCTIONAL_TYPE);
    }
  }

  public setListHydriqueMeasures(locationReference: any = null) {
    const filerLocationReferences: any = [];
    if (locationReference) {
      filerLocationReferences.push(locationReference);
    }
    const functionalType = this.props.functionalType || null;
    if (functionalType === HYDRIQUE_FUNCTIONAL_TYPE || !functionalType) {
      const date = new Date();
      date.setHours(date.getHours() - 24);
      const startAt = formatServerDate(new Date(date));
      MeasureService.getMeasures(1, filerLocationReferences, startAt, null, 1).then((response) => {
        this.setState({ hydriqueMeasures: response });
      });
    }
  }

  public setListCircuits = (locationReference: any) => {
    const filerLocationReferences: any = [];
    const { selectMapLocationReference } = this.props;
    const { geojson } = locationReference;
    const functionalType = this.props.functionalType || null;
    if (geojson && (functionalType === WATERING_FUNCTIONAL_TYPE || functionalType === null)) {
      let center: any[];
      if (geojson && geojson.type == "Polygon") {
        center = getCoordinateCenter(geojson.coordinates[0]);
      } else if (geojson && geojson.type == "MultiPolygon") {
        center = getCentroidMultipolygon(geojson.coordinates[0]);
      }
      filerLocationReferences.push(locationReference);
      ProgrammingService.getCircuits(
        1,
        { locationReferences: filerLocationReferences, mode: null },
        functionalType,
      ).then((response: any) => {
        const circuits = response.data;
        const list: any = [];
        circuits.forEach((circuit: any) => {
          list[circuit.id] = circuit;
        });
        const now = new Date();
        if (circuits.length) {
          ProgrammingService.getCalendar(circuits, formatServerDate(now)).then((responseCalendar: any) => {
            const calendar = responseCalendar.data;
            calendar.forEach((item: any) => {
              list[item.circuitId].calendar = item;
            });
            selectMapLocationReference({
              ...this.props.selectedLocationReference,
              ...locationReference,
              circuits: list,
            });
            this.setState({
              center: { lat: center[1], lng: center[0] },
              allFilterActivated: true,
            });
          });
        } else {
          selectMapLocationReference({ ...this.props.selectedLocationReference, ...locationReference, circuits: [] });
          this.setState({
            center: { lat: center[1], lng: center[0] },
            allFilterActivated: true,
          });
        }
      });
    }
  };

  public setListPlants = (locationReferences: { id: string; name: string; geojson: { coordinates: string[] } }[]) => {
    const functionalType = this.props.functionalType || null;
    if (functionalType === HYDRIQUE_FUNCTIONAL_TYPE || functionalType === null) {
      PlantService.getPlants(1, { locationReferences }, "name", "desc", MAX_NBR_ALL_PI, functionalType).then(
        (response: any) => {
          const plants = response.data;
          const list: any = [];
          plants.forEach((plant: any) => {
            list[plant.id] = plant;
          });

          this.setState({ plants: list });
        },
      );
    }
  };

  public setMapFilter(subFilter: any) {
    this.setState({ mapFilter: { ...this.state.mapFilter, ...subFilter } });
  }

  public setFilter(filter: any) {
    this.setState({ filter: { ...this.state.filter, ...filter } });
  }

  public searchHandle() {
    const { search } = this.state;
    const showPlants = this.state.mapFilter.isHydriquePlantActivated;
    this.setState({
      ...this.initialState,
      search,
    });
    this.setData(search, showPlants);
  }

  public initHandle() {
    const showPlants = this.state.mapFilter.isHydriquePlantActivated;
    this.setState({ ...this.initialState, search: "" });
    this.setData("", showPlants);
  }

  public enterKeyHandle(event: any) {
    if (event.key === "Enter") {
      this.searchHandle();
    }
  }

  public clickAlarmHandle(filter:any) {
    this.props.alarmFilterLocationReference(filter);
  }

  public clickCircuitHandle(locationReferences: any) {
    this.props.circuitFilterLocationReference(locationReferences);
  }

  public clickEquipmentHandle(locationReferences: any) {
    this.props.equipmentFilterLocationReference(locationReferences);
  }

  public clickHydriqueEquipmentHandle(locationReferences: any) {
    this.props.hydriqueEquipmentFilterLocationReference(locationReferences);
  }

  public clickHydriqueMonitoringHandle(locationReferences: any) {
    this.props.hydriqueMonitoringFilterLocationReference(locationReferences);
  }

  public render() {
    const { allFilterActivated, mapFilter, filter } = this.state;
    const { selectedLocationReference, locationReferences, t, alarms, functionalType, filterQuery } = this.props;
    const showInitBtn = this.state.search || selectedLocationReference;
    return (
      <>
        <Map
          locationReferences={filter.filterLocationReference && !selectedLocationReference ? locationReferences : null}
          selectedLocationReference={selectedLocationReference}
          selectLocationReferenceHandle={this.selectLocationReferenceHandle.bind(this)}
          filter={mapFilter}
          className="float-left ml-5"
          zoom={this.state.zoom}
          center={this.state.center}
          clickAlarmHandle={this.clickAlarmHandle.bind(this)}
          clickCircuitHandle={this.clickCircuitHandle.bind(this)}
          clickEquipmentHandle={this.clickEquipmentHandle.bind(this)}
          clickHydriqueEquipmentHandle={this.clickHydriqueEquipmentHandle.bind(this)}
          clickHydriqueMonitoringHandle={this.clickHydriqueMonitoringHandle.bind(this)}
          alarms={alarms}
          functionalType={functionalType}
          hydriquePlants={this.state.plants}
          hydriqueMeasures={this.state.hydriqueMeasures}
          plantTab={this.state.plantTab}
          autoZoom={this.state.autoZoom}
        />
        <div className="float-left map-filter">
          <div className="row m-0 p-0 mt-2">
            <div className="col-7 pr-1 pl-1 p-0 m-0">
              <SzInput
                value={this.state.search}
                onKeyUp={this.enterKeyHandle.bind(this)}
                onChange={({ target }) => this.setState({ search: target.value })}
                placeholder={t("wateringMap:select_location_reference")}
              />
            </div>
            <div className="col-5 m-0 p-0">
              <SzButton
                className="p-0 m-0 search-map"
                onClick={() => this.searchHandle()}
                variant="secondary"
                icon="search"
              />

              {showInitBtn && (
                <SzButton
                  className="p-0 m-0 pl-1"
                  onClick={() => this.initHandle()}
                  variant="secondary"
                  icon="diagram-round"
                />
              )}
            </div>
            <SzTypographie variant="text" className={`pl-3 m-0 ${filterQuery ? "visible" : "invisible"}`}>
              {locationReferences.length ? `${locationReferences.length} ${t("pi_found_text")}` : t("no_pi_found")}
            </SzTypographie>
          </div>
          <Filter
            setMapFilter={this.setMapFilter.bind(this)}
            mapFilter={this.state.mapFilter}
            selectedLocationReference={selectedLocationReference}
            allFilterActivated={allFilterActivated}
            setFilter={this.setFilter.bind(this)}
            setListEquipment={this.setListEquipment.bind(this)}
            setListCircuits={this.setListCircuits.bind(this)}
            setListPlants={this.setListPlants.bind(this)}
            initHandle={this.initHandle.bind(this)}
            functionalType={functionalType}
            setPlantTabHandle={this.setPlantTab.bind(this)}
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setLocationReferences: mapAction.setLocationReferences,
  setMapAlarms: mapAction.setMapAlarms,
  filterMapLocationReference: mapAction.filterMapLocationReference,
  selectMapLocationReference: mapAction.selectMapLocationReference,
  alarmFilterLocationReference: alarmAction.filterLocationReference,
  equipmentFilterLocationReference: equipmentAction.filterLocationReference,
  hydriqueEquipmentFilterLocationReference: hydriqueEquipmentAction.filterLocationReference,
  hydriqueMonitoringFilterLocationReference: hydriqueMonitoringAction.filterHydrique,
  circuitFilterLocationReference: programmingAction.filterLocationReference,
};
const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    locationReferences: state.map.locationReferences,
    alarms: state.map.alarms,
    filterLocationReference: state.map.filterLocationReference,
    filterQuery: state.map.filters.query,
    selectedLocationReference: state.map.selectedLocationReference,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MapManager));
