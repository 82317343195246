import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { errorUserReducers, userReducer } from "../../../user/store/reducers";
import {
    alarmReducer,
    equipmentReducer,
    eventReducer,
    measureReducer,
    programmingReducer,

} from "../../../watering/store/reducers";

import {
    hydriqueEquipmentReducer, hydriqueEventReducer, hydriqueMonitoringReducer,

} from "../../../hydrique/store/reducers";
import errorReducer from "./errors";
import loadingReducers from "./loading";
import meteoReducers from "./meteo";
import LocationReferenceReducers from "./locationReference";
import {mapReducer} from "../../../map/store/reducers";

const rootReducer = (history: History) =>
  combineReducers({
    error: errorReducer,
    meteo: meteoReducers,
    errorUser: errorUserReducers,
    router: connectRouter(history),
    user: userReducer,
    equipment: equipmentReducer,
    hydriqueEquipment: hydriqueEquipmentReducer,
      hydriqueMonitoring: hydriqueMonitoringReducer,
    measure: measureReducer,
    programming: programmingReducer,
    event: eventReducer,
    hydriqueEvent: hydriqueEventReducer,
    alarm: alarmReducer,
    map: mapReducer,
    loading: loadingReducers,
    locationReference:LocationReferenceReducers
  });

export default rootReducer;
