import {Constants} from "../constants";

export default {
  setLocationReferences(data:any) {
    return {
      payload: data,
      type: Constants.SET_LOCATION_REFERENCE_LIST,
    };
  }
};
