import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SzButton, SzCheckbox, SzInput, SzModal, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import {TimeSelect} from "../../../main/components/Utils/TimeSelect";
import ProgrammingService from "../../services/ProgrammingService";
import {STATION_TYPE} from "../../store/constants";
import ListStationsModal from "./ListStationsModal";

const ProgramDetail: any = (props: any) => {
    const {t} = useTranslation();
    const {program, resetHandle, setDataChanged, module} = props;
    const [days, setDays] = useState([false, false, false, false, false, false, false]);
    const hourInitState: any = [];
    const minuteInitState: any = [];
    const programDurationsComponentsInitState: any = [];
    const programDurationsHoursInitState: any = [];
    const programDurationsMinutesInitState: any = [];
    const programDurationsIdsInitState: any = [];
    const [programHour, setProgramHour] = useState(hourInitState);
    const [programMinute, setProgramMinute] = useState(minuteInitState);
    const [showStations, setShowStations] = useState(false);
    const [programDurationsComponents, setProgramDurationsComponents] = useState(programDurationsComponentsInitState);
    const [programDurationsHours, setProgramDurationsHours] = useState(programDurationsHoursInitState);
    const [programDurationsMinutes, setProgramDurationsMinutes] = useState(programDurationsMinutesInitState);
    const [programDurationsIds, setProgramDurationsIds] = useState(programDurationsIdsInitState);

    const checkHandle = (element: any) => {
        const elementId = element.target.id;
        days[elementId.replace("program_day_", "")] = element.target.checked;

        setDays([...days]);
        setDataChanged(true);
    };

    useEffect(() => {

        initValue();

    }, [program]);

    const initValue = () => {
        const binaryDaysWatering = (program.daysWatering >>> 0).toString(2);

        let days = binaryDaysWatering.split("").map((value) => (value === "1"));
        if (days.length === 7) {
            setDays([...days]);
        } else {
            days = days.reverse();
            for (let i = days.length; i < 7; i++) {
                days[i] = false;
            }
            setDays([...(days.reverse())]);
        }
        const times = program.wateringAt ? program.wateringAt.split(",") : [];
        const programHour: any = [];
        const programMinute: any = [];
        setProgramHour([...programHour]);
        setProgramMinute([...programMinute]);

        times.forEach((time: string, index: number) => {
            const timeArray = time.split(":");
            programHour[index] = Number(timeArray[0]) < 10 ? `0${parseInt(timeArray[0])}` : parseInt(timeArray[0]) + "";
            programMinute[index] = Number(timeArray[1]) < 10 ? `0${parseInt(timeArray[1])}` : parseInt(timeArray[1]) + "";
            setProgramHour([...programHour]);
            setProgramMinute([...programMinute]);
        });
        initProgramDurations();
        setDataChanged(false);

    };

    const initProgramDurations = () => {
        const programDurationsComponents: any = [];
        const programDurationsHours: any = [];
        const programDurationsMinutes: any = [];
        const programDurationsIds: any = [];
        program.programDurations.forEach((programDuration: any) => {
            const nbrMinutes = programDuration.duration / 60;
            const hour: any = nbrMinutes / 60;
            const minute: any = nbrMinutes % 60;
            programDurationsComponents[programDuration.position] = programDuration.componentId;
            programDurationsHours[programDuration.position] = twoDigitFormat(hour);
            programDurationsMinutes[programDuration.position] = twoDigitFormat(minute);
            programDurationsIds[programDuration.position] = programDuration.id;
        });
        setProgramDurationsComponents(programDurationsComponents);
        setProgramDurationsHours(programDurationsHours);
        setProgramDurationsMinutes(programDurationsMinutes);
        setProgramDurationsIds(programDurationsIds);
    };

    const twoDigitFormat = (n: any) => {
        return parseInt(n) < 10 ? `0${parseInt(n)}` : parseInt(n) + "";
    };

    const getComponentOption = (componentId: string) => {
        let componentOption: any = null;
        module.components.forEach((component: any) => {
            if (component.id === componentId) {
                componentOption = {
                    value: component.id,
                    label: `${component.externalId} - ${component.name}`,
                };
            }
        });
        return componentOption;
    };

    const getComponentsOptions = () => {

        const options: any = [];
        const listComponent = module.components.sort(function(componentA: any, componentB: any) {
            return componentA.externalId - componentB.externalId;
        });

        listComponent.forEach((component: any) => {
            if (component.deviceModel && component.deviceModel.type === STATION_TYPE && component.status === 1) {
                options.push({value: component.id, label: `${component.externalId} - ${component.name}`});
            }
        });

        return options;
    };

    const saveProgramHandle = () => {
        let watering = "";
        programHour.forEach((hour: string, index: number) => {
            watering += `${hour}:${programMinute[index]},`;
        });

        let daysWatering = "";

        days.forEach((day) => {
            daysWatering += day ? "1" : "0";
        });

        const programDurations: any = [];
        programDurationsComponents.forEach((componentId: string, index: number) => {
            programDurations.push({
                id: programDurationsIds[index] || undefined,
                componentId,
                duration: (parseInt(programDurationsHours[index]) * 60 * 60) + (parseInt(programDurationsMinutes[index]) * 60),
                position: index,
            });
        });

        const body = {
            wateringAt: watering.replace(/,+$/, ""),
            daysWatering: parseInt(daysWatering, 2),
            programDurations,
        };

        ProgrammingService.saveProgram(program.id, body).then(() => {
            resetHandle();
            setDataChanged(false);
        });
    };

    const getTotalProgramDurations = (): number => {
        let totalMinutes = 0;
        programDurationsHours.forEach((hour: string, index: number) => {
            totalMinutes += (parseInt(hour) * 60) + parseInt(programDurationsMinutes[index]);
        });
        return totalMinutes;
    };

    const timeConvert = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${twoDigitFormat(hours)} H : ${twoDigitFormat(minutes)} Min`;
    };

    return (
        <div>
            <SzModal title={""} show={showStations} handleClose={() => setShowStations(false)} size={"xl"}>
                <ListStationsModal
                    moduleId={program.moduleId}
                    closeHandle={() => setShowStations(false)}
                />
            </SzModal>
            <div className="pt-0 mt-3 row">
                <div className="col-12 col-sm-4">
                    <SzTypographie
                        className="">{t("programming:program.type")} : {t(`programming:program.type_${program.scheduleType | 0}`)}</SzTypographie>
                </div>

                <div className="col-12 col-sm-4">
                    <SzTypographie
                        className="">{t("programming:program.stack_mode")} : {t(`programming:program.stack_mode_${program.stackMode | 0}`)}</SzTypographie>
                </div>

                <div className="col-12 col-sm-4">
                    <SzTypographie
                        className="">{t("programming:program.waterBudget_title")} : {program.waterBudgets}</SzTypographie>
                </div>

            </div>
            <div className="d-flex flex-row ">
                <div className="col p-0">

                    <SzTypographie className="mb-1"
                                   color="inactive">{t("programming:program.days_watering_title")} </SzTypographie>
                    <div className="row d-flex flex-row ml-3">
                        <div className="p-3 mr-sm-5 col-6 col-sm-1">
                            <SzCheckbox
                                id="program_day_6"
                                name="checkbox"
                                label={t("programming:program.monday")}
                                checked={days[6]}
                                onChange={checkHandle}
                            />
                        </div>

                        <div className="p-3 mr-sm-5 col-6 col-sm-1">
                            <SzCheckbox
                                id="program_day_5"
                                name="checkbox"
                                label={t("programming:program.tuesday")}
                                checked={days[5]}
                                onChange={checkHandle}
                            />
                        </div>

                        <div className="p-3 mr-sm-5 col-6 col-sm-1">
                            <SzCheckbox
                                id="program_day_4"
                                name="checkbox"
                                label={t("programming:program.wednesday")}
                                checked={days[4]}
                                onChange={checkHandle}
                            />
                        </div>

                        <div className="p-3 mr-sm-5 col-6 col-sm-1">
                            <SzCheckbox
                                id="program_day_3"
                                name="checkbox"
                                label={t("programming:program.thursday")}
                                checked={days[3]}
                                onChange={checkHandle}
                            />
                        </div>

                        <div className="p-3 mr-sm-5 col-6 col-sm-1">
                            <SzCheckbox
                                id="program_day_2"
                                name="checkbox"
                                label={t("programming:program.friday")}
                                checked={days[2]}
                                onChange={checkHandle}
                            />
                        </div>

                        <div className="p-3 mr-sm-5 col-6 col-sm-1">
                            <SzCheckbox
                                id="program_day_1"
                                name="checkbox"
                                label={t("programming:program.saturday")}
                                checked={days[1]}
                                onChange={checkHandle}
                            />
                        </div>

                        <div className="p-3 mr-sm-5 col-6 col-sm-1">
                            <SzCheckbox
                                id="program_day_0"
                                name="checkbox"
                                label={t("programming:program.sunday")}
                                checked={days[0]}
                                onChange={checkHandle}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100 mt-4">
                <SzTypographie className="mb-1"
                               color="inactive">{t("programming:program.wateringAt_title")} </SzTypographie>
                <div className="row w-100 ml-md-3 mt-3 m-0">

                    {

                        programHour.map((hour: string, index: number) => {

                                return (<div className="col-lg-3  col-sm-6 border-right mt-md-3 mb-md-3" key={index}>
                                        <div className="row program-time-input pt-4 pr-3">
                                            <div className="col-10">
                                                <TimeSelect
                                                    required={true}
                                                    onChangeFirst={(value: string) => {
                                                        programHour[index] = value;
                                                        setProgramHour([...programHour]);
                                                        setDataChanged(true);
                                                    }}
                                                    onChangeSecond={(value: string) => {
                                                        programMinute[index] = value;
                                                        setProgramMinute([...programMinute]);
                                                        setDataChanged(true);
                                                    }}
                                                    firstName={`programHour_${index}`} firstValue={programHour[index]}
                                                    secondName={`programMinute_${index}`}
                                                    secondValue={programMinute[index]}
                                                />
                                            </div>
                                            <div className="col-2">
                                                <SzButton variant="secondary" className="delete-watering-at" icon="remove"
                                                          onClick={() => {
                                                              programHour.splice(index, 1);
                                                              programMinute.splice(index, 1);
                                                              setProgramHour([...programHour]);
                                                              setProgramMinute([...programMinute]);
                                                              setDataChanged(true);
                                                          }}/>
                                            </div>

                                        </div>
                                    </div>

                                );
                            },
                        )}
                    {(programHour.length < 10) &&
                    <div className="col-1">
                        <SzButton variant="tertiary" className="mr-3" icon="add-circle" onClick={() => {

                            programHour.push("00");
                            programMinute.push("00");
                            setProgramHour([...programHour]);
                            setProgramMinute([...programMinute]);
                            setDataChanged(true);
                        }}/>
                    </div>
                    }
                </div>
            </div>

            <div className="w-100 mt-4 program-duration">
                <div className="row mt-3">
                    <div className="col-12 col-sm-6">
                        <div className="row mt-3 mb-3 ">
                            <SzTypographie weight="bold" className="mb-1 col-6 d-none d-xl-block"
                                           color="inactive">{t("programming:program.duration_componentId")} </SzTypographie>
                            <SzTypographie weight="bold" className="mb-1 col-6 d-none d-xl-block"
                                           color="inactive">{t("programming:program.duration")} </SzTypographie>
                            <SzTypographie weight="bold" className="mb-1 col-12 d-block d-sm-none"
                                           color="inactive">{t("programming:program.duration_component_full_title")} </SzTypographie>
                        </div>

                        {
                            programDurationsComponents.map((programDuration: any, index: number) => {
                                    return (<div className="row mb-3" key={index}>
                                            <div className="col-1 mt-2 d-none d-xl-block">{index}</div>
                                            <div className="col-12 col-sm-5 mb-sm-0 mb-1">
                                                <SzSelect
                                                    id={"id-1"}
                                                    placeholder={"Sélectionner une station"}
                                                    options={getComponentsOptions()}
                                                    value={getComponentOption(programDurationsComponents[index])}
                                                    isSearchable={false}
                                                    isClearable
                                                    onChange={(option:{value:string}) => {
                                                        programDurationsComponents[index] = option.value;
                                                        setProgramDurationsComponents(programDurationsComponents);
                                                        setDataChanged(true);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <div className="row program-time-input  pr-3">
                                                    <div className="col-10">
                                                        <TimeSelect
                                                            required={true}
                                                            onChangeFirst={(value: string) => {
                                                                programDurationsHours[index] = value;
                                                                setProgramDurationsHours(programDurationsHours);
                                                                setDataChanged(true);
                                                            }}
                                                            onChangeSecond={(value: string) => {
                                                                programDurationsMinutes[index] = value;
                                                                setProgramDurationsMinutes(programDurationsMinutes);
                                                                setDataChanged(true);
                                                            }}
                                                            firstName={`programDurationHour_${index}`}
                                                            firstValue={programDurationsHours[index]}
                                                            secondName={`programDurationMinute_${index}`}
                                                            secondValue={programDurationsMinutes[index]}
                                                        />
                                                    </div>
                                                    <div className="col-2">
                                                        <SzButton variant="secondary" className="delete-duration-at"
                                                                  icon="remove"
                                                                  onClick={() => {
                                                                      programDurationsComponents.splice(index, 1);
                                                                      programDurationsMinutes.splice(index, 1);
                                                                      programDurationsHours.splice(index, 1);
                                                                      programDurationsIds.splice(index, 1);
                                                                      setProgramDurationsIds(programDurationsIds);
                                                                      setProgramDurationsHours(programDurationsHours);
                                                                      setProgramDurationsComponents(programDurationsComponents);
                                                                      setProgramDurationsMinutes(programDurationsMinutes);
                                                                      setDataChanged(true);
                                                                  }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                },
                            )}
                    </div>
                    <div className="col-12 col-sm-6 d-flex flex-column">
                        <div className="col-12 text-center">
                            {module.components.length > 0 &&
                                <SzButton variant="tertiary" className="mr-3" icon="add-circle" onClick={() => {
                                    if (programDurationsComponents.length > 0) {
                                        programDurationsHours.push("00");
                                        programDurationsMinutes.push("00");
                                        programDurationsComponents.push(module.components[0].id);
                                    } else {
                                        programDurationsHours[1] = "00";
                                        programDurationsMinutes[1] = "00";
                                        programDurationsComponents[1] = module.components[0].id;
                                    }
                                    setProgramDurationsHours(programDurationsHours);
                                    setProgramDurationsMinutes(programDurationsMinutes);
                                    setProgramDurationsComponents(programDurationsComponents);
                                    setDataChanged(true);
                                }}>
                                    {t("programming:program.add_station")}
                                </SzButton>
                            }
                        </div>
                        <div className="col-12 text-center d-none d-xl-block">
                            <SzButton className="mr-3" variant="tertiary" icon="task-list-text-1"
                                      onClick={() => setShowStations(true)}>{t("programming:station.show_list")}</SzButton>
                        </div>
                    </div>
                    {programDurationsComponents.length > 1 &&
                    <div className="col-12">
                        <SzTypographie weight="bold" color="inactive">{t("programming:program.duration_total")} : {timeConvert(getTotalProgramDurations())}</SzTypographie>
                        <SzTypographie weight="bold" color="inactive">{t("programming:program.duration_total_water_budget")} : {timeConvert(getTotalProgramDurations() * (program.waterBudgets / 100))}</SzTypographie>
                    </div>
                    }
                </div>

            </div>
            <div className="text-center mt-3">
                <SzButton className="mr-3"
                          variant="secondary"
                          onClick={initValue}
                >{t("programming:program.cancel")}</SzButton>
                <SzButton onClick={saveProgramHandle}
                          icon="send-email-1" alignIcon="right">{t("programming:program.save")}</SzButton>
            </div>
        </div>

    );
};

export default ProgramDetail;
