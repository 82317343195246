import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { config } from "../../config";
import Dashboard from "../components/Dashboard";

class UserAdminManager extends React.Component<any> {

  public render() {
    return (
      <>
        <Dashboard  url={`${config.apiUrl}/user-admin`} />
      </>
    );
  }
}

export default connect()(withTranslation()(UserAdminManager));
