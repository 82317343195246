import {Constants} from "../constants";

const initialState: any = {
    locationReferences: [],
    filters: {
        query: "",
    },
    filterLocationReference: {
        locationReference: null,
        filterWateringEquipment: false,
    },
    selectedLocationReference: null,
    alarms: []
};

const MapReducer = (
    state = initialState,
    action: any,
): any => {
    switch (action.type) {
        case Constants.SET_WATERING_MAP_LOCATION_REFERENCE:
            return {
                ...state,
                locationReferences: action.payload.ids,
                filters: {...state.filters, query: action.payload.query}
            };
        case Constants.SET_FILTER_MAP_LOCATION_REFERENCE:
            return {
                ...state,
                filterLocationReference: action.payload,
            };
        case Constants.SET_SELECT_MAP_LOCATION_REFERENCE:
            return {
                ...state,
                selectedLocationReference: action.payload,
            };
        case Constants.SET_MAP_ALARMS:
            return {
                ...state,
                alarms: action.payload,
            };
        default:
            return state;
    }
};

export default MapReducer;
