import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SzTypographie, SzList, SzIcon, SzChart} from "@suezenv/react-theme-components";
import "./style.scss";
import {routesPath} from "../../../routes";
import {formatDateTime, formatServerDate} from "../../../main/utils";

const PlantMeasure: any = (props: any) => {
    const {t} = useTranslation();
    const {plant, measures} = props;
    const {locationReference} = plant;
const getCategories = ()=>{
    let categories:any = [];
    Object.values(measures).forEach((item: any) => {
        const index = formatDateTime(item.startDate);
        categories.push(index);
    });

    return categories;
}
    const getData = () => {
        let measuresData: any = [];
        Object.values(measures).forEach((item: any) => {
            const index = formatDateTime(item.startDate);
            measuresData[index] = item.value !== null ? parseFloat((item.value * 100).toFixed(1)) : 0;

        });
        return Object.values(measuresData);
    };
    const getChartOptions = () => {
        return {
            title: {
                align: "left",
                useHTML: true,
                text: "",
            },
            chart: {
                type: "column",
                zoomType: 'x'

            },
            rangeSelector: {
                selected: 2,
            },
            xAxis: {
                title: {
                    useHTML: true,
                    text: "",
                },
                labels: {
                    useHTML: true,
                    format: "<h6 class='chart-text'>{value}</h6>",
                    style: {
                        color: "",
                    },
                    formatter: function() {
                        const {value}:any = this;
                        const date = value.split(" ");
                        return date[0];

                    }
                },
                categories:
                    getCategories(),

                crosshair: true,

            },
            yAxis: [
                {
                    min: 0,
                    title: {
                        text: "Humidité",
                        useHTML: true,
                        style: {
                            color: "84DFFF",
                        }
                    },
                    labels: {
                        format: "{value} %",
                        useHTML: true,
                        style: {
                            color: "84DFFF",
                        }
                    }
                }],
            exporting: false,
            plotOptions: {
                column: {
                    pointPadding: 0,
                    borderWidth: 0,
                    colors: ["#84DFFF"],
                },
            },
            legend: {
                enabled: false,
            },
            series: [
                {
                    type:'area',
                    name: "",
                    data: getData(),
                    pointWidth: 15,
                    pointPadding: 0,
                }
            ],
        };
    };

    const renderTitle = (name: string) => {
        return (
            < div className="row">
                <div className="col-1">
                    <i className="sz-icon-line analytics-bars-3d"/>
                </div>
                <div className="col-6">
                    <SzTypographie className="m-0" color="inactive" variant="caption">
                        {t(`hydriqueMonitoring:measure.title`)}
                    </SzTypographie>
                    <SzTypographie>{name ? name : "-"}</SzTypographie>
                </div>
            </div>
        );
    };

    return (<>
        {renderTitle(locationReference ? locationReference.name  +'/'+ plant.name:plant.name)}
            <SzChart options={getChartOptions()}/>
    </>);
};

export default PlantMeasure;
