import { Constants } from "../constants";
export default {
  setEquipmentEventsList(payload: any) {
    return {
      payload,
      type: Constants.SET_EQUIPMENT_EVENT_LIST,
    };
  },
  setLatestEvent(payload: any) {
    return {
      payload,
      type: Constants.SET_LATEST_EVENT_EQUIPMENT,
    };
  },
};
