declare global {
    interface Window {
        REACT_APP_API_HOST: string;
        REACT_APP_ENV: string;
        REACT_APP_MAP_PLAN_URL: string;
        REACT_APP_MAP_SATELLITE_URL: string;
        REACT_APP_ANALYTICS_KEY: string;
        REACT_APP_VERSION: string
    }
}

// use process.env in dev instead window
export const config = {
        apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
        appEnv: window.REACT_APP_ENV || process.env.REACT_APP_ENV || "development",
        mapPlanUrl: window.REACT_APP_MAP_PLAN_URL || process.env.REACT_APP_MAP_PLAN_URL,
        mapSatelliteUrl: window.REACT_APP_MAP_SATELLITE_URL || process.env.REACT_APP_MAP_SATELLITE_URL,
        googleAnalyticsId: window.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY,
        REACT_APP_VERSION: window.REACT_APP_VERSION || process.env.REACT_APP_VERSION,
};
