import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SzTypographie, SzList, SzIcon} from "@suezenv/react-theme-components";
import "./style.scss";
import {Constants} from "../../store/constants";

const PlantDetail: any = (props: any) => {
    const {t} = useTranslation();
    const {plant} = props;
    const inpUrl = plant.varietyINPN ? Constants.INPN_BASE_URL + plant.varietyINPN : '';
    const {varietyName, locationReference} = plant;
    const renderTitle = (name: string) => {
        return (
            < div className="row">
                <div className="col-1">
                    <i className="sz-icon-line plant-icon"/>
                </div>
                <div className="col-3">
                    <SzTypographie className="m-0" color="inactive" variant="caption">
                        {t(`hydriqueMonitoring:detail.title`)}
                    </SzTypographie>
                    <SzTypographie>{name ? name : "-"}</SzTypographie>
                </div>
            </div>
        );
    };

    const renderAttribute = (label: string, value: any, isNotText = false) => {
        return (
            <div className="col-6">
                <SzTypographie className="m-0" color="inactive" variant="caption">
                    {t(`hydriqueMonitoring:detail.${label}`)}
                </SzTypographie>
                {isNotText ? value ? value : "-" : <SzTypographie>{value ? value : "-"}</SzTypographie>}
            </div>
        );
    };

    return (<>
        {renderTitle(plant.name)}
        <div className="w-100 plant-detail row">

            <div className="col-10">
                <SzList>

                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("name", plant.name)}
                            {renderAttribute("externalId", plant.externalId)}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("externalName", plant.externalName || "-")}
                            {renderAttribute("locationReference", locationReference ? `${locationReference.name} / ${locationReference.sigId}` : "-")}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("latitude", plant.latitude || "-")}
                            {renderAttribute("longitude", plant.longitude || "-")}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("type", plant.nature ? plant.nature.label : "-")}
                            {renderAttribute("plantingYear", plant.plantingYear)}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("variety", varietyName)}
                            {renderAttribute("diagDate", plant.diagDate || '-')}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("force", plant.forceClass || '-')}
                            {renderAttribute("bloating", plant.bloating || '-')}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("rootBallDiameter", plant.rootBallDiameter || '-')}
                            {renderAttribute("slope", plant.slope || '-')}
                        </div>
                    </SzList.Item>

                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("basinDiameterCompliance", plant.basinDiameterCompliance? t("hydriqueMonitoring:detail.yes"): t('hydriqueMonitoring:detail.no'))}
                            {renderAttribute("basinHeightCompliance", plant.basinHeightCompliance ?  t("hydriqueMonitoring:detail.yes"): t('hydriqueMonitoring:detail.no'))}
                        </div>
                    </SzList.Item>
                    <SzList.Item>


                        <div className="row">
                            {renderAttribute("soilColletThickness", plant.soilColletThickness || '-')}
                            {renderAttribute("humid", plant.humid || '-')}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("soilNature", plant.soilNature ? plant.soilNature.label : '-')}
                            {renderAttribute("deepSoilNature", plant.deepSoilNature ? plant.deepSoilNature.label : '-')}
                        </div>
                    </SzList.Item>
                    <SzList.Item>
                        <div className="row">
                            {renderAttribute("rootBallSoilNature", plant.rootBallSoilNature ? plant.rootBallSoilNature.label : '-')}
                            {renderAttribute("rootItems", plant.rootItems || '-')}

                        </div>
                    </SzList.Item>

                </SzList>
            </div>
            <div className="col-2">
                <a className="btn sz-btn btn-primary-light" target="_blank"
                   href={inpUrl}>{t("hydriqueMonitoring:btn.INPN")}</a>

            </div>

        </div>
    </>);
};

export default PlantDetail;
