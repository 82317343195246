import React from "react";
import {useTranslation} from "react-i18next";
import {SzBox, SzButton, SzIcon, SzTypographie} from "@suezenv/react-theme-components";
import {formatDateWithDay, formatLocalDateTime} from "../../../main/utils";
import "./style.scss";
import {routesPath} from "../../../routes";
import {useHistory} from "react-router";

const AlarmAcquittedRow: any = (props: any) => {
    const {t} = useTranslation();
    const {alarm} = props;
    const history = useHistory();

    const toMapHandle = (alarm: any) => {
        const selectedLocationReference = {
            locationReference: {
                sigId: alarm.locationReferenceSigId,
                id: alarm.locationReferenceId,
                name: alarm.locationReferenceName,
            },
            filterWateringEquipment: false,
        };
        props.filterMapLocationReference(selectedLocationReference);
        history.push(routesPath.map);
    };

    return (
        <>
            <SzBox className="row alarm-info-box m-0 p-0 p-2 mb-2" tag="div">
                <div className="col-2 p-0 m-0 row border-right">
                    <SzIcon variant="line" icon="time-clock-circle-1"/>
                    <div className="col-8">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.detection")}</SzTypographie>
                        <SzTypographie>
                            {formatLocalDateTime(alarm.startAt)}
                        </SzTypographie>
                    </div>
                </div>
                <div className="col-2 p-0 m-0 row pl-4">
                    <div className="col-4">
                        <div title={t("alarm:btn.map")}>
                            <SzButton
                                variant="secondary"
                                icon="maps-pin-1"
                                alignIcon="right"
                                onClick={() => toMapHandle(alarm)}
                            ></SzButton>
                        </div>
                    </div>
                    <div className="col-6 pl-2">
                        <SzTypographie color="inactive" variant="caption" className="m-0">
                            {alarm.locationReferenceName}
                        </SzTypographie>
                        <SzTypographie>{alarm.locationReferenceSigId}</SzTypographie>
                    </div>
                </div>
                <div className="col-2 p-0 m-0 row border-right">
                    <div className="col-4">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.alarm")}</SzTypographie>
                        <SzTypographie>
                            {t(`alarm:types.${alarm.type}`)}
                        </SzTypographie>
                    </div>
                    <div className="col-8">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.equipment_serial_numbr")}</SzTypographie>
                        <SzTypographie>
                            {`${alarm.equipmentName} / ${alarm.equipmentSerialNumber}`}
                        </SzTypographie>
                    </div>
                </div>
                <div className="col-3 p-0 m-0 row  border-right">
                    <div className="col-8">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.acknowledge")}</SzTypographie>
                        <SzTypographie>
                            {alarm.ackUserName}
                        </SzTypographie>
                    </div>
                    <div className="col-4">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.alarm")}</SzTypographie>
                        <SzTypographie>
                            {formatDateWithDay(alarm.endAt)}
                        </SzTypographie>
                    </div>
                </div>
                <div className="col-3 p-0 m-0 row">
                    <div className="col-1 pl-2 m-0">
                    <div className={`info-status alarm-${alarm.ackReason}`}></div>
                    </div>
                    <div className="col-3 p-0 m-0">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.ackReason")}</SzTypographie>
                        <SzTypographie>
                            {t(`alarm:reason.${alarm.ackReason}`)}
                        </SzTypographie>
                    </div>
                    <div className="col-3 p-0 m-0">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.reason")}</SzTypographie>
                        <SzTypographie>
                            {alarm.reason ?t(`alarm:reason.${alarm.reason}`):""}
                        </SzTypographie>
                    </div>
                    <div className="col-5 p-0 m-0">
                        <SzTypographie color="inactive" variant="caption"
                                       className="m-0">{t("alarm:list.comment")}</SzTypographie>
                        <SzTypographie>
                            {alarm.comment}
                        </SzTypographie>
                    </div>
                </div>
            </SzBox>
        </>
    );
};

export default AlarmAcquittedRow;
