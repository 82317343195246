import {Constants, ERROR} from "../constants";

const initialState: any = {
  list: []
};

const LocationReferenceReducers = (
  state = initialState,
  action: any,
): any => {
  switch (action.type) {
    case Constants.SET_LOCATION_REFERENCE_LIST:
      return { list: action.payload};
    default:
      return state;
  }
};

export default LocationReferenceReducers;
