import cssVars from "css-vars-ponyfill";
import React from "react";

export const defaultTheme = {
  variables: {
    "primary-brand": "#004990",
    "brand-default": "#004990",
    "brand-dark": "#a76509",
    "brand-light": "#f7c885",
    "primary": "#004990",
  },
};

export const setActiveTheme = () => {
  cssVars({
    variables: defaultTheme.variables,
  });
};

export const activeTheme = defaultTheme;

export const ThemeContext = React.createContext(activeTheme);
