import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SzButton, SzIcon, SzList, SzPagination, SzTable, SzTypographie} from "@suezenv/react-theme-components";
import {formatDateTime, formatLocalDateTime} from "../../main/utils";
import EmptyRow from "../components/utils/EmptyRow";
import EventService from "../../main/services/EventService";
import {eventAction} from "../store/actions";
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT,
    PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT,
} from "../../main/store/constants";
import { displayValues } from "../store/tools";

class ViewEventManager extends React.Component<any> {
    public componentDidMount() {
        this.setData();
    }

    public setData(page = 1) {
        const filters: any = {equipments: [this.props.equipment.id]};
        EventService.getEvents(page, filters).then(
            (response: any) => {
                this.props.setEquipmentEventsList(response);
            },
        );
    }

    public getColumn(): any[] {
       return[
               this.columnJson("", "date", "event:list.date"),
               this.columnJson("", "type", "event:list.type"),
               this.columnJson("", "description", "event:list.description"),
           ];

    }

    public columnJson(
        classes: string,
        dataField: string,
        text: string,
    ) {
        const { t } = this.props;
        return {
            classes,
            dataField,
            text: t(text),
        };
    }

    public getListData(): any {
        const result: Array<{ date: string; type: string }> = [];
        Object.values(this.props.listEquipmentEvents.data).forEach((item: any) => {
            const formatDate = formatLocalDateTime(item.date);
            const description = <pre>{displayValues(item.values)}</pre>;
            const type = this.props.t(`event:types.${item.type}`);
            const row = { date: formatDate, type, description };
            result.push(row);
            });

        return result;
    }

    public render() {
        const {t, equipment, listEquipmentEvents} = this.props;
        return (
          <>
            <SzList>
              <SzList.Item>
                <div className="row">
                  <div className="col-1">
                    <SzIcon variant="line" icon="task-list-text-1" />
                  </div>
                  <div className="col-3">
                    <SzTypographie>{t("event:title")}</SzTypographie>
                    <SzTypographie className="m-0" color="inactive" variant="caption">
                      {t("event:title_2")}
                    </SzTypographie>
                  </div>
                </div>
              </SzList.Item>
              <SzList.Item>
                <div className="row p-0 m-0">
                  <div className="col-3">
                    <SzTypographie className="m-0">{equipment.name}</SzTypographie>
                    <SzTypographie color="inactive" variant="caption">
                      {equipment.serialNumber}
                    </SzTypographie>
                  </div>
                  <div className="col-3">
                    {equipment.relay && (
                      <>
                        <div className="row m-0 ">
                          <div className="col-2">
                            <SzIcon variant="line" icon="wifi-signal-2" />
                          </div>
                          <div className="col-10">
                            <SzTypographie className="m-0" color="inactive" variant="caption">
                              {t("equipment:list.relay_title")}
                            </SzTypographie>
                            <SzTypographie>{equipment.relay.name}</SzTypographie>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-3">
                    {equipment.locationReference ? (
                      <>
                        <SzTypographie color="inactive" variant="caption" className="m-0">
                          {equipment.locationReference.name}
                        </SzTypographie>
                        <SzTypographie className="m-0">{equipment.locationReference.sigId}</SzTypographie>
                      </>
                    ) : (
                      equipment.circuit &&
                      equipment.circuit.locationReference && (
                        <>
                          <SzTypographie color="inactive" variant="caption" className="m-0">
                            {equipment.circuit.locationReference.name}
                          </SzTypographie>
                          <SzTypographie className="m-0">{equipment.circuit.locationReference.sigId}</SzTypographie>
                        </>
                      )
                    )}
                  </div>
                    <div className="col-3 p-0">
                        {/*<SzButton variant="secondary" className="m-0" icon="maps-pin-1" alignIcon="right">
                      {t("event:btn_map")}
                    </SzButton>
                    */}
                    </div>
                </div>
              </SzList.Item>
              <SzList.Item>
                {this.getColumn().length && Object.keys(listEquipmentEvents.data).length > 0 ? (
                  <div className="list-table row m-2 mr-3">
                    <SzTable
                      rowClasses={(row: any) => {
                        return row.id === this.props.currentProjectId ? "selected-project" : "";
                      }}
                      columns={this.getColumn()}
                      data={this.getListData()}
                      keyField="id"
                      className="table-striped"
                    />
                    {listEquipmentEvents && listEquipmentEvents.pagination && (
                      <div className="row w-100">
                        <div className="col-6 w-100 offset-5">
                          {listEquipmentEvents.pagination[PAGINATION_PAGE_COUNT] > 1 && (
                            <SzPagination
                              totalItemsCount={parseInt(listEquipmentEvents.pagination[PAGINATION_TOTAL_COUNT], 10)}
                              activePage={parseInt(listEquipmentEvents.pagination[PAGINATION_CURRENT_PAGE], 10)}
                              onChange={(pageNumber: any) => {
                                this.setState({ page: pageNumber });
                                this.setData(pageNumber);
                              }}
                              itemsCountPerPage={parseInt(listEquipmentEvents.pagination[PAGINATION_PER_PAGE], 10)}
                              pageRangeDisplayed={parseInt(listEquipmentEvents.pagination[PAGINATION_PAGE_COUNT], 10)}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <EmptyRow />
                )}
              </SzList.Item>
            </SzList>
          </>
        );
    }
}

const mapDispatchToProps = {
    setEquipmentEventsList: eventAction.setEquipmentEventsList,
};

const mapStateToProps = (state: any, props: any) => {
    return {
        listEquipmentEvents: state.event.listEquipmentEvents,
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ViewEventManager));
