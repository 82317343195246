import React from "react";
import {useTranslation} from "react-i18next";
import "./index.scss";
import {SzTab} from "@suezenv/react-theme-components/build/components/SzTab/SzTab";
import {routesPath} from "../../../../routes";
import {menuItems} from "../../Container";


const HydriqueTabNavigation = (props: any) => {
    const {t} =  useTranslation();
    return (
        <SzTab
            activeKey={props.route}
            id="story-tab"
            selectTabHandle={props.selectTabHandle}
        >
            <SzTab.SzTabContent eventKey={routesPath.hydriqueCircuit} title={t('hydriqueHomePage')}>
            </SzTab.SzTabContent>
            <SzTab.SzTabContent eventKey={routesPath.hydriqueMap} title={t('hydriqueMap')}>
            </SzTab.SzTabContent>
            <SzTab.SzTabContent eventKey={routesPath.hydriqueEquipment} title={t('hydriqueEquipment')}>
            </SzTab.SzTabContent>
        </SzTab>
    );
};

export default HydriqueTabNavigation;
