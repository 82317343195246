import {SzInput, SzTypographie} from "@suezenv/react-theme-components";
import React from 'react';
import classnames from 'classnames';

interface TimeSelectType {
    onChangeFirst: (value: string) => void,
    onChangeSecond: (value: string) => void,
    firstName: string,
    firstValue: string,
    firstClassName?: string,
    secondName: string,
    secondValue: string,
    secondClassName?: string
    className?: string,
    secondLabel?: string,
    firstLabel?: string
    maxFirstValue?:number
    maxSecondValue?:number
    required ? : boolean
}

export const TimeSelect = (props: TimeSelectType) => {

    const {
        onChangeFirst,
        onChangeSecond,
        firstName,
        firstValue,
        firstClassName,
        secondName,
        secondValue,
        secondClassName,
        className,
        secondLabel,
        firstLabel,
        maxFirstValue,
        maxSecondValue,
        required
    } = props;

    const onChangeFirstHandle = ({target}: any) => {
        if (!isNaN(Number(target.value)) && Number(target.value) >= 0 && Number(target.value) <= (maxFirstValue || 23)) {
            if (Number(target.value) < 10 && target.value.length<2) {
                const value = `0${parseInt(target.value)}`;
                onChangeFirst(value);
            } else {
                const value = parseInt(target.value) + '';
                onChangeFirst(value);
            }
        }
    }

    const onChangeSecondHandle = ({target}: any) => {
        if (!isNaN(Number(target.value)) && Number(target.value) >= 0 && Number(target.value) <= (maxSecondValue || 59)) {

            if (Number(target.value) < 10 && target.value.length<2) {
                const value = `0${parseInt(target.value)}`;
                onChangeSecond(value);
            } else {
                const value = parseInt(target.value) + '';
                onChangeSecond(value);
            }
        }
    }
    const classesName = classnames('d-flex flex-row', className);
    return (

        <div className={classesName}>
            <SzInput
                name={firstName}
                value={firstValue}
                className={firstClassName || ''}
                type='number'
                onChange={onChangeFirstHandle}
                required = {required}

            />

            <SzTypographie className="ml-2 mt-2 mr-3">{firstLabel || 'H'}</SzTypographie>

            <SzInput
                name={secondName}
                value={secondValue}
                className={secondClassName || ''}
                type='number'
                onChange={onChangeSecondHandle}
                required = {required}
            />

            <SzTypographie className="ml-2 mt-2">{secondLabel || 'Min'}</SzTypographie>
        </div>
    );
}