import React, {useEffect} from "react";
import { routesPath } from "../../../routes";
import ConfigManager from "../../../watering/manager/Programming/ConfigManager";
import Breadcrumb from "../../components/Utils/Breadcrumb";
import {useHistory} from "react-router";
import {config} from "../../../config";
import ReactGA from "react-ga";

const ProgrammingConfig = () => {
    const history = useHistory();
    useEffect(() => {
        if (config.googleAnalyticsId && history.location) {
            ReactGA.initialize(config.googleAnalyticsId);
            ReactGA.set({ page: history.location.pathname }); // Update the user's current page
            ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
        }
    },[history.location.pathname]);

    return (
      <div className="m-0 m-xl-2 w-100 overflow-auto">
        <Breadcrumb
          backUrl={
              {
                  pathname: routesPath.programming.index,
                  state: { previous: routesPath.programming.config}
              }
              }
          elements={[
            { text: "programming", link: routesPath.programming.index },
          ]}
        />
        <ConfigManager />
      </div>
    );
};

export default ProgrammingConfig;
