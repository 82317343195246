import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SzBox, SzButton, SzIcon, SzInput, SzTypographie } from "@suezenv/react-theme-components";
import "./style.scss";

const ConsumptionHeaderList: any = (props: { searchHandle: (arg0: string) => void; filters:any, resetHandle: () => void}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ search: props.filters.query });

  const enterKeyHandle = (event: any) => {
      if (event.key === "Enter") {
        props.searchHandle(state.search);
      }
  };

  return (
    <>
      <div className="m-2 mt-4 w-100">
        <SzBox className="equipment-info-box row mr-4  m-0 p-0 pt-1  cursor-pointer" tag="div">
          <div className="float-left mr-2 mt-2 ml-2">
            <SzIcon variant="line" icon="color-drop-pick" />
          </div>
          <div className="col-3 mt-2">
            <SzTypographie className="mb-0">{t(`consumption:list.title`)}</SzTypographie>
            <SzTypographie variant="caption" weight="light">
              {t(`consumption:list.subTitle`)}
            </SzTypographie>
          </div>
          <div className="col-3 mt-3 row  p-0 m-0">
            <div className="col-8 p-0">
              <SzInput
                value={state.search}
                onKeyUp={enterKeyHandle}
                onChange={({ target }) => setState({ ...state, search: target.value })}
                placeholder={t("equipment:select_location_reference")}
              />
            </div>
            <div className="col-2 text-center">
              <SzButton onClick={() => props.searchHandle(state.search)} variant="secondary" icon="search" />
            </div>

            {state.search &&
            < div className="col-2 p-0">
              <SzButton onClick={()=>{
                setState({...state, search: ''});
                props.resetHandle();
              }} variant="secondary" icon="diagram-round"/>
            </div>
            }
          </div>
        </SzBox>
      </div>
    </>
  );
};

export default ConsumptionHeaderList;
