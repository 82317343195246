import { config } from "../../config";
import { Token as TokenService } from "./Token";

export class Authenticate {
  public static intialize() {
    TokenService.createAlmEvTokens();
    TokenService.checkJWTValidityFromAPI();
  }

  public static login() {
    return (window.location = `${config.apiUrl}/secure/login` as any);
  }

  public static logout(ssoToken: string = "") {
    sessionStorage.clear();
    window.location = `${config.apiUrl}/secure/logout/${ssoToken}` as any;
  }
}
