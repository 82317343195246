// action strings
export enum Constants {
  LOG_IN = "APP_LOGIN",
  LOG_OUT = "APP_LOGOUT",
  SET_USER_INFO = "SET_USER_INFO",
  SET_LOADING= "SET_LOADING",
  SET_METEO_FORECAST= "SET_METEO_FORECAST",
  SET_METEO_YESTERDAY_PRECIPITATION= "SET_METEO_YESTERDAY_PRECIPITATION",
  SET_LOCATION_REFERENCE_LIST="SET_LOCATION_REFERENCE_LIST"
}

export enum ERROR {
  SET = "ERROR.SET",
  RESET = "ERROR.RESET",
}

export const  MAX_MOBILE_WIDTH = 1200;

export const STATUS_INDEX_DEFECT = 3;
export const STATUS_INDEX_UNAVAILABLE = 2;
export const STATUS_INDEX_NORMAL = 1;
export const STATUS_LIST = {
  [STATUS_INDEX_DEFECT]: "DEFECT",
  [STATUS_INDEX_UNAVAILABLE]: "UNAVAILABLE",
  [STATUS_INDEX_NORMAL]: "NORMAL",
};
export const  RAISED_STATUS = "RAISED";
export const  ACKED_STATUS = "ACKED";
export const MAX_NBR_ALL_PI = 1000;
export const PAGINATION_TOTAL_COUNT = "x-pagination-total-count";
export const PAGINATION_PAGE_COUNT = "x-pagination-page-count";
export const PAGINATION_CURRENT_PAGE = "x-pagination-current-page";
export const PAGINATION_PER_PAGE = "x-pagination-per-page";
export const PAGINATION_HEADERS = [PAGINATION_TOTAL_COUNT, PAGINATION_PAGE_COUNT, PAGINATION_CURRENT_PAGE, PAGINATION_PER_PAGE];
export const LIST_EVENTS = [
  "leak",
  "low_threshold_unreached",
  "high_threshold_exceeded",
  "high_threshold_reached",
  "low_threshold_exceeded",
  "weak_battery",
  "communication_lost" ];
export const WATERING_FUNCTIONAL_TYPE = "arrosage";
export const HYDRIQUE_FUNCTIONAL_TYPE = "suivi_hydrique";
export const HUNTER_SOURCE= "hunter";
export const SOLEM_SOURCE =  "solem";
export const SOLEM_STEP_MEASURE = 15;
export const HUNTER_STEP_MEASURE = 5;
export enum STATE {
  ON = "on",
  OFF = "off",
}

