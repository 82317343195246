import React from "react";

const Frame = (props: { url: string | undefined, className?: string , title?: string, sandbox?: boolean | undefined}) => {

    const sandboxAttr = props.sandbox ? "allow-scripts allow-same-origin allow-forms allow-downloads" : undefined;
    return (
        <>
            <iframe
                sandbox={sandboxAttr}
                id={`inlineFrameExample${props.title}`}
                title={props.title}
                className={`dashboard ${props.className}`}
                src={props.url}
            ></iframe>
      </>
    );
};

export default Frame;
