import { Constants } from "../constants";
export default {
  filterHydrique(payload: any) {
    return {
      payload,
      type: Constants.SET_HYDRIQUE_MONITORING_FILTER,
    };
  },

  setListMeasures(payload: any) {
    return {
      payload,
      type: Constants.SET_HYDRIQUE_MONITORING_MEASURES,
    };
  },
  setListPlantMeasures(payload: any) {
    return {
      payload,
      type: Constants.SET_HYDRIQUE_MONITORING_PLANT_MEASURES,
    };
  },
  setListPlants(payload: any) {
    return {
      payload,
      type: Constants.SET_HYDRIQUE_MONITORING_PLANTS,
    };
  }
};
