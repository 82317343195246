import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SzBox, SzButton, SzIcon, SzInput, SzPagination, SzTab, SzTypographie} from "@suezenv/react-theme-components";
import {mapAction} from "../../map/store/actions";
import AlarmAcquittedRow from "../components/Alarm/AlarmAcquittedRow";
import AlarmRow from "../components/Alarm/AlarmRow";
import EmptyRow from "../components/utils/EmptyRow";
import AlarmService from "../services/AlarmService";
import LocationReferenceService from "../../main/services/LocationReferenceService";
import ProgrammingService from "../services/ProgrammingService";
import {alarmAction} from "../store/actions";
import {
    ACKED_STATUS, MAX_NBR_ALL_PI,
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT, PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT,
    RAISED_STATUS,
} from "../../main/store/constants";
import EquipmentService from "../services/EquipmentService";

class AlarmManager extends React.Component<any> {

    public state: { tab: string, sort: any, search: string} = {
        tab: "tab-0",
        sort: {
            RAISED: {field: "startAt", dir: "desc"},
            ACKED: {field: "startAt", dir: "desc"},
        },
        search: this.props.filters.query,
    };

    public acknowledgeAlarmHandle(ackReason: string, alarm: any, user: any, reason: string, comment: string = "") {
        const acknowledgeUser = {
            id: user.id,
            name: (!user.firstName && !user.lastName) ? user.email : `${user.firstName} ${user.lastName}`,
        };
        AlarmService.acknowledgeAlarm(alarm.id, ackReason, acknowledgeUser, reason, comment).then(() => {
            this.setData(1, RAISED_STATUS);
            this.setData(1, ACKED_STATUS);
        });
    }

    public componentDidMount() {
        this.initTable();
    }

    public initTable() {
        const {filters, filterLocationReference, setCircuitList} = this.props;
        if (filters.locationReferences && filters.locationReferences.length > 0) {
            this.setData(1, RAISED_STATUS, "startAt", "desc", filters);
            this.setData(1, ACKED_STATUS, "startAt", "desc", filters);
        } else {
            this.setData(1, RAISED_STATUS);
            this.setData(1, ACKED_STATUS);
        }
    }

    private getListEquipmentsId(data:any)
    {
     const equipmentsId:any = [];
      data.forEach((alarm:any)=>{
          equipmentsId.push(alarm.equipmentId);
      });

      return equipmentsId;
    }

    private getAlarmEquipment(equipmentId:any)
    {
        let alarmEquipment:any = null;
        this.props.listRaisedAlarms.equipments.forEach((equipement:any)=>{
            if(equipement.id === equipmentId)
            {
                alarmEquipment = equipement;
            }
        });
        return alarmEquipment;
    }
    public setData(page = 1, status: string, sort = "startAt", dir = "desc", filter= []) {
        let filters = {...this.props.filters, ...filter,  status};

        AlarmService.getAlarms(page, filters, sort, dir).then((response:any) => {
            if (status === RAISED_STATUS) {
                const equipments:any =  this.getListEquipmentsId(response.data);
                EquipmentService.getEquipments(1, {
                    equipment: equipments,
                    status: null,
                    locationReferences: [],
                    query: ""
                }, null).then((equipmentResponse: any) => {
                        this.props.setAlarmsRaisedList({...response, equipments:equipmentResponse.data});
                    });
                AlarmService.countAlarms(filters).then((response: any) => {
                    this.props.setAlarmsRaisedCount(response.count);
                });
            } else {
                this.props.setAlarmsAcquittedList(response);
                AlarmService.countAlarms(filters).then((response: any) => {
                    this.props.setAlarmsAcquittedCount(response.count);
                });
            }
        });
    }

    public downloadListAlarms(status: string, sort = "startAt", dir = "desc", filter= []) {
        const filters = {...this.props.filters, ...filter, status};
        const windowUrl = window.URL;
        const link = document.createElement("a");
        link.setAttribute("download", `alarmes_${status}.csv`);
        AlarmService.getAlarms(1, filters, sort, dir, "all", "csv").then((response: any) => {

            const url = windowUrl.createObjectURL(new Blob([response.data]));
            link.href = url;
            document.body.appendChild(link);
            link.click();
        });
    }

    public selectTabHandle(tab = null) {
        this.setState({tab});
    }

    public renderPaginationList(status: string, listAlarms: any) {
        {
            return <>
                {listAlarms.pagination[PAGINATION_PAGE_COUNT] > 1 &&
                <div className="list-table row m-2">
                    <div className="row w-100">
                        <div className="col-6 w-100 offset-5">
                            <SzPagination
                                totalItemsCount={parseInt(listAlarms.pagination[PAGINATION_TOTAL_COUNT], 10)}
                                activePage={parseInt(listAlarms.pagination[PAGINATION_CURRENT_PAGE], 10)}
                                onChange={(pageNumber: any) => {
                                    this.setState({page: pageNumber});
                                    this.setData(pageNumber, status);
                                }}
                                itemsCountPerPage={parseInt(listAlarms.pagination[PAGINATION_PER_PAGE], 10)}
                                pageRangeDisplayed={parseInt(listAlarms.pagination[PAGINATION_PAGE_COUNT], 10)}
                            />

                        </div>
                    </div>
                </div>
                }
            </>;
        }
    }

    public sortHandle(field: string, status: string) {
        const dir = (this.state.sort[status].field !== field) ? "desc" : (this.state.sort[status].dir === "desc" ? "asc" : "desc");
        this.setData(1, status, field, dir);
        this.setState({sort: {...this.state.sort, [status]: {field, dir}}});
    }

    public renderColumnHeader(field: string, className: string, status: string = RAISED_STATUS) {
        return <>
            <div className={`cursor-pointer ${className}`} onClick={() => this.sortHandle(field, status)}>
                <SzTypographie
                    className="m-0 pt-2 pr-1 float-left">{this.props.t(`alarm:titles.${field}`)}</SzTypographie>
                <SzIcon variant="line"
                        icon={field !== this.state.sort[status].field ? "move-expand-vertical" : (this.state.sort[status].dir === "desc" ? "arrow-rectangle-down" : "arrow-rectangle-up")}/>

            </div>

        </>;
    }

    public enterKeyHandle(event: any) {
        if (event.key === "Enter") {
            this.searchHandle(this.state.search);
        }
    }

    public searchHandle(query: string) {
        LocationReferenceService.getLocationReferences(query).then((response: any) => {
            const localReferencesId = (response.data.length === 0 && query) ? ["notFoundLocationReferences"] : response.data;
            const filters: any = {
                locationReferences: localReferencesId,
            };
            this.props.filterLocationReference({ids: filters.locationReferences, query});
            this.setData(1, RAISED_STATUS, "startAt", "desc", filters);
            this.setData(1, ACKED_STATUS, "startAt", "desc", filters);
        });
    }

    public resetHandle() {
        this.props.filterLocationReference({ids: [], query: ""});
        const filters: any = {
            locationReferences: [],
        };
        this.setData(1, RAISED_STATUS, "startAt", "desc", filters);
        this.setData(1, ACKED_STATUS, "startAt", "desc", filters);
        this.setState({search: ""});
    }

    public render() {
        const {listRaisedAlarms, listAcquittedAlarms, user, t, filterMapLocationReference} = this.props;
        return (
          <>
            <div className="">
              <SzBox className="row  alarm-title-box mr-4 mb-4 m-0 p-0" tag="div">
                <div className="ml-2">
                  <SzIcon variant="line" icon="alarm-bell" />
                </div>
                <div className="col-2">
                  <SzTypographie color="inactive" variant="caption" className="m-0">
                    {this.props.t("alarm:btn.alarm_text1")}
                  </SzTypographie>
                  <SzTypographie>{this.props.t("alarm:btn.alarm_text2")}</SzTypographie>
                </div>
                <div className="offset-6 col-3">
                  <div className=" mt-3 row  p-0 m-0">
                    <div className="col-8 p-0">
                      <SzInput
                        value={this.state.search}
                        onKeyUp={this.enterKeyHandle.bind(this)}
                        onChange={({ target }) => this.setState({ search: target.value })}
                        placeholder={t("equipment:select_location_reference")}
                      />
                    </div>
                    <div className="col-2 text-center">
                      <SzButton
                        onClick={() => this.searchHandle(this.state.search)}
                        variant="secondary"
                        icon="search"
                      />
                    </div>
                      {this.state.search &&
                      < div className="col-2 p-0">
                          <SzButton onClick={this.resetHandle.bind(this)} variant="secondary" icon="diagram-round"/>
                      </div>
                      }
                  </div>
                </div>
              </SzBox>
              <SzBox className="row position-relative  alarm-info-box mr-4  m-0 p-0" tag="div">
                <div className="alarm-tab w-100 p-0">
                  <SzTab activeKey={this.state.tab} id="story-tab" selectTabHandle={this.selectTabHandle.bind(this)}>
                    <SzTab.SzTabContent
                      eventKey="tab-0"
                      title={
                        <>
                          <span className="alarm-info-nbr">{listRaisedAlarms.nbr}</span>
                            <span className="alarm-info-title">{this.props.t("alarm:btn.tab_0")}</span>
                        </>
                      }
                    >
                      <SzBox className="row  alarm-header-box m-0 p-1" tag="div">
                        {this.renderColumnHeader("startAt", "col-2")}
                        {this.renderColumnHeader("locationReferenceName", "col-2")}
                        {this.renderColumnHeader("type", "col-2")}
                        {this.renderColumnHeader("equipmentName", "col-2")}
                        {this.renderColumnHeader("description", "col-2")}
                      </SzBox>
                      {Object.keys(listRaisedAlarms.data).length > 0 ? (
                        Object.values(listRaisedAlarms.data).map((alarm: any) => (
                          <AlarmRow
                            acknowledgeAlarmHandle={this.acknowledgeAlarmHandle.bind(this)}
                            key={alarm.id}
                            equipment={this.getAlarmEquipment(alarm.equipmentId)}
                            alarm={alarm}
                            user={user}
                            filterMapLocationReference={filterMapLocationReference.bind(this)}
                          />
                        ))
                      ) : (
                        <EmptyRow />
                      )}
                      {this.renderPaginationList(RAISED_STATUS, listRaisedAlarms)}
                        <SzButton variant="secondary" className="mb-3 download-btn float-right alarm-btn" icon="download-bottom" onClick={() => this.downloadListAlarms(RAISED_STATUS)}>
                            {t("export")}
                        </SzButton>
                    </SzTab.SzTabContent>
                    <SzTab.SzTabContent
                      eventKey="tab-1"
                      title={
                        <>
                          <span className="alarm-info-nbr">{listAcquittedAlarms.nbr}</span>
                            <span className="alarm-info-title">{this.props.t("alarm:btn.tab_1")}</span>
                        </>
                      }
                    >
                      <SzBox className="row  alarm-header-box m-0 p-1" tag="div">
                        {this.renderColumnHeader("startAt", "col-2", ACKED_STATUS)}
                        {this.renderColumnHeader("locationReferenceName", "col-2")}
                          <div className="col-2 row p-0 m-0">
                          {this.renderColumnHeader("type", "col-6 m-0 p-0", ACKED_STATUS)}
                              {this.renderColumnHeader("equipmentName", "col-6 m-0 p-0", ACKED_STATUS)}

                          </div>
                          <div className="col-3 row p-0 m-0">
                        {this.renderColumnHeader("ackUserName", "col-6", ACKED_STATUS)}
                        {this.renderColumnHeader("endAt", "col-6", ACKED_STATUS)}
                          </div>
                        <div className="col-3 row p-0 m-0">
                        {this.renderColumnHeader("ackReason", "col-3 p-0 m-0", ACKED_STATUS)}
                        {this.renderColumnHeader("reason", "col-3 p-0 m-0", ACKED_STATUS)}
                        {this.renderColumnHeader("comment", "col-6 p-0 m-0", ACKED_STATUS)}
                        </div>
                      </SzBox>
                      {Object.keys(listAcquittedAlarms.data).length > 0 ?
                      Object.values(listAcquittedAlarms.data).map((alarm: any) => (
                        <AlarmAcquittedRow
                            key={alarm.id}
                            alarm={alarm}
                            filterMapLocationReference={filterMapLocationReference}
                        />
                      )) : <EmptyRow /> }
                      {this.renderPaginationList(ACKED_STATUS, listAcquittedAlarms)}
                        <SzButton variant="secondary" className="mr-3 mb-3 float-right" icon="download-bottom"
                                  onClick={() => this.downloadListAlarms(ACKED_STATUS)}>
                            {t("export")}
                        </SzButton>
                    </SzTab.SzTabContent>
                  </SzTab>
                </div>
              </SzBox>
            </div>
          </>
        );
    }
}

const mapDispatchToProps = {
    setAlarmsAcquittedList: alarmAction.setAlarmsAcquittedList,
    filterLocationReference: alarmAction.filterLocationReference,
    setAlarmsAcquittedCount: alarmAction.setAlarmsAcquittedCount,
    setAlarmsRaisedList: alarmAction.setAlarmsRaisedList,
    setAlarmsRaisedCount: alarmAction.setAlarmsRaisedCount,
    filterMapLocationReference: mapAction.filterMapLocationReference,
};
const mapStateToProps = (state: any) => {
    return {
        listAcquittedAlarms: state.alarm.listAcquittedAlarms,
        listRaisedAlarms: state.alarm.listRaisedAlarms,
        filters: state.alarm.filters,
        user: state.user,

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(AlarmManager));
