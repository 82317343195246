import React from "react";
import { routesPath } from "../../../routes";
import ConfigManager from "../../../watering/manager/Programming/ConfigManager";
import Breadcrumb from "../../components/Utils/Breadcrumb";
import ConfigHunterManager from "../../../watering/manager/Programming/ConfigHunterManager";

const ProgrammingHunterConfig = () => {
    return (
      <div className="m-0 m-xl-2 w-100 overflow-auto">
        <Breadcrumb
          backUrl={
              {
                  pathname: routesPath.programming.index,
                  state: { previous: routesPath.programming.config},
              }
              }
          elements={[
            { text: "programming", link: routesPath.programming.index },
          ]}
        />
        <ConfigHunterManager />
      </div>
    );
};

export default ProgrammingHunterConfig;
