import { ERROR } from "../constants";
import { IErrorResetAction, IErrorSetAction } from "../types";

export default {
  setErrorMessage(jwtCheck: boolean, message: string, status: number): IErrorSetAction {
    return {
      payload: { jwtCheck, message, status },
      type: ERROR.SET,
    };
  },
  resetErrorMessage(): IErrorResetAction {
    return {
      type: ERROR.RESET,
    };
  },
};
