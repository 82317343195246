import React, { useEffect, useState } from "react";
import { Trans, useTranslation  } from "react-i18next";
import { connect } from "react-redux";
import { SzModal } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { ErrorActions} from "../../../store/actions";
import "./errorMessage.scss";

const ErrorMessage = (props: any) => {
  const { t } = useTranslation();
  const [show, isShow] = useState(false);
  const { resetErrorMessage, error } = props;

  useEffect(() => {
    isShow(props.show);
  }, [props]);

  const handleClose = () => {
    isShow(!show);
    resetErrorMessage();
  };
  return (
    <>
      <SzModal title={t("error.title")} show={show} handleClose={handleClose} size={"xl"}>
        {error && <p>{error.message}</p>}
        <Trans i18nKey="error.text">
          Error, please contact administrator
        </Trans>
      </SzModal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    error: state.error,
    errorUser: state.errorUser || null,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({...ErrorActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
