import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import {SzTypographie} from "@suezenv/react-theme-components";
import HomeManager from "../../manager/HomeManager";
import "./home.scss";
import {config} from "../../../config";
import ReactGA from "react-ga";

const Home = (props: any) => {
    const {user} = props;
    const {t} = useTranslation();
    const history = useHistory();
    useEffect(() => {
        if (config.googleAnalyticsId && history.location) {
            ReactGA.initialize(config.googleAnalyticsId);
            ReactGA.set({ page: history.location.pathname }); // Update the user's current page
            ReactGA.pageview(history.location.pathname); // Record a pageview for the given page
        }
    },[history.location.pathname]);

    return (
        <div className="m-2 w-100">
        <div className="m-4 ml-5">
            <SzTypographie> {t("home_page_message", {...user})}</SzTypographie>
        </div>
            <HomeManager history={history}/>
        </div>
    );
};

const mapDispatchToProps = {};
const mapStateToProps = (state: any) => {
    return {
        listRaisedAlarms: state.alarm.listRaisedAlarms,
        user: state.user,

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Home);
