import axios from "axios";
import {setupCache} from "axios-cache-adapter";
import {config} from "../../config";
import {Token as TokenService} from "../../user/services/Token";
import {store} from "../store";
import {Constants, ERROR} from "../store/constants";

export class Request {
    public static cancel: any;
    private base: string;
    private CancelToken: any;
    private cache: any;

    constructor() {
        this.base = config.apiUrl as string;
        this.CancelToken = axios.CancelToken;
        this.cache = setupCache({
            maxAge: 15 * 60 * 1000,
        });
    }

    public async get(endpoint: string, cancelOption = false, cached = true, showLoading = true) {
        if (TokenService.isJwtNotValid()) {
            return TokenService.refreshToken().then(() => this.sendRequest(endpoint, cancelOption));
        } else {
            return this.sendRequest(endpoint, cancelOption, cached, showLoading);
        }
    }

    public async post(endpoint: string, body: any, cancelOption = false) {
        if (TokenService.isJwtNotValid()) {
            return TokenService.refreshToken().then(() => this.postRequest(endpoint, body, cancelOption));
        } else {
            return this.postRequest(endpoint, body, cancelOption);
        }
    }
    public async put(endpoint: string, body: any, cancelOption = false) {
        if (TokenService.isJwtNotValid()) {
            return TokenService.refreshToken().then(() => this.putRequest(endpoint, body, cancelOption));
        } else {
            return this.putRequest(endpoint, body, cancelOption);
        }
    }
    public async delete(endpoint: string, cancelOption = false) {
        if (TokenService.isJwtNotValid()) {
            return TokenService.refreshToken().then(() => this.deleteRequest(endpoint, cancelOption));
        } else {
            return this.deleteRequest(endpoint, cancelOption);
        }
    }

    public async getBlobWithPost(endpoint: string, params: any) {
        Request.cancel && Request.cancel();
        const httpInstance = this.getHttpInstance();
        params.responseType = "blob" as "blob";
        return httpInstance.post(endpoint, params);
    }

    public async getBlob(endpoint: string, cancelOption: boolean, cached = true) {
        Request.cancel && Request.cancel();
        const httpInstance = this.getHttpInstance(cached);
        const param = { responseType: "blob" as "blob" };
        return httpInstance.get(endpoint, param);
    }

    public async refreshToken() {
        const body = JSON.stringify({
            refresh_token: sessionStorage.getItem("refreshToken"),
        });
        const endpoint = `/token/refresh`;
        const httpInstance = this.getHttpInstance();
        return httpInstance.post(endpoint, body);
    }

    public async checkJWT() {
        const endpoint = `/api/token-check`;
        const httpInstance = this.getHttpInstance();
        return httpInstance.get(endpoint);
    }

    public cancelRequest() {
        Request.cancel && Request.cancel();
    }

    private sendRequest(endpoint: string, cancelOption = false, cached = true, showLoading = true) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }
        const httpInstance = this.getHttpInstance(cached, showLoading);

        return httpInstance.get(endpoint, param);
    }

    private postRequest(endpoint: string, body: any, cancelOption = false) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }
        const httpInstance = this.getHttpInstance();
        return httpInstance.post(endpoint, body, param);
    }

    private putRequest(endpoint: string, body: any, cancelOption = false) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }
        const httpInstance = this.getHttpInstance();
        return httpInstance.put(endpoint, body, param);
    }

    private deleteRequest(endpoint: string,  cancelOption = false) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }
        const httpInstance = this.getHttpInstance();
        return httpInstance.delete(endpoint, param);
    }

    private getHttpInstance(cached?: boolean, showLoading = true) {
        const {base} = this;
        let adapter = this.cache.adapter;
        if ("undefined" !== typeof cached && !cached) {
            adapter = undefined;
        }
        if (showLoading) {
            store.dispatch({
                payload: true,
                type: Constants.SET_LOADING,
            });
        }
        const httpInstance = axios.create({
            adapter,
            baseURL: `${base}`,
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                "authorization": `Bearer ${sessionStorage.getItem("tokenAlmEv")}`,
            },
        });

        httpInstance.interceptors.response.use(
            async (response) => {
                store.dispatch({
                    payload: false,
                    type: Constants.SET_LOADING,
                });
                return response;
            },
            (error) => {
                store.dispatch({
                    payload: false,
                    type: Constants.SET_LOADING,
                });
                if (error.response) {
                    const {
                        status,
                        data: {message},
                    } = error.response;
                    const isJwtError = 401 === status;
                    if (isJwtError) {
                        store.dispatch({
                            payload: {jwtCheck: true, status, message},
                            type: ERROR.SET,
                        });
                    } else {
                        store.dispatch({
                            payload: {jwtCheck: false, status, message},
                            type: ERROR.SET,
                        });
                    }
                } else {
                    store.dispatch({
                        payload: {jwtCheck: false, status: 0, message: error.message},
                        type: ERROR.SET,
                    });
                }

                return Promise.reject(error);
            },
        );
        return httpInstance;
    }
}

export default new Request();
