import instanceRequest, {Request} from "../../main/services/Request";
import {
    PAGINATION_HEADERS,
    STATUS_INDEX_DEFECT,
    STATUS_INDEX_NORMAL,
    STATUS_INDEX_UNAVAILABLE,
    STATUS_LIST,
} from "../../main/store/constants";

class AlarmService {
    private axiosService: Request;

    public constructor(axiosService: Request) {
        this.axiosService = axiosService;
    }

    public getAlarms(
        page = 1,
        filters = {status: null, locationReferences: [], functionalType: null},
        sortField = "startAt",
        sortDirection = "desc",
        itemsPerPage:string = "20",
        format= "json",
    ) {
        let filtersStatusQuery = "";
        let filtersFunctionalType = "";
        if (filters.status) {
            filtersStatusQuery = `status=${filters.status}`;
        }

        if (filters.functionalType) {
            filtersFunctionalType = `&functionalType=${filters.functionalType}`;
        }
        const body = {locationReference: filters.locationReferences ? filters.locationReferences.map((item: any) => item.id):[]};
        const itemsPerPageQuery = itemsPerPage === 'all' ? `&allItems=1` : `&itemsPerPage=${itemsPerPage}`;
        // all filters are deactivated
        if (filtersStatusQuery === "" && filters.status) {
            return new Promise((resolve, reject) => {
                resolve({
                    pagination: [],
                    data: [],
                });
            });
        }
        const url = `/api/alarm/list?currentPage=${page}${itemsPerPageQuery}&sortKey=${sortField}&sortDir=${sortDirection}&${filtersStatusQuery}&format=${format}${filtersFunctionalType}`;

        if (format === "json") {
            return this.axiosService.post(url, body, false).then((response) => {
                return {
                    pagination: this.getPaginationInfo(response.headers),
                    data: response.data,
                };
            });
        } else {
            return this.axiosService.getBlobWithPost(url, body).then((response) => {
                return response;
            });
        }
    }

    public countAlarms(
        filters = {status: null, locationReferences: []},
    ) {
        let filtersStatusQuery = "";
        if (filters.status) {
            filtersStatusQuery = `status=${filters.status}`;
        }
        const body = {locationReference: filters.locationReferences.map((item: any) => item.id)};

        const url = `/api/alarm/count?${filtersStatusQuery}`;
        return this.axiosService.post(url, body, false).then((response) => {
            return {
                count: response.data.count,
            };
        });
    }

    public getPaginationInfo(headers: any): any {
        const pagination: any = [];

        Object.entries(headers).map((header: any) => {
            if (PAGINATION_HEADERS.includes(header[0])) {
                pagination[header[0]] = header[1];
            }
            return pagination;
        });

        return pagination;
    }

    public acknowledgeAlarm(alarmId: string, ackReason: string, user: { id: string | null, name: string }, reason: string, comment = "") {
        const body = {ackReason: ackReason, user, reason, comment};

        const url = `/api/alarm/${alarmId}/ack`;
        return this.axiosService.post(url, body, false);
    }

}

export default new AlarmService(instanceRequest);
