import {duration} from "moment";
import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {SzBox, SzIcon, SzPagination, SzTypographie} from "@suezenv/react-theme-components";
import {mapAction} from "../../map/store/actions";
import { routes } from "../../routes";
import EquipmentRow from "../components/Equipement/EquipmentRow";
import Filter from "../components/Filter";
import EmptyRow from "../components/utils/EmptyRow";
import EquipmentService from "../services/EquipmentService";
import EventService from "../../main/services/EventService";
import {equipmentAction} from "../store/actions";
import {eventAction} from "..//store/actions";
import {
    PAGINATION_CURRENT_PAGE,
    PAGINATION_PAGE_COUNT,
    PAGINATION_PER_PAGE,
    PAGINATION_TOTAL_COUNT,
} from "../../main/store/constants";

class ListHydriqueEquipmentManager extends React.Component<any> {

    public state = {
        page: 1,
    };

    public componentDidMount() {
        this.initTable();
    }

    public initTable() {
        const {filters} = this.props;
        this.setData(1 , filters);
    }

    public setData(page = 1, filters:any = null) {
        EquipmentService.getEquipments(page, filters ? filters : this.props.filters).then((response) => {
            this.props.setEquipmentsList(response);
            EventService.getLatestEvents(response).then((response) => {
              this.props.setLatestEvent(response);
            } );
        });
        EquipmentService.getEquipmentStats(filters ? filters : this.props.filters).then((response) => {
            this.props.setEquipmentsStats(response);
        });
    }

    public filterStatusHandle(status: string) {
        const filters: any = {
            ...this.props.filters,
            status: {...this.props.filters.status, [status]: !this.props.filters.status[status]},
        };
        EquipmentService.getEquipments(this.state.page, filters).then((response) => {
            this.props.setEquipmentsList(response);
            this.props.filterStatus(status);
            EventService.getLatestEvents(response).then((response) => {
              this.props.setLatestEvent(response);
            });
        });
        EquipmentService.getEquipmentStats(filters).then((response) => {
            this.props.setEquipmentsStats(response);
        });

    }

    public searchHandle(query: string) {
        const  filters: any = {
                    ...this.props.filters,
                    query,
                };
        this.setData(1, filters);
        this.props.filterLocationReference({query});

    }

    public resetHandle() {
        this.props.filterLocationReference({ids: [], query: ""});
        this.setData(1, { locationReferences: [], query : "" });
    }

    public render() {
      const { listEquipments, filters, stats, latestEvent, filterMapLocationReference} = this.props;
      return (
        <>
          <Filter
            searchHandle={this.searchHandle.bind(this)}
            filterStatusHandle={this.filterStatusHandle.bind(this)}
            stats={stats}
            filters={filters}
            resetHandle={this.resetHandle.bind(this)}
          />
          {Object.keys(listEquipments.data).length > 0 ? (
            Object.values(listEquipments.data).map((equipment: any) => (
              <EquipmentRow filterMapLocationReference={filterMapLocationReference} key={equipment.id} equipment={equipment} latestEvent={latestEvent} />
            ))
          ) : (
            <EmptyRow />
          )}
          {listEquipments.pagination[PAGINATION_PAGE_COUNT] > 1 && (
            <div className="list-table row m-2">
              <div className="row w-100">
                <div className="col-6 w-100 offset-5">
                  <SzPagination
                    totalItemsCount={parseInt(listEquipments.pagination[PAGINATION_TOTAL_COUNT], 10)}
                    activePage={parseInt(listEquipments.pagination[PAGINATION_CURRENT_PAGE], 10)}
                    onChange={(pageNumber: any) => {
                      this.setState({ page: pageNumber });
                      this.setData(pageNumber);
                    }}
                    itemsCountPerPage={parseInt(listEquipments.pagination[PAGINATION_PER_PAGE], 10)}
                    pageRangeDisplayed={parseInt(listEquipments.pagination[PAGINATION_PAGE_COUNT], 10)}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      );
    }

    protected getFastLink() {
        return (
          <div className="row m-0 p-0 mb-5">
            <div className="col-3 p-0" onClick={() => this.props.history.push(routes().map)}>
              <SzBox className="row equipment-info-box mr-4  m-0 p-0 pt-4  cursor-pointer" tag="div">
                <div className="col-2">
                  <SzIcon variant="line" icon="maps-pin-1" />
                </div>
                <div className="col-8">
                  <SzTypographie className="m-0">{this.props.t("equipment:btn.map_text1")}</SzTypographie>
                  <SzTypographie color="inactive" variant="caption">
                    {this.props.t("equipment:btn.map_text2")}
                  </SzTypographie>
                </div>
                <div className="col-2">
                  <SzIcon variant="line" icon="arrow-right" />
                </div>
              </SzBox>
            </div>

            <div className="col-3 p-0" onClick={() => this.props.history.push(routes().consumption)}>
              <SzBox className="row equipment-info-box mr-4  m-0 p-0 pt-4  cursor-pointer" tag="div">
                <div className="col-2">
                  <SzIcon variant="line" icon="color-drop-pick" />
                </div>
                <div className="col-8">
                  <SzTypographie className="m-0">{this.props.t("equipment:btn.consumption_text1")}</SzTypographie>
                  <SzTypographie color="inactive" variant="caption">
                    {this.props.t("equipment:btn.consumption_text2")}
                  </SzTypographie>
                </div>
                <div className="col-2">
                  <SzIcon variant="line" icon="arrow-right" />
                </div>
              </SzBox>
            </div>
            <div className="col-3 p-0">
              <SzBox className="row equipment-info-box  mr-4 m-0 p-0 pt-4  cursor-pointer" tag="div">
                <div className="col-2">
                  <SzIcon variant="line" icon="wifi-signal-2" />
                </div>
                <div className="col-8" onClick={() => this.props.history.push(routes().alarm)}>
                  <SzTypographie className="m-0">{this.props.t("equipment:btn.alarm_text1")}</SzTypographie>
                  <SzTypographie color="inactive" variant="caption">
                    {this.props.t("equipment:btn.alarm_text2")}
                  </SzTypographie>
                </div>
                <div className="col-2">
                  <SzIcon variant="line" icon="arrow-right" />
                </div>
              </SzBox>
            </div>
            <div className="col-3 p-0" onClick={() => this.props.history.push(routes().programming.index)}>
              <SzBox className="row equipment-info-box  m-0 p-0 pt-4  cursor-pointer" tag="div">
                <div className="col-2">
                  <SzIcon variant="line" icon="cog" />
                </div>
                <div className="col-8">
                  <SzTypographie className="m-0">{this.props.t("equipment:btn.programming_text1")}</SzTypographie>
                  <SzTypographie color="inactive" variant="caption">
                    {this.props.t("equipment:btn.programming_text2")}
                  </SzTypographie>
                </div>
                <div className="col-2">
                  <SzIcon variant="line" icon="arrow-right" />
                </div>
              </SzBox>
            </div>
          </div>
        );
    }
}

const mapDispatchToProps = {
    setLatestEvent: eventAction.setLatestEvent,
    setEquipmentsList: equipmentAction.setEquipmentsList,
    filterStatus: equipmentAction.filterStatus,
    setEquipmentsStats: equipmentAction.setEquipmentsStats,
    filterLocationReference: equipmentAction.filterLocationReference,
    filterMapLocationReference: mapAction.filterMapLocationReference,
};
const mapStateToProps = (state: any) => {
    return {
        listEquipments: state.hydriqueEquipment.listEquipments,
        latestEvent: state.hydriqueEvent.latestEvents,
        filters: state.hydriqueEquipment.filters,
        stats: state.hydriqueEquipment.stats,
        locationReferences: state.locationReferences,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(ListHydriqueEquipmentManager));
