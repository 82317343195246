import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SzButton, SzIcon, SzList, SzSelect, SzTypographie} from "@suezenv/react-theme-components";
import { formatServerDate } from "../../../main/utils";
import MeasureService from "../../services/MeasureService";

const ExportConsumption: any = (props: any) => {
    const {t} = useTranslation();
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState({value: `${currentYear}`, label: `${currentYear}`});
    const selectChangeHandle = (option: any) => {
        setYear(option);
    };
    const getOptions = () => {
        return [{value: `${currentYear - 1}`, label: `${currentYear}`},
            {value: `${currentYear - 1}`, label: `${currentYear - 1}`},
            {value: `${currentYear - 2}`, label: `${currentYear - 2}`},
            {value: `${currentYear - 3}`, label: `${currentYear - 3}`}];
    };

    const download = (year: any) => {
        const windowUrl = window.URL;
        const now = new Date();
        const link = document.createElement("a");
        link.setAttribute("download", `consommations_${formatServerDate(now)}.csv`);
        MeasureService.getMeasureByMonth([year], "", "csv").then((response: any) => {

            const url = windowUrl.createObjectURL(new Blob([response.data]));
            link.href = url;
            document.body.appendChild(link);
            link.click();
        });
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col-6">
                    <SzTypographie className="required">
                        {t("consumption:year_label")}
                    </SzTypographie>
                    <SzSelect
                        className="mb-1"
                        onChange={selectChangeHandle}
                        name={"years"}
                        placeholder=""
                        options={getOptions()}
                        value={year}
                    />
                </div>
            </div>
            <SzButton variant="secondary" className="mr-3 float-right" icon="download-bottom" onClick={() => download(year.value)}>
            {t("export")}
            </SzButton>
        </>
    );
};

export default ExportConsumption;
