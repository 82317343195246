import i18n from "../../i18n";
import {formatLocalDateTime, validateDateFormat} from "../../main/utils";

export const displayValues = (values: any)  => {
    const jsonValues = JSON.parse(values);
    let item = "";
    for (const [key, value] of Object.entries(jsonValues)) {
        const transkey = i18n.t(`event:types.${key}`);
        if (typeof jsonValues[key] === "object" && jsonValues[key] !== null) {
            let res = "";
            for (const [index, value] of Object.entries(jsonValues[key])) {
                const transIndex = i18n.t(`event:types.${index}`);
                res = `${res} \n ${transIndex}: ${formatDateValue(jsonValues[key][index])}`;
            }
            item = `${item}  \n${transkey}: ${res}`;
        } else {
            item = `${item} \n${transkey}: ${formatDateValue(jsonValues[key])}`;
        }
    }
    return item;
};

/**
 * format value if date time  (set format and local time)
 * @param value
 */
const formatDateValue = (value: any) => {

    if (typeof value !== 'string') {
        return value;
    }

    return validateDateFormat(value, 'YYYY-MM-DDTHH:mm:ssZ', true) ? formatLocalDateTime(value) : value;
}
