import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ConsumptionHeader from "../components/Consumption/ConsumptionHeader";
import MeasureService from "../services/MeasureService";
import {measureAction} from "../store/actions";

class ConsumptionManager extends React.Component<any> {
    public state = {
        page: 1,
    };

    public componentDidMount() {
        this.init();
    }

    public init() {
        if (this.props.measure.totalYear === null) {
            this.setData();
        }
    }

    public setData() {
        MeasureService.getMeasureByYear().then((response) => {
            this.props.setMeasureByYear(response);
        });
    }

    public setMeasureByMonth(years = []) {
        MeasureService.getMeasureByMonth(years).then((response) => {
            this.props.setMeasureByMonth(response);
        });
    }

    public render() {
        return (
            <>
                <ConsumptionHeader measure={this.props.measure} setMeasureByMonth={this.setMeasureByMonth.bind(this)}/>
            </>
        );
    }
}

const mapDispatchToProps = {
    setMeasureByYear: measureAction.setMeasureByYear,
    setMeasureByMonth: measureAction.setMeasureByMonth,
};
const mapStateToProps = (state: any) => {
    return {
        measure: state.measure,
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(ConsumptionManager));
