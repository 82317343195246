export const routes = (moduleId?: string, plantId?: string) =>  {
    return {
      home: "/",
        watering: "/watering",
        consumption: "/consumption",
      programming: { index: "/programming", config: `/programming/configuration/${moduleId}` },
      programmingHunter: { index: "/programming", config: `/programming/hunter/configuration/${moduleId}` },
      programmingMobileHunter: { index: "/programming", config: `/programming/mobile/hunter/configuration/${moduleId}` },
      map: "/map",
      referentieladmin: "/referentiel-admin",
      useradmin: "/user-admin",
      alarm: "/alarm",
      urbasenseModule: `/urbasense/module/${moduleId}/plant/${plantId}`

    };
};

export const routesPath = {
         home: "/",
         watering: "/watering",
         consumption: "/consumption",
         programming: { index: "/programming", config: `/programming/configuration/:moduleId` },
         programmingHunter: { index: "/programming", config: `/programming/hunter/configuration/:moduleId` },
         programmingMobileHunter: { index: "/programming", config: `/programming/mobile/hunter/configuration/:moduleId` },
         map: "/map",
         referentieladmin: "/referentiel-admin",
         useradmin: "/user-admin",
         alarm: "/alarm",
         hydriqueEquipment: "/hydrique-equipment",
         hydriqueCircuit: "/hydrique-circuit",
         hydriqueMap: "/hydrique-map",
         urbasenseModule: `/urbasense/module/:moduleId/plant/:plantId`
       };
export const wateringPaths:string[] = ["/watering","/consumption","/programming","/map","/alarm"];
